@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.byline {
  color: $grey-600;
  display: block;

  > div {
    top: -3px;
  }
}

.subsection {
  display: inline-block;
}

.updateHomepageWrapper {
  padding: 0;
  margin: 0 0 24px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: initial;
  max-width: max-content;
  border-radius: $border-radius-small;
  position: relative;
  overflow: hidden;
  background: $grey-100;

  > label {
    font-size: 1.4rem;
    text-align: center;
    line-height: 38px;
    border: 1px solid rgba($grey-600, 0.2);
    margin: 0;
    width: 20%;
    max-width: 95px;
    padding: 8px;

    &.checked {
      background-color: $grey-600;

      em,
      span {
        color: $white;
      }
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-right: 1px solid rgba($grey-600, 0.2);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:not(.checked):hover {
      background-color: rgba($grey-600, 0.2);
      cursor: pointer;
    }

    > span {
      input[type='radio'] {
        + em {
          display: none !important;
        }
      }

      > span {
        padding: 0 12px;
        width: 90px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .navIcon {
          display: flex;
          width: 30px;
          height: 45px;
          margin: auto;
        }

        .pageName {
          padding: 0;
          line-height: $base-line-height;
        }
      }
    }
  }
}

.pageSelector {
  span[class*='labelText'] {
    display: flex;

    svg {
      width: 30px;
      height: 45px;
      margin: auto;
    }
  }

  &.checked span[class*='labelText'] svg {
    fill: $white;
  }
}

.box {
  display: block;
  margin-right: auto;
  background: #ffffff;
  border: 1px solid #cbd5e0;
  border-radius: $border-radius-small;
  padding: 0;
  max-width: 600px;
  width: 100%;

  h3 {
    font-size: 1.8rem;
    line-height: 3.4rem;
    color: #2d3748;
  }

  p {
    font-size: 1.2rem;
    color: #718096;
  }
}

.notifs {
  padding: 16px 20px;

  &:nth-child(even) {
    background: #f7fafc;
  }

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }

  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #2d3748;
    display: block;
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 18px;
    color: #2d3748;
  }

  p {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    line-height: 1.4rem;
    color: #a0aec0;
    margin: 0;
  }
}
