@import './variables';

$small-screen-min-width: 415px; // iPhone 8
$medium-screen-min-width: 769px;
$medium-wide-screen-min-width: 850px; // arbitrary horizontal mobile width
$large-screen-min-width: 1024px;
$site-container-width: 1200px;
$site-container-height: 900px;

@mixin large {
  @media only screen and (min-width: $large-screen-min-width) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: $medium-screen-min-width) {
    @content;
  }
}

@mixin medium-wide {
  @media only screen and (min-width: $medium-wide-screen-min-width) {
    @content;
  }
}

@mixin small {
  @media only screen and (min-width: $small-screen-min-width) {
    @content;
  }
}

@mixin site-width {
  @media only screen and (min-width: $site-container-width) {
    @content;
  }
}

@mixin fixed-content-min-height {
  @media only screen and (min-height: $site-container-height) {
    @content;
  }
}
