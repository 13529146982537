@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  display: block;
  margin-top: -8px;
}

span.selectBox {
  max-width: 100%;
}

.loading {
  position: relative;

  .preloader {
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-color: rgba($white, 0.75);
    z-index: 10;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.buttonStatus {
  display: none;
}
.shareIcon {
  opacity: 0.8;
  display: block;
  height: 20px;
  margin-top: 4px;
  line-height: 20px;
  content: ' ';
  padding-left: 20px;
  font-weight: 400;
  color: $grey-700;
  font-size: $xx-small-font-size;
}
.private {
  composes: shareIcon;
  background: 0% 50% url(../assets/icon-lock.svg) no-repeat;
  background-size: 16px;
}
.public {
  composes: shareIcon;
  background: 0% 50% url(../assets/icon-eye-open.svg) no-repeat;
  background-size: 16px;
}
.footNote {
  font-size: $x-small-font-size;
  margin: 4px 0 0;
  color: $grey-600;
}
.shareSingleButton {
  display: flex;
  justify-content: flex-end;
}
