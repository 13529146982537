@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.fixedFooter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .paginationContainer {
    position: relative;
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
    position: sticky;
    bottom: 0;
    margin: 16px auto -16px -20px;
    padding: 32px 20px 16px;
    background-color: $grey-100;
    border-top: 1px solid $grey-300;
    z-index: 10;

    .stickyScroll {
      display: block;
      position: absolute;
      max-width: calc(100% - 40px);
      top: 8px;
      overflow: auto hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;

      // Alex prefers default scrollbar
      // &::-webkit-scrollbar {
      //   width: 12px;
      //   height: 12px;
      //   position: absolute;
      //   bottom: 100px;
      // }

      // &::-webkit-scrollbar-track {
      //   background: $grey-200;
      // }

      // &::-webkit-scrollbar-thumb {
      //   background: $grey-300;
      //   border-radius: 4px;
      // }

      > .topScroll {
        padding-top: 1px;
        height: 0;
      }
    }
  }

  // Custom scrollbar is shown instead
  div[class*='doubleScroll']:last-of-type::-webkit-scrollbar {
    display: none;
  }
}

.tableContainer {
  max-width: 100%;
  border: 1px solid $grey-300;
  border-radius: $border-radius-small;
  overflow: auto;
}

.table {
  min-width: 100%;
  padding: 0;
  border: 0;
  border-spacing: 0;
  white-space: nowrap;
  overflow: auto;

  &:hover {
    th span[class*='tooltipIcon'] {
      visibility: visible;
    }
  }

  .fixedHeader {
    position: sticky;
    z-index: 1;
    background-color: $white;
  }

  tbody {
    tr {
      background-color: $white;

      &.oddRow {
        background-color: $grey-100;
      }

      &:last-child {
        td:first-child {
          border-bottom-left-radius: $border-radius-medium;
        }

        td:last-child {
          border-bottom-right-radius: $border-radius-medium;
        }
      }
    }
  }

  th,
  td {
    min-height: 60px;
    font-size: $small-font-size;
    line-height: 1.6em;
    text-align: left;
    padding: 6px 10px;
    border-right: 1px solid $grey-300;

    @include medium {
      min-width: 120px;
      padding: 8px 16px;
      white-space: nowrap;

      p {
        white-space: normal;
      }
    }

    &:last-child {
      border-right-width: 0;
    }

    p {
      font-size: inherit;
      margin: 0;
    }
  }

  th span[class*='tooltipIcon'] {
    visibility: hidden;
  }

  // Ensures delete confirmation button container is the same color as the row
  tr {
    &:nth-child(odd) div[class*='confirm'] {
      background-color: $grey-100;
    }

    div[class*='confirm'] {
      padding: 0 16px;
    }
  }
}

.headerColumn {
  position: relative;
  text-align: left;
  padding: 0 24px 0 16px;
  border-right: 1px solid $grey-300;
  color: $grey-600;
  font-weight: 500;
  background-color: $white;
  white-space: nowrap;
  height: 60px;
  border-bottom: 1px solid $grey-300;

  &:first-child {
    border-radius: $border-radius-medium 0 0 0;
  }

  &:last-child {
    border-radius: 0 $border-radius-medium 0 0;
    border-right: 0;
  }

  &.sortable {
    cursor: pointer;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  button {
    position: relative;
  }

  .orderArrow {
    width: 16px;
    height: 16px;
    background: url(../assets/arrow.svg) 50% 50% no-repeat;
    background-size: 100%;
    opacity: 0.4;

    &.ascOrder {
      transform: rotate(180deg);
    }

    &.arrowTypeBi {
      background: url(../assets/arrow-up-down.svg) 50% 50% no-repeat;
    }
  }
}

.placeholderRow {
  height: 75px;

  td {
    .loadingText {
      margin-top: 0;
    }
  }
}

.noData {
  padding: 16px;

  img {
    max-height: 125px !important;
  }
}

.paginationElement {
  &.activePagination button {
    background-color: $grey-200 !important;
    color: $text-color;
  }

  button {
    border-radius: 0;
    min-width: $button-height;
    height: 100%;

    &:focus {
      outline: none;
    }

    &:hover {
      border: 1px solid $grey-300;
      border-left-width: 0 !important;
      background-color: $grey-400 !important;
    }
  }
}

.rowsPerPageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @include medium {
    margin-top: 0;
    margin-left: auto;
  }

  .rowsPerPageSummary {
    font-size: 1.3rem;
    color: $grey-600;
    margin-right: 12px;
  }
}

.paginationRow {
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;

  .paginationWrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .paginationMiddle {
      display: flex;
      flex-wrap: no-wrap;
      justify-content: conter;

      button {
        background: $white;
        border: 1px solid $grey-300;
        border-left-width: 0;
        color: $black;
        padding: 0 8px;
        min-width: 30px;

        &:hover {
          color: $black;
        }
      }

      & div:first-child {
        button {
          border-left-width: 1px;
          border-right-width: 0;
        }
      }

      .paginationMiddleMobile {
        border: 1px solid $grey-300;
        border-left-width: 0;

        span {
          font-size: 1.4rem;
          line-height: $button-height;
          margin-bottom: 0;
          padding: 0 4px;
        }

        @include medium {
          display: none;
        }
      }

      .paginationMiddlePages {
        display: none;
        flex-wrap: no-wrap;

        @include medium {
          display: flex;
        }
      }

      .previousButton,
      .nextButton {
        background-size: 6px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: $white;
        min-width: 40px !important;

        &:hover {
          background-color: $grey-400 !important;
        }
      }

      .previousButton {
        background-image: url(../assets/drop-down-arrow-left.svg);
        border-radius: $border-radius-small 0 0 $border-radius-small;
        border-left-width: 1px;
        margin-left: 8px;

        @include medium {
          border-right-width: 0;
        }
      }

      .nextButton {
        background-image: url(../assets/drop-down-arrow.svg);
        border-radius: 0 $border-radius-small $border-radius-small 0;
        margin-right: 8px;

        @include medium {
          border-left-width: 0;
        }
      }
    }
  }

  .noPagination {
    margin-left: auto;
  }
}
