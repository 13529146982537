@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.recommended {
  color: $grey-600;
  font-weight: 400;
  display: inline;
  font-size: 1.2rem;
  line-height: 1em;
}

.radioButton {
  width: calc(50% - 4px);
  min-width: 200px;
}

.noPaddingTop {
  padding-top: 0 !important;
}

.submitButton {
  width: fit-content;

  // &.softDisableButton {
  //   background-color: $pink !important;
  //   border-color: $pink !important;
  //   color: $primary-color !important;
  // }
}
