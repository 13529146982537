@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.logoWrapper {
  width: 220px;
  min-width: 220px;

  img {
    display: block;
    width: auto;
    max-width: 220px;
    height: auto;
    max-height: 110px;
  }
}
