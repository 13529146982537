@import './variables.scss';
@import './breakpoints.scss';
@import './react-datepicker.scss';
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

// ------------------- globals ------------------
html,
body {
  display: block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  font-size: 10px;
  // font-size: 100%;
  margin: 0 auto;
  color: $text-color;
  font-weight: 400;
  line-height: 1;
  background-color: $grey-100;

  * {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }
}

html,
body,
#root {
  height: 100%;
}

// Type
a,
p a {
  font-size: $small-font-size;
  line-height: 2.4rem;
  font-weight: 400;

  @include medium {
    font-size: $base-font-size;
    line-height: $base-line-height;
  }
  color: $tertiary-color;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
}

tr,
td {
  position: relative;
}

p,
td,
tr,
li,
table {
  font-size: $small-font-size;
  line-height: 2.4rem;

  @include medium {
    font-size: $base-font-size;
  }
}

table {
  line-height: 1.8rem;
}

ol {
  margin: 0 0 16px;
  padding-left: 32px;
}

li {
  margin-bottom: 8px;
}

tr {
  line-height: 2.4rem;
  min-height: 4rem;
  vertical-align: middle;
  align-items: center;
  color: $text-color;
}

p {
  margin: 0 0 16px;
}

h1 {
  color: $black;
  font-size: $h1-font-size-mobile;
  line-height: $h1-line-height-mobile;
  margin: 0 auto 10px;

  @include medium {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }
}

h2 {
  font-size: $h2-font-size-mobile;
  line-height: $h2-line-height;
  margin: 0 auto 10px;
  font-weight: 500;

  @include medium {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }
}

h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
  font-weight: 600;
  margin: 0 auto 16px;
}

h4 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;
  margin: 0 auto 10px;
}

button {
  font-size: $button-font-size;
  line-height: $button-line-height;
}

select {
  box-sizing: border-box;
  display: block;
  flex: 100%;
  width: 100%;
  margin: 0 auto 20px;
  border-radius: $border-radius;
  font-size: $button-font-size;
  line-height: $button-line-height;
  background-color: #e2e2e2;
  border: none;
  padding: 0 20px;
}

label[data-type='select-box'] {
  box-sizing: border-box;
  display: block;
  flex: 100%;
  width: 100%;
  margin: 0 auto 20px;
  border-radius: $border-radius;
  font-size: $button-font-size;
  line-height: $button-line-height;
  height: $button-line-height;
  background-color: #e2e2e2;
  border: none;
  padding: 0 20px;
  overflow: hidden;

  select {
    padding: 0;
    margin: 0;
  }
}

.preload-image {
  position: fixed;
  top: -500px;
  left: -500px;
  opacity: 0;
  pointer-events: none;
}

// Usabilla
.usabilla_live_button_container {
  display: none;
  @include medium {
    display: block;
  }
}

// React vis - graph
.rv-xy-plot__inner {
  position: relative;
  z-index: 1;
}

.rv-xy-plot__grid-lines {
  opacity: 0.6;
}

.rv-crosshair {
  z-index: 0;
  .rv-crosshair__line {
    width: 2px;
    transform: translateX(-1px);
    background-color: #347a95;
  }
}
.rv-discrete-color-legend.horizontal {
  top: -24px;
}
.rv-xy-plot__axis--vertical .rv-xy-plot__axis__title {
  transform: translate(-8px, 30%);
  @include medium {
    transform: translate(0, 30%);
  }
}

.rv-discrete-color-legend.horizontal {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 6px;
}
.rv-discrete-color-legend-item.horizontal {
  padding: 4px 8px;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  svg {
    margin-right: 4px;
  }
}
pre {
  background-color: $grey-200;
  border-radius: $border-radius-small;
  line-height: 2.4rem;
  padding: 8px 8px;
}
code {
  color: $grey-900;

  margin: -1px 2px;
  white-space: break-spaces;
}

.usabilla_live_button_container {
  z-index: 999 !important;
  top: 66% !important;
}

textarea {
  resize: none;
}

::placeholder {
  color: $grey-500;
}
