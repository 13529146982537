@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.emailPreviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;

  .copyButton {
    margin-left: auto;
  }
}

.emailPreviewField {
  width: 100%;
  position: relative;

  .previewCode {
    width: 100%;
    border: 1px solid $grey-400;
    border-radius: $border-radius-small;
    padding: 8px;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: $grey-300;
    }

    &::-webkit-scrollbar-thumb {
      background: $grey-500;
      border-radius: 4px;
    }

    code {
      font-family: Consolas, 'courier new';
      font-size: $x-small-font-size;
    }
  }

  .scrollIndicator {
    width: 21px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    z-index: 10;
    pointer-events: none;

    .highlightIndicator {
      position: absolute;
      background-color: $report-color;
      width: 100%;
      height: 3px;
    }
  }
}
