@import '../core/breakpoints.scss';
@import '../core/variables.scss';

.accordionContainer {
  border: none;
}

.completedSection {
  font-style: italic;
  color: $grey-500;
  text-decoration: line-through;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .markCompleteRight {
    margin-left: auto;
  }
}

.utmUploadMessage {
  margin-bottom: 16px;
}
