@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.wrapper {
  margin-bottom: 16px;
}

.headerWrapper {
  display: flex;
  gap: 16px;
  width: 100%;
  flex-direction: column-reverse;
  margin-bottom: 16px;
  padding-top: 20px;

  @include medium {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
  }
}

.header {
  margin: 0;

  @include medium {
    display: block;
    width: fit-content;
  }
}

.selectorWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex: 1;
  margin-left: auto;

  .trialWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .trial {
      display: none;
      margin: 0;
      text-align: right;

      @include large {
        display: block;
      }
    }

    .progressBar {
      margin-bottom: 0;
    }
  }

  .siteSelector {
    position: relative;
    flex-shrink: 1;
    min-width: 300px;
    max-width: 550px;
  }
}

h1 .beta {
  top: -10px;
}
