@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.radioPanel {
  border: 1px solid $grey-200;
  border-radius: $border-radius-medium;
  overflow: hidden;
  margin-bottom: 16px;

  label {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0 12px;

    &:nth-child(even) {
      background-color: $grey-100;
    }
  }

  p {
    padding-left: 34px;
    color: $grey-600;
    font-size: $small-font-size;
    margin: 0;
  }
}

.table {
  border: 1px solid #cbd5e0;
  border-radius: $border-radius-small;
  border-collapse: separate;

  .handleColumn {
    width: 48px;
    min-width: 48px !important;
    text-align: center;

    svg {
      vertical-align: middle;
    }
  }

  .reportNameColumn {
    width: 100%;
  }

  .visibilityColumn {
    min-width: 150px;
  }

  .actionsColumn {
    min-width: 64px;

    .editButton {
      margin-right: 4px;
    }
  }
}

.headerPanel {
  display: flex;
  margin-bottom: -1px;
}

b.availableToIcon {
  display: inline-block;
  width: 26px;
  height: 20px;
  vertical-align: middle;
  background-position: 0 50%;
  margin-right: 4px;
  margin-left: 4px;
  border-width: 0;
}
b.account {
  background: transparent url(../assets/icon-account.svg) no-repeat 50% 50%;
  background-size: 20px;
}
b.company {
  background: transparent url(../assets/icon-company.svg) no-repeat 50% 50%;
  background-size: 16px;
}
b.user {
  background: transparent url(../assets/icon-eye-crossed.svg) no-repeat 50% 50%;
  background-size: 18px;
}

label.availableToInput {
  & > span:after {
    content: ' ';
    display: inline-block;
    width: 26px;
    height: 34px;
    margin-left: 12px;
  }

  &.availableToInputCompany {
    & > span:after {
      background: transparent url(../assets/icon-company.svg) no-repeat 100% 50%;
      background-size: 20px;
    }
  }
  &.availableToInputAccount {
    & > span:after {
      background: transparent url(../assets/icon-account.svg) no-repeat 100% 50%;
      background-size: 24px;
    }
  }
  &.availableToInputUser {
    & > span:after {
      background: transparent url(../assets/icon-eye-crossed.svg) no-repeat 100%
        50%;
      background-size: 20px;
    }
  }
}

.fullWidth {
  width: 100%;
}

.tabBoxPlaceholder {
  display: none;
}

.afterTabsButtons {
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  button {
    margin-right: 8px;
  }
}

.buttonTabs {
  width: 100%;
  > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @include medium {
      flex-direction: row;
    }
  }
}

.afterTabsActionButtons {
  @include medium {
    margin-left: auto;
    margin-top: -8px;
  }

  .actionButtonsWrapper {
    margin-left: auto;
    margin-bottom: 8px;

    @include site-width {
      margin: 0;
    }
  }

  .bulkButtons {
    width: 100%;
    margin: 0 0 16px;

    @include medium {
      margin: 0 0 0 8px;
      width: 160px;
      min-width: 160px;

      > div {
        min-width: 160px;
      }
    }
  }
}

.panel {
  @include medium {
    width: 100%;
  }
}

.reportDescriptionTooltip {
  padding: 0 !important;
  border-bottom-width: 0;
}

.noSavedReports {
  margin: 0;
  text-align: center;
  color: $grey-500;
}

.softDisable {
  color: $grey-400;
}

.betaLabel {
  right: 0;
}
