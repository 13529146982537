@import '../core/variables.scss';

.outerBox {
  span[class*='tooltipIcon'] {
    display: none;
  }

  &:hover {
    span[class*='tooltipIcon'] {
      display: block;
    }
  }
}

.innerBox {
  &:not(:first-child) {
    border-top: 1px solid $grey-200;
  }
}

.helpSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 16px;

  .helpButtons {
    flex: 2 1;
    max-width: 200px;

    .helpButton {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  .helpImage {
    display: block;
    max-height: 133px;
    max-width: 100%;
    align-self: flex-end;
  }
}

.latestFeatures {
  > h2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    .newPill {
      background-color: $secondary-color;
      right: initial;
    }
  }

  .featureImg {
    display: block;
    margin: 0 auto 16px;
    max-width: 100%;
    border: 1px solid $grey-300;
    border-radius: $border-radius-small;
  }

  a {
    font-size: $button-font-size;
  }
}
