@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.wrapper {
  // border: 1px solid $grey-200;
  border-radius: $border-radius-small;
  margin-bottom: 16px;
  // overflow: hidden;
  background: transparent;
}

.header {
  padding: 8px 40px 8px 16px;
  background-color: $grey-200;
  position: relative;
  min-height: 48px;
  h2 {
    margin-bottom: 0;
  }
}

.smallTitle {
  font-size: $small-font-size;
  font-weight: 500;
  color: $grey-800;
}

.body {
  // overflow: hidden;
}

.innerBody {
  box-sizing: border-box;
  // padding: 16px 16px 0;
}

.expanded {
  .body {
    max-height: 100%;
    transition: all $animation-duration;
  }
  .expandButton {
    background-image: url(../assets/accordion-close.svg);
  }
}

.collapsed {
  overflow: hidden;
  .body {
    overflow: hidden;
    max-height: 0;
    transition: all $animation-duration;
  }
}

.expandButton {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 48px;
  width: 48px;
  right: 1px;
  top: 0;
  position: absolute;
  background-image: url(../assets/accordion-open.svg);
}
