@import '../core/breakpoints.scss';
@import '../core/variables.scss';

.heading {
  @include medium-wide {
    margin-top: 0 !important;
  }
}

.bold {
  font-weight: 600;
}

.boxedText {
  display: inline-block;
  background-color: $grey-200;
  padding: 0 8px;
  color: $text-color;
  font-weight: 500;
  border-radius: $border-radius-small;
  margin-left: 2px;
  margin-right: 3px;
}

.noteText {
  display: block;
  background-color: $grey-200;
  padding: 8px 16px;
  color: $text-color;
  font-weight: 400;
  border-radius: $border-radius-small;

  &.sameLine > * {
    display: inline !important;
  }

  strong {
    font-weight: 600;
    color: $black;
    display: block;
    margin-bottom: 4px;
  }
}

.tickListItem {
  list-style-image: url(../assets/tick-green.svg);

  &.pink {
    list-style-image: url(../assets/tick.svg);
  }
}

.orderedList {
  counter-reset: numList;

  > li {
    position: relative;
    padding-left: 8px;
    overflow-wrap: anywhere;
  }

  > li::before {
    counter-increment: numList;
    content: counter(numList);
    position: absolute;
    left: -25px;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    color: $white;
    line-height: 22px;
    width: 22px;
    height: 22px;
    background: $grey-700;
    border-radius: 22px;
  }
}

.successText {
  position: relative;
  margin: 0;
  padding-left: 18px;

  &.green {
    color: $green;
  }

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    margin-right: 8px;
    line-height: $base-font-size;
    background: transparent url(../assets/icon-check-green.svg) no-repeat;
    background-size: 14px;
  }
}

.errorMsg {
  margin: 4px 0 0;
  color: $error-red;
  font-size: $small-font-size;
}
