@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.ssoWrapperOuter {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  background: $grey-100;
  @include medium {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
}

.ssoWrapper {
  width: 375px;
  margin: auto;
  padding: 16px;
  overflow: hidden;

  @include small {
    background: $white;
    border-radius: $border-radius-small;
    border: 1px solid $grey-300;
  }

  @include medium {
    padding: 32px 32px 16px 32px;
  }

  input[type='checkbox'] {
    font-size: 1.4rem;
    margin: 0 auto;
    line-height: 2.5rem;
    margin-bottom: 25px;
    margin-top: 6px;
    margin-right: 10px;
    @include medium {
      margin-left: 0;
    }
  }

  h1 {
    text-align: center;
    font-weight: normal;
    color: #1a202c;
  }

  h2 {
    width: 100%;
    flex: 100%;
    text-align: center;
    font-weight: normal;
    font-size: 1.5rem;
    margin: 0 auto 16px;
  }

  h3 {
    width: 100%;
    flex: 100%;
    text-align: center;
    font-weight: normal;
    font-size: $base-font-size;
    margin: 0 auto 16px;
  }

  h4 {
    font-size: $small-font-size;
    font-weight: 400;
  }

  p {
    text-align: center;
    font-size: $small-font-size;
  }

  a {
    text-align: center;
    font-size: $small-font-size;
  }

  .link {
    display: block;
    margin: 0 auto 16px;
    width: 100%;
  }

  p .link {
    display: inline;
  }

  button:not([class*='navigateButton']) {
    width: 100%;
  }

  .divider {
    display: block;
    width: 100%;
    height: 16px;
  }
  .inactive {
    opacity: 0.3;
    pointer-events: none;
  }
}

.warningMessage {
  background: #e4f5fc;
  padding: 12px 16px 12px 45px;
  border-radius: $border-radius-small;
  margin: 0 0 16px;
  background-image: url(../assets/icon-info-blue.svg);
  background-repeat: no-repeat;
  background-position: 16px 14px;
  p {
    margin: 0;
    text-align: left !important;
    font-size: $small-font-size;
  }
}
