@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.formHeading {
  position: relative;
  min-height: 8px;
  color: $grey-600;
  font-size: $small-font-size;
  text-transform: initial;
  padding: 8px 0;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    border-bottom: 1px solid $grey-200;
    width: 100%;
  }

  > span {
    display: block;
    position: relative;
    width: fit-content;
    color: $primary-color;
    background-color: $white;
    padding: 0 20px 0 0;
    z-index: 1;
  }
}

.formRow {
  width: 100%;
  display: flex;
  position: relative;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 16px 0 0;

  @include medium {
    flex-direction: row;
  }

  &.paddingBottom {
    padding-bottom: 16px;
  }

  &.bottomBorder {
    border-bottom: 1px solid $grey-200;
  }
}

.labelSlot {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 36px;
  margin-bottom: 8px;

  @include medium {
    width: 35%;
    margin-bottom: 0;
    padding-right: 16px;
  }

  .labelDisabled {
    color: $grey-500;
  }
}

.fieldSlot {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.lastLoader .loader {
    margin-bottom: 16px;
  }

  @include medium {
    max-width: 65%;
  }
}

.loader {
  margin-top: 0;
  align-self: flex-start;
}
