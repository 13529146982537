@import '../core/variables.scss';
@import '../core/breakpoints.scss';
@import '../core/breakpoints.scss';

.wrapper {
  margin-top: 0;
}

.list {
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  border-bottom: 1px solid $grey-300;
}

.deleteConfirmContainer {
  padding: 0 16px;
}

.row {
  display: flex;
  padding: 0;
  border-bottom: 1px solid $grey-200;
  position: relative;

  @include medium {
    border-bottom: 0;

    // This is to enable drag and order functionality
    &:hover {
      cursor: move;
      cursor: pointer;
    }
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }

  // label {
  //   padding-top: 16px;
  //   padding-left: 16px;
  //   padding-bottom: 16px;
  //   margin: 0;
  //   opacity: 1;
  //   transition: transform $animation-duration;
  // }
}

.typeahead {
  margin-bottom: 16px;
}

.confirmationButtons {
  margin-top: 16px;
  // justify-content: flex-start !important;
}
.actionPanelActive {
  .actionPanel {
    display: block;
  }
  .body {
    display: none;
  }
  // height: 100px;
  // transition: height $animation-duration;
}

.table {
  border: 0;
  // border-left: 0;
  // border-right: 0;
}

.tableCell {
  padding: 8px 16px 4px;

  @include medium {
    padding: 12px 16px;
    align-items: left;
    border-right: 1px solid $grey-300;
    width: 75%;
    min-height: 108px;
    display: flex;
    align-items: center;
    // position: absolute;
    // top: 0;

    &:first-child {
      padding-right: 16px;
    }

    &:nth-child(2) {
      width: 17%;
      padding: 12px 8px;
    }

    &:nth-child(3) {
      width: 8%;
    }

    &.addNewItem {
      // padding-left: 98px;
      background-color: $grey-300;
    }
  }
}
.addNewItemRow {
  border-top: 1px solid $grey-400;

  .tableCell {
    @include medium {
      width: 100%;
    }
  }
}
.addNewItemButtonRow {
  border-top: 1px solid $grey-400;

  &.buttonOnly {
    border-top-width: 0;
  }
  .tableCell {
    .addNewButton::after {
      display: none;
    }

    @include medium {
      width: 100%;
      min-height: 42px;

      .addNewButton {
        margin: 0;
        outline: none;
      }
    }
  }
}
.tableCellContent {
  display: block;
  width: 100%;
}

.tableBody {
  display: block;
}
.tableCell {
  text-align: left;
  line-height: 1.8rem;
  font-size: 1.4rem;
  // padding-left: 16px;
  color: $grey-700;

  @include medium {
    // line-height: 58px;
    margin: 0;
  }
}
.tableCellContentPadded {
  display: block;
  width: 100%;
}

.input {
  width: 100%;
  max-width: 100%;
  height: 42px;
  margin: 0 0 4px;
}

.addNewButtonConfirm {
  margin: 0;
}

.tableCellButtons {
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: auto;

    @include medium {
      width: 100%;
    }
  }
}

.editButton {
  margin: 0;
  background: transparent url(../assets/edit.svg) no-repeat 90% 50%;
  padding: 0 45px 0 12px;
  margin-right: 50px;
  background-size: 28px;
  &:hover {
    background-color: transparent;
  }
  @include medium {
    margin-top: -6px;
  }
}

.goodBadPanel {
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  margin-bottom: 16px;

  label {
    margin: 0;
  }
}

.radioBox {
  border: 1px solid $grey-200;
  border-radius: $border-radius-medium;
  max-width: 200px;
  padding-bottom: 4px;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
    border-color: $grey-400;
  }

  &:first-child {
    margin-right: 32px;
  }

  &:after {
    content: ' ';
    display: block;
    background: transparent url(../assets/icon-warning-green.svg) no-repeat 50%
      50%;
    background-size: 100%;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -6px;
    // background-image: url(../assets/radio-blue.svg) !important;
  }

  &:last-child {
    &:after {
      // top: 69px;
      // margin-left: 5px;
      background-image: url(../assets/icon-warning.svg);
    }
  }

  > span {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;

    &:before {
      content: ' ';
      display: block;
      background-color: $grey-100;
      border-bottom: 1px solid $grey-200;
      height: 86px;
      width: 100%;
      margin-bottom: 4px;
    }

    &:after {
      content: ' ';
      display: block;
      background: transparent url(../assets/graph-up.svg) no-repeat 50% 50%;
      background-size: 100%;
      height: 38px;
      width: 100%;
      position: absolute;
      top: 24px;
      left: 0;
    }
  }
  &.radioBoxBad {
    > span {
      &:after {
        // top: 45px;
        background-image: url(../assets/graph-down.svg);
      }
    }
  }

  input {
    &[type='radio'] {
      &:checked ~ em {
        background-image: url(../assets/radio-red.svg) !important;
      }
    }
  }
  &:first-child {
    input {
      &[type='radio'] {
        &:checked ~ em {
          background-image: url(../assets/radio-blue.svg) !important;
        }
      }
    }
  }
}

.itemLink {
  display: flex;
  align-items: center;

  p {
    font-weight: 600;
    padding-left: 12px;
    font-size: 1.4rem;
    color: $grey-900;
    margin: 0;
  }

  button {
    display: inline-flex;
    padding: 0;
    margin: 0;
  }
}
.dashboardThumbnail {
  width: 120px;
  height: 90px;
  margin: 0 auto;
  box-shadow: 0px 1px 1px rgba($black, 0.3);
  border-radius: $border-radius-small;
}
.error {
  color: $grey-600;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  color: $error-red;
}

.padded {
  padding: 16px 16px 0;
}

.addNewDashboard {
  padding: 0;
  font-weight: 600;
}

.editDashboardsModal > div[class*='modalBody'] {
  padding: 0;
}
