@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.loadingHeader {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
}

.codeList {
  margin: 0;
  padding: 0;
  border-top: 1px solid $grey-200;
  margin-bottom: 20px;

  .codeListItem {
    list-style: none;
    margin: 0;
    padding: 0 4px;
    text-align: left;
    position: relative;
    white-space: nowrap;
    text-align: left;
    color: $grey-700;
    font-size: $small-font-size;
    font-weight: 500;
    line-height: 40px;
    border-bottom: 1px solid $grey-300;

    &.noLinksFound {
      color: $grey-600;
    }

    .loadingCodes {
      margin: 8px auto;
    }

    .buttonsContainer {
      display: none;
      position: absolute;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      width: 50%;
      height: 100%;
      top: 0;
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        $white 10%,
        $white 100%
      );
    }

    &:hover {
      .buttonsContainer {
        display: flex;
      }
    }

    .deleteButton {
      background-color: transparent !important;
    }
  }
}

.linkItemTooltipContainer {
  max-width: 100%;
  flex-grow: 1;
  border-bottom: none;
}

.fullLinkTooltip {
  word-break: break-all;
  white-space: pre-line;
}

.linkItem {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: flex-start;

  .hoverable {
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  .linkCheckbox {
    flex: none;
    width: auto;
    margin: 8px 0;
  }

  .linkCode {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .inlineDate {
    margin-left: 16px;
    text-align: right;
    color: $grey-400;
  }
}

.deleteButtons {
  right: 0;
  position: absolute;
  margin: 0;
  right: 0;
  top: 0;
  padding: 0 !important;
  justify-content: flex-end;
  width: auto !important;

  p {
    color: $grey-800 !important;
  }

  button[class*='_delete'] {
    background-color: $white !important;
    display: none;
  }

  > div > button:hover {
    display: block;
  }
}

.newlyCreatedLink {
  background-color: $light-green;
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
}

.deleteError {
  font-size: $x-small-font-size;
  color: $error-red;
  text-align: right;
}

.actionButtons {
  justify-content: space-between;

  a {
    font-size: $button-font-size;
  }
}

.shareButton {
  margin-left: 16px;
}

.copyAllButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;

  .divider {
    border-left: 1px solid $grey-300;
    height: 30px;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
