@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.metricDataBannerBox {
  position: relative;
  flex-basis: 100%;
  width: auto;
  box-sizing: border-box;
  background: $white;
  border-radius: $border-radius-small;
  border: 1px solid $grey-400;
  padding: 16px 21px 8px;
  margin: 0 0 8px;
  min-height: 98px;
  flex-basis: 30%;
  min-width: 220px;

  @include large {
    min-width: 280px;
  }
  &.isEmpty {
    padding: 0;
    height: 160px;
    position: relative;

    .loader {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 50%;
      transform: translateY(95%);
    }
  }
  &:first-child {
    flex-basis: 100%;
    margin-right: 0;
    width: auto;
  }
  @include medium-wide {
    flex-basis: auto;
    &:first-child {
      margin-bottom: 0;
      margin-right: 20px;
      flex-basis: auto;
    }
  }
  @include medium-wide {
    margin: 0 20px 0 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &.metricDataBannerBoxNoData {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2 {
    text-align: left;
    margin: 0 0 4px;
    font-size: $medium-font-size;
    position: relative;
  }
  h3 {
    text-align: left;
    margin: 16px 0 16px;
    font-weight: 600;
    font-size: 40px;
    position: relative;
    display: flex;
    align-items: flex-end;
    line-height: 1em;
  }
  h4 {
    text-align: left;
    font-size: 12px;
    font-weight: normal;
    color: $grey-600;
    em {
      font-style: normal;
    }
  }

  .metricTooltip {
    margin-top: -8px;
    font-weight: normal;
  }
  .valueChange {
    font-size: $large-font-size;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal !important;
    line-height: 1.2em;

    > span {
      min-width: auto;
    }
    img {
      width: 22px;
      height: 22px;
      margin-right: 0;
    }
  }
}
