@import '../core/variables.scss';

.navigateButton {
  display: block;
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.form {
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: 10px;
  }

  .submitRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin: 0;
    }
  }
}

.error {
  color: $error-red;
  margin-bottom: 0;
}
