@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 12px;
  background-color: $modal-background-color;
  pointer-events: auto;
  overflow: auto;

  @include medium {
    padding: $site-padding;
  }

  &.blurredBackground {
    backdrop-filter: blur(4px);
  }
}

.modalContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 95%;
  max-height: 95%;
  margin: 0 auto;
  background-color: $white;
  border-radius: $border-radius-small;

  @include small {
    &.superNarrow {
      max-width: 414px;
    }
  }

  @include medium {
    max-width: 580px;

    &.wide {
      max-width: 850px;
    }

    &.superWide {
      max-width: 1200px;
    }
  }
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  border-top-right-radius: $border-radius-small;
  border-top-left-radius: $border-radius-small;
  background-color: $grey-100;
  border-bottom: 1px solid $grey-300;

  &.warning {
    background-image: url(../assets/warning-red.svg);
    background-position: 20px 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    padding-left: 50px;
  }

  &.pink {
    background-color: $pink;

    &:not(.warning) {
      color: $white;
      background-color: $primary-color;
      border-bottom-width: 0;
    }
  }

  &.green {
    color: $white;
    background-color: $green;
  }

  h3 {
    margin-bottom: 0;
  }
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  z-index: 2;
}

.modalBody {
  flex-grow: 1;
  padding: 16px;
  position: relative;
  overflow: auto;

  &.modalLoading {
    min-height: 200px;
  }

  .loading {
    position: absolute;
    margin: auto;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
  }

  textarea {
    background-color: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 4px;
    min-height: 100px;
    padding: 8px 12px;
    width: 100%;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-top: 1px solid $grey-400;

  &.hasButton {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .footerInner {
    text-align: center;
    margin: 0 16px;

    > * {
      margin: 0;
    }
  }
}
