@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.canvasContainer {
  display: flex;
  flex-direction: column;
  min-height: 400px;

  .canvasNoDataMessage {
    min-height: 400px;
  }

  .canvasPreloader {
    width: 80px;
    height: 60px;
  }

  .canvasOuter {
    display: contents;
    min-width: 100%;
    height: 400px;
    flex-grow: 1;

    &.canvasLoading {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .archerContainer {
      overflow-x: auto;
    }

    .canvas {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 64px;
      width: 100%;
      min-width: 1200px;
    }
  }
}

.canvasLane {
  position: relative;

  &:hover .addCardButton {
    display: block;
  }

  &:nth-child(2) {
    .laneTitleBox,
    .canvasCard,
    .addCardButton {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:nth-child(3) {
    .laneTitleBox,
    .canvasCard,
    .addCardButton {
      margin-left: auto;
    }
  }

  .laneTitleBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;
    width: 400px;

    margin-bottom: 16px;

    padding: 8px;
    font-size: $base-font-size;
    font-weight: 600;
    border-radius: $border-radius-small;

    .laneTitle {
      display: block;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;

      &:hover {
        text-overflow: initial;
        white-space: initial;
        word-break: break-all;
      }
    }

    .editLaneButton {
      display: none;
      padding: 0;
    }

    &:hover {
      .editLaneButton {
        display: block;
      }
    }
  }

  .addMetricsButton {
    width: 100%;
  }

  .canvasCard {
    position: relative;
    margin-bottom: 12px;
    width: 400px;
    min-height: 75px;
    border-radius: $border-radius-small;
    transition: ease-in-out;

    .canvasCardInner {
      padding: 8px 16px;
    }

    .fetchingPagePreview {
      margin: 8px 0;
      width: 36px;
      height: 24px;
    }

    .imgLoadingError {
      display: block;
      padding-top: 4px;
      margin-bottom: 4px;
      font-size: $x-small-font-size;
      color: $error-red;
      font-style: italic;
    }

    .cardTitleContainer {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      padding-right: 32px;

      .cardTitle {
        margin: 0;
        font-size: $small-font-size;
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
          white-space: initial;
          text-overflow: initial;
          word-break: break-all;
        }
      }

      div[class*='url-validation-message_badge'] {
        margin: 0;
      }
    }

    .cardContent {
      margin: 4px 0;

      span {
        display: block;
        margin-bottom: 4px;
        font-size: $x-small-font-size;
      }
    }

    .cardImagesContainer {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;

      img,
      video {
        display: block;
        max-height: 125px;
        max-width: 100%;
      }
    }

    .editCardButton {
      position: absolute;
      top: 4px;
      right: 0;
      margin-right: -12px;
    }
  }
}

.noConversions {
  width: 400px;
  margin-left: auto;

  p {
    margin-bottom: 8px;
  }
}

.addCardButton {
  display: none;
  width: 400px;
  border: 2px dashed;
}

.addConnectionTooltip {
  position: absolute;
  top: 50%;
  border-bottom: none;

  &.left {
    left: 0;
    transform: translateY(-50%) translateX(calc(-50% - 2px));
  }

  &.right {
    right: 0;
    transform: translateY(-50%) translateX(calc(50% + 2px));
  }

  > button {
    background-color: $primary-color;
    border-radius: 50%;
    padding: 0;
  }
}

.cardImage {
  display: block;
  min-width: calc(100% - 32px);
  max-width: 100%;
  border-radius: $border-radius-small;
}

.additionalCardImage {
  display: block;
  max-height: 175px;
  max-width: 100%;
  border-radius: $border-radius-small;
  margin-bottom: 8px;
}

.editModal {
  .cardImagesContainer {
    padding: 16px 0;
    border-bottom: 1px solid $grey-200;

    h3 {
      text-align: left !important;
    }

    .fileUploader {
      width: 100%;
      margin-bottom: 16px;

      .dragAndDrop > div[class*='dropZoneWrapper'] {
        padding-top: 0;
        margin-bottom: 0;
      }

      p[class*='error'] {
        margin: 4px 0 0;
      }
    }
  }

  .addCustomMetricsContainer {
    padding: 16px 0;

    h3 {
      text-align: left !important;
    }

    .addMetricBlock {
      display: flex;
      gap: 8px;

      &:first-child {
        padding-right: 40px;
      }

      &:last-child {
        .metricRowNameField,
        .metricRowValueField {
          margin-bottom: 0;
        }
      }

      .metricRowNameField,
      .metricRowValueField {
        flex: auto;
        width: auto;
      }

      .metricRowValueField {
        max-width: 120px;
      }

      .deleteMetricButton {
        display: block;
        width: 32px;
        height: 40px;
        margin: 0;
        padding: 0;
      }
    }

    .addAnotherMetric {
      display: block;
      margin: 8px auto;
    }
  }

  .footNoteError {
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 0;
    color: $error-red;
  }
}

.existingCardImages {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 16px;

  .editCardImageContainer {
    position: relative;

    .editCardImage {
      display: block;
      max-height: 125px;
      max-width: 200px;
      border-radius: $border-radius-small;
    }

    &:hover {
      .deleteImageButton {
        display: block;
      }
    }

    .deleteImageButton {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
      width: 32px;
      height: 40px;
    }
  }
}

/* saveNewBoardModalFields */
.availableToContainer {
  border: 1px solid $grey-200;
  border-radius: $border-radius-medium;
  overflow: hidden;
  margin-bottom: 16px;

  label {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0 12px;

    &:nth-child(even) {
      background-color: $grey-100;
    }
  }

  p {
    padding-left: 34px;
    color: $grey-600;
    font-size: $small-font-size;
    margin: 0;
  }

  .availableToInput {
    & > span:after {
      content: ' ';
      display: inline-block;
      width: 26px;
      height: 34px;
      margin-left: 12px;
    }

    &.availableToInputCompany {
      & > span:after {
        background: transparent url(../assets/icon-company.svg) no-repeat 100%
          50%;
        background-size: 20px;
      }
    }

    &.availableToInputAccount {
      & > span:after {
        background: transparent url(../assets/icon-account.svg) no-repeat 100%
          50%;
        background-size: 24px;
      }
    }

    &.availableToInputUser {
      & > span:after {
        background: transparent url(../assets/icon-eye-crossed.svg) no-repeat
          100% 50%;
        background-size: 20px;
      }
    }
  }
}

.updateFiltersButton {
  display: block;
  margin: 8px auto;
}

.freezeViewContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  margin-top: 8px;

  .freezeViewTitle {
    margin: 0;
    flex-shrink: 0;
  }

  .freezeViewPill {
    flex-shrink: 1;
    max-width: 100%;
    overflow: hidden;

    button {
      margin: 0;
      background: $grey-200;
      color: $grey-600;
      height: 32px;
      line-height: 32px;
      padding: 0 24px 0 16px;
      transition: none;
      max-width: 100%;

      &::after {
        display: none;
        background-image: url(../assets/icon-close-black.svg);
      }

      &:hover {
        color: $grey-600;
        background: $grey-200;
        border-color: $grey-400;

        &::after {
          display: block;
        }
      }

      div {
        max-width: 100%;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .filterDimensionName {
      font-weight: 600;
    }
  }
}

.modalFormRow {
  span[class*='input_container'] {
    margin: 0;
  }
}

.creativeVideo {
  max-width: 100%;
}
