@import '../core/variables.scss';
@import '../core/breakpoints.scss';
@import './sso.module.scss';

.svg {
  width: 220px;
  height: 140px;
  display: block;
  margin: 0 auto;
}
.box {
  margin: 40px 0 48px;
}
