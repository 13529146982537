@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.tableLinkWrapper {
  display: inline-block;
}

.tableLink {
  display: inline;
}

.tableContainer {
  border: 1px solid $grey-300;
  border-radius: $border-radius-medium;
  overflow: auto;
  margin-bottom: 16px;
}

.linksTable {
  thead {
    th {
      text-align: center;
      padding: 8px 24px;
      line-height: 1.8rem;

      &:first-child {
        text-align: left;
      }

      @include medium {
        white-space: normal;
        width: 135px;
        min-width: 135px;

        &.fullMatch {
          min-width: 800px;
        }
      }

      &.linksMainHeaderCell {
        width: auto;
        padding-left: 16px !important;

        .headerButton {
          width: 100%;

          > div {
            justify-content: flex-start;
          }

          &:hover {
            cursor: pointer;
            text-decoration: none !important;
          }

          .tableTitle {
            margin: 0;
            white-space: nowrap;

            .titleText {
              color: $black;
              font-size: $h2-font-size-mobile;
              line-height: $h2-line-height;
              font-weight: bold;
            }
          }
        }
      }

      &.metricTypeHeaderColumn {
        text-align: left;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 8px;
        height: 40px;

        &.successMetric {
          background-color: $light-green;
        }

        &.supportMetric {
          background-color: rgba($secondary-color, 0.3);
        }
      }

      &.metricColumnHeader {
        width: 140px;
        max-width: 140px;
        padding: 8px 34px 8px 24px;
        line-height: 1.8rem;
        height: 60px;
        text-align: center;
        border-radius: 0;

        @include medium {
          white-space: normal;
          width: 135px;
          min-width: 135px;
        }
      }

      .loadingText {
        height: 21px;
        margin: 8px 0;
      }
    }

    &:hover {
      th.metricColumnHeader {
        padding-left: 14px;
      }

      .headerTooltip,
      .highlightCheckboxContainer,
      .warningTooltip {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.25s;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        .successMetricColumn {
          background-color: rgba($grey-200, 0.9) !important;
        }
      }

      td {
        width: 135px;
        max-width: 140px;

        &.tdCode {
          position: relative;
          font-weight: normal;
          word-break: break-all;
          white-space: normal !important;

          @include medium {
            max-width: 1000px !important;
          }
        }

        &.valueCell {
          text-align: center;
        }
      }
    }
  }

  @include medium {
    max-width: 100%;
    width: 100%;
  }
}

.headerTooltip {
  opacity: 0;
  font-weight: normal;
  position: absolute !important;
  right: 6px;
  top: 6px;

  p {
    text-align: center !important;
  }
}

.warningTooltip {
  visibility: hidden;
  position: absolute;
  top: 6px;
  right: 30px;
  width: 20px;
  height: 20px;
  color: $white;
  text-align: center;
  font-size: 14px;
  line-height: initial;
  font-weight: 700;
  background-color: $quaternary-color;
  border-radius: 50%;
  cursor: pointer;
}

.successMetricColumn {
  font-weight: 600 !important;
  background: rgba($grey-200, 0.4) !important;
}

.orderArrow {
  margin: 0 0 0 3px;
  opacity: 0.8;
  display: inline-block;
  position: absolute;
  bottom: 0px;
  right: 8px;
  top: auto;
}

.notSetRow {
  display: flex;

  .notSetTooltip {
    border-bottom-width: 0;

    &:hover div {
      text-decoration: none !important;
    }
  }

  .notSet {
    margin-right: 8px;
  }

  .hint {
    display: block;
    padding: 4px 8px;
    border-radius: 22px;
    font-size: $x-small-font-size;
    width: 22px;
    height: 22px;
    line-height: 12px;
    margin-top: 0;
    margin-right: 12px;
    border: 1px solid $grey-400;
    color: $grey-500;

    &.hintPartial {
      text-align: center;
      font-weight: 600;
    }
  }
}
