@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.existingDropdownsHeader {
  > div {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h3 {
      margin: 0;
    }
  }

  .usageButton {
    margin: 8px 0 0 auto;
    min-width: 175px;
    display: block;

    @include medium {
      margin-top: 0;
    }
  }

  .downloadError {
    text-align: right;
    color: $error-red;
    font-size: $small-font-size;
    margin: 4px 0 0;
  }
}

.accordionHeader {
  padding-right: 40px !important;
}

.buttonRow {
  justify-content: space-between;

  .navButton {
    padding: 0;
  }
}
