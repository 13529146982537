@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.intro {
  margin-bottom: 20px;
}

.beta {
  transform: translateY(-12px);
}

.titleTooltip {
  top: 6px;
  right: 6px;
  font-weight: normal;
  opacity: 0;
  position: absolute;
}

.filterBox {
  display: flex;
  justify-items: center;
  padding-top: 4px;

  h4 {
    display: block;
    height: 32px;
    font-size: $small-font-size;
    line-height: 32px;
    margin: 0 8px 0 0;
    font-weight: normal;
  }

  .filtersWrapper {
    display: flex;
    margin-right: -8px;
  }
}

.paper {
  padding: 16px;
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: 0 $border-radius-small $border-radius-small
    $border-radius-small;
  border-top-width: 0;
  min-width: 100%;
}

.resetButton {
  background-color: $grey-100;
  margin: -10px 0 0;

  &:hover {
    background-color: $grey-100;
  }
}

.boxTitle {
  position: absolute;
  top: -12px;
  left: 8px;
  background-color: $grey-100;
  padding: 0 8px;
  border-radius: $border-radius-small;
}
.buttonRowBottom {
  width: 100%;
  justify-content: space-between !important;
  align-items: center;
}

.successMetricSelector {
  div[class*='singleValue'] {
    width: max-content;
    min-width: 100px;
    text-transform: lowercase;
  }

  div[class*='_menu_'] {
    min-width: 250px;
    text-transform: lowercase;

    div[class*='tooltip'] {
      text-transform: initial;
    }
  }

  @include medium {
    margin: 0 8px;
  }
}

.graphWrapper {
  position: relative;
  padding: 16px 0;
  width: fit-content;

  h1 {
    font-size: $medium-font-size;
    margin: 0;
    align-items: center;

    @include medium-wide {
      display: flex;
    }
  }

  .successMetricSelector {
    width: 100%;
    margin: 8px 0 12px 0;

    @include medium {
      > input {
        transform: translateY(-4px);
      }
    }

    @include medium-wide {
      width: auto;
      margin: 0 8px 0 12px;
    }
  }

  @include medium {
    &:hover {
      .graphTitle .titleSelectWrapper.hideBreakdown {
        visibility: visible;
        opacity: 1;
      }
    }

    &:not(:hover) {
      .successMetricSelector {
        margin-left: 0;

        div[class*='control'] {
          font-size: $medium-font-size;
          background-color: transparent;
          border-color: transparent;

          div[class*='ValueContainer'] {
            padding-left: 0;

            > div[class*='singleValue'] {
              margin-left: 0;
            }
          }

          div[class*='dropdownIndicator'] {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.graphTitle {
  line-height: 100%;

  .titleSelectWrapper {
    @include medium {
      &.hideBreakdown {
        visibility: hidden;
        opacity: 0;
      }
    }

    > span {
      text-transform: lowercase;
      display: inline-block;
      padding-left: 6px;
    }

    .successMetricSelector {
      margin-left: 4px;

      div[class*='control'] {
        font-size: $medium-font-size;
      }

      div[class*='placeholder'] {
        color: $text-color;
      }
    }
  }
}

.breakdownDims {
  @include medium-wide {
    position: absolute;
    right: 20px;
    top: 0;
    margin: 0;
    width: auto;
  }
}

.tableWrapper {
  width: 100%;
  padding-top: 8px;

  thead:hover {
    .titleTooltip {
      opacity: 1;
      transition: opacity 0.25s;
    }
  }
}

button.shareButton {
  margin-right: 0;
  padding-right: 30px;
  & > span {
    width: 130px;
  }
}

.titleSelectTitle {
  margin-right: 8px;
  > span {
    text-transform: capitalize !important;
  }
}

.successMetricMulti {
  border-color: transparent;
  background-color: $grey-200;
  > span > p {
    color: $primary-color;
    font-weight: 600;
  }
  > span > button {
    background-color: $grey-200 !important;
  }
  span[class*='hint'] {
    display: none !important;
  }
}

.errorMsg {
  color: $error-red;
}

.successMetricSelector {
  width: 220px;
  margin: 0 0 0 auto;

  div[class*='_menu_'] {
    right: 0;
  }
}

.editMetrics {
  display: block;
  height: 40px;
  padding-left: 10px;
  line-height: 1em;
  font-size: $button-font-size;
}
