@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.companyLogoComponent {
  display: block;
  width: auto;

  .logoWrapper {
    position: relative;
    width: fit-content;
    min-width: 120px;
    min-height: 120px;

    > img {
      display: block;
      min-height: 120px;
      max-height: 200px;
      max-width: 260px;
      object-fit: contain;
    }

    .hoverButtons {
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover .hoverButtons {
      display: flex;
    }
  }

  .error {
    color: $error-red;
    margin-bottom: 0;
  }
}
