@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.nextArrow {
  height: 16px;
  width: 16px;
  fill: #007d9b;
}

.previousArrow {
  composes: nextArrow;
  transform: rotate(180deg);
}

.carouselHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 0 16px;

  @include medium {
    flex-direction: row;
  }
  [aria-label='button-group'] {
    min-width: 60px;
    padding-top: 8px;
    white-space: nowrap;
    @include medium {
      padding-top: 0;
    }
  }

  h3 {
    margin: 0;
    vertical-align: middle;
    color: $grey-700;
    font-weight: 600;
    font-size: $base-font-size;
  }

  svg {
    position: relative;
    top: 3px;
    fill: #d4366a;
  }

  // div {
  //   max-width: 100px;
  //   margin: 0;
  //   margin-right: 0px;
  // }

  [data-type='separator'] {
    margin-left: 10px;
  }

  span {
    margin: 0;
    color: $grey-500;
    margin-left: 16px;
    margin-right: 16px;
    min-width: 40px;
    font-size: 1.6rem;
    font-weight: 600;
  }
}

.nextButton span {
  display: none;
}

.nextButton {
  height: auto;
  line-height: 1em;
  padding: 0;
  margin: -3px 0 0 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  vertical-align: top;
  @include medium {
    padding: 0 16px 0 0;
  }
}

.column {
  flex-grow: 2;
  display: flex;
  // flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include medium {
    flex-direction: row;
    align-items: center;
  }
}
.pagination {
  margin-left: 8px;
  margin-right: 8px;
  white-space: nowrap;

  @include medium {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.columnRight {
  composes: column;
  width: 100%;
  justify-content: flex-start;
  margin-top: 8px;

  @include medium {
    justify-content: flex-end;
    width: auto;
    margin-top: 0;
  }
}

.carouselBody {
  border-radius: 3px;
  width: 100%;
  flex: 1;
  margin-bottom: 16px;
  background-color: $white;
  padding-bottom: 0;
  padding: 0 10px 0 0;
  p {
    font-size: $small-font-size;
    line-height: 1.6em;
    @include medium {
      font-size: $medium-font-size;
    }
  }
  @include medium {
    padding: 0;
  }

  @include site-width {
    margin-top: 0;
    flex: 1;
  }
}

.buttonWrapper {
  display: flex;
}
