@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.connectorStatus {
  position: relative;
  font-size: $x-small-font-size;
  color: $grey-600;
  margin: 0;

  &.connected::before,
  &.attention::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
    width: 14px;
    height: 14px;
    border-radius: 10px;
    margin-right: 8px;
    line-height: $x-small-font-size;
  }

  &.connected {
    color: $green;
    padding-left: 18px;

    &::before {
      background: transparent url(../assets/icon-check-green.svg) no-repeat;
      background-size: 14px;
    }
  }

  &.attention {
    color: $error-red;
    padding-left: 18px;

    &::before {
      background: transparent url(../assets/icon-check-red.svg) no-repeat;
      background-size: 14px;
    }
  }
}

/* **************************************************** */
/* Modal */
/* **************************************************** */

.connectModal {
  // min-height: 700px;

  div[class*='modalBody'] {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 7px;
  }

  .connectorSummary {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px;
    margin-bottom: 16px;

    .connectorLogo {
      max-width: 150px;
    }

    p {
      margin: 8px 0;
    }
  }

  .tabsContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    div[class*='button-tabs_content'] {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .outerBox {
        flex-grow: 1;
        border-top-width: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-bottom: 0;
      }
    }
  }

  div[class*='modalFooter'] {
    border-top-width: 0;
  }
}

.supportTab {
  color: $tertiary-color !important;
}

.sendFeedbackBtn {
  display: block;
  margin-left: auto;
}

.success {
  color: $green;
}

.error {
  text-align: right;
  color: $error-red;
  font-size: $x-small-font-size;
  margin-top: 8px;
}

.footerError {
  color: $error-red;
  font-size: $x-small-font-size;
}

/* **************************************************** */
/* Tile */
/* **************************************************** */

.container {
  margin: auto;
  height: 100%;
  min-height: 200px;
  width: 100%;

  @include site-width {
    &:nth-child(3n - 2) {
      margin-left: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  div[class*='innerBox'] {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.connectorHeader {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  height: 85px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 6px;
    height: 100%;

    h3 {
      margin: 0;
    }
  }
}

.connectorLogo {
  max-width: 100px;
  max-height: 85px;
  margin-right: 16px;
}

.tagsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .connectorTag {
    right: 0;
  }
}

.connectButton {
  margin-top: auto;
}
