@import '../core/variables.scss';

.checkboxField {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  label {
    margin: 0;
    flex: 0 0;

    span[class*='inputWrapper'] {
      align-items: center;
    }

    span[class*='labelText'] {
      font-weight: 600;
    }
  }
}

.mockButtonDropdown > div[class*='mainButtonContainer'] {
  font-size: $xx-small-font-size;

  > div[class*='dropdownMainButton'] {
    padding: 2px 4px;
  }

  > div[class*='dropdownArrowButton'] {
    padding: 2px 6px;

    > svg {
      width: 6px;
    }
  }
}

.loadingMsg {
  margin-bottom: 0;
  color: $grey-600;
}
