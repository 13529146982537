@import '../core/variables.scss';
@import '../core/breakpoints.scss';

/* ************************************* */
// Domain selector
/* ************************************* */

.domainSelectorContainer {
  min-width: fit-content;
  max-width: 200px;

  div[class*='menu_'] {
    min-width: 250px;
  }

  .addButton {
    font-weight: 600;
    font-size: $button-font-size;
  }
}

/* ************************************* */
// Alias input
/* ************************************* */

.aliasContainer {
  position: relative;
  flex-grow: 1;

  .shortLinkInput {
    min-width: 160px;
    margin-bottom: 0;

    &.error .inputWrapper {
      border-color: $error-red;
    }

    &.disabled input {
      color: $grey-500;
    }

    &.editable:not(.disabled) input {
      color: $black;
    }

    &.shortLinkInputAnimated .shortLinkInputButton {
      animation: rotating 0.5s linear infinite;
    }

    input {
      border: 1px solid $grey-300;
      padding: 0 30px 0 8px;
      margin: 0;
      border-radius: $border-radius-small;
      color: $grey-600;

      &:focus {
        & + span .shortLinkInputButton {
          animation: none;
        }
      }
    }

    span[class*='inputWrapper'] {
      padding-right: 0;
      background: transparent;
      position: relative;
      display: flex;
      justify-content: flex-start;
      border-radius: $border-radius-small;
      line-height: $button-line-height;
      height: 38px;
      background-color: $white;
      border: none;
      width: 100%;
      font-weight: normal;
      border-width: 0;

      & > span:first-child {
        margin: 0;
      }
    }

    .shortLinkInputButton {
      position: absolute;
      top: 0;
      right: 8px;
      background-color: transparent !important;
      height: 40px;
    }
  }

  .validationCheck {
    display: inline-block;
    font-size: $x-small-font-size;
    line-height: $x-small-font-size;
    align-items: center;
    padding-top: 8px;
    position: relative;
    padding-left: 18px;

    p,
    a,
    span {
      margin: 0;
      display: inline;
      font-size: $x-small-font-size;
      line-height: $medium-font-size;

      a {
        text-decoration: underline;
      }
    }

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 10px;
      width: 14px;
      height: 14px;
      background: transparent url(../assets/icon-check-grey.svg) no-repeat;
      border-radius: 10px;
      margin-right: 8px;
      line-height: $x-small-font-size;
      background-size: 14px;
    }
  }

  .validationCheckShortLink {
    margin: 0;

    &.urlIsBad {
      color: $error-red;

      a {
        color: $error-red;
      }

      &::before {
        background: url('../assets/remove.svg') no-repeat;
      }
    }

    &.urlIsGood {
      color: $green;

      &:before {
        background: transparent url(../assets/icon-check-green.svg) no-repeat;
        background-size: 14px;
      }
    }
  }

  .validationCheckItem {
    margin-right: 6px;
    display: inline;
  }

  .editableLinkBiLine {
    position: absolute;
    margin-top: 16px;
    right: 0;
    text-align: right;

    &:before {
      right: 20%;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ************************************* */
// Combined Input
/* ************************************* */

.fullShortLinkWrapper {
  display: flex;
  position: relative;
  justify-content: flex-start;
  flex-direction: row;
  gap: 8px;
  border-radius: $border-radius-small;
  border: 1px solid $grey-300;
  min-height: $button-height;
  width: 100%;
  background: $white;
  border-width: 0;
  background: transparent;

  @include medium {
    width: auto;
  }

  .domainSelectorContainer {
    width: 170px;
    height: fit-content;
  }

  .staticDomainValue {
    margin: 0;
    font-size: $small-font-size;
    color: $grey-600;
    line-height: 38px;
  }

  .shortLinkInputPrefixDiv {
    display: block;
    margin: 0;
    color: $grey-600;
    line-height: 38px;
  }
}
