@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.modalLabel {
  display: flex;

  & > :nth-child(2) {
    order: 3;
  }

  & > :nth-child(3) {
    order: 2;
    line-height: 21px;
    margin-left: 8px;
  }
}

.domainInput {
  margin-bottom: 0;
}

.modalTitle {
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    height: 30px;
  }
}

.featureList {
  padding-inline-start: 20px;

  li {
    margin-bottom: 8px;
    padding-left: 10px;

    &::marker {
      content: url(../assets/tick.svg);
      position: absolute;
      margin-right: 4px;
    }
  }
}
