@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.errorMsg {
  color: $error-red;
  margin: 0;
}

.businessUnitDropdownRow {
  gap: 8px;
  padding: 0;

  .businessUnitDropdown {
    max-width: 74%;
    margin: 0;
  }

  button {
    flex-grow: 1;
    max-width: 200px;
  }
}

.tenantSuccess {
  color: $green;
}

.tenantError {
  color: $error-red;
  font-size: $small-font-size;
  margin: 4px 0 0;
}

.divider {
  border-top: 1px solid $grey-400;
  margin: 16px 0;
}

.existingOktaTenants {
  padding: 0 16px;

  em {
    font-size: $small-font-size;
  }

  li {
    word-wrap: break-word;
  }

  div[class*='buttonRow'] {
    justify-content: space-between;
    align-items: center;
  }

  .deleteTenantBtn {
    margin-right: 0;
  }
}

.addTenantFormRow {
  padding-bottom: 8px;
  border-bottom: 0;
}

.addDataSourceBtn {
  display: block;
  margin-left: auto;
}

.propertyTypeFormRow {
  padding: 0;

  .propertyTypeSelector {
    display: flex;
  }
}

.editTenantField {
  width: fit-content;

  > label {
    cursor: pointer;
    font-size: $base-font-size;
    font-weight: 700;
    background: url('../assets/edit.svg') no-repeat 100% 50%;
    background-size: 30px;
  }
}

.passwordField {
  position: relative;

  .showPassword {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    font-size: $button-font-size;
    padding: 0 10px;

    &:hover {
      text-decoration: none;
    }
  }
}

.picklistInputs {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-wrap: wrap;

  .picklist {
    flex-grow: 1;
  }

  .submitButton {
    width: auto !important;
  }
}
