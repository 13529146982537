@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.dateWrapper {
  max-width: 170px;
  height: $button-height;
  box-sizing: border-box;
  border: 1px solid $grey-400 !important;
  border-radius: $border-radius-small;

  &.dateError {
    border-color: $error-red !important;
  }

  &.buttonOnly {
    position: relative;
    width: 40px;

    > div::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 50%;
      transform: translateY(-50%);
      width: 75%;
      height: 75%;
      background-image: url('../assets/icon-calendar.svg');
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;
    }

    .dateInput {
      opacity: 0;
      padding: 0;
      cursor: pointer;
    }
  }

  &.grey {
    border-width: 0 !important;
  }

  > div {
    height: 100%;

    .dateInput {
      width: 100%;
      height: 100%;
      background: $white url(../assets/icon-calendar-grey.svg) no-repeat
        calc(100% - 10px) 50%;
      border: none;
      border-radius: $border-radius-small;
      padding: 0 30px 0 12px;
      font-size: $button-font-size;
      font-weight: normal;
      line-height: $button-line-height;

      &[disabled] {
        opacity: 0.9;
        cursor: not-allowed;
      }

      &::placeholder {
        color: $grey-500;
      }

      &.grey {
        background-color: $grey-200;
        color: $primary-color;
        font-weight: 500;

        &::placeholder {
          color: $primary-color;
          font-weight: 500;
        }
      }
    }
  }

  .clearButton {
    background: url(../assets/icon-close-grey.svg) no-repeat;
    top: 50%;
    right: 34px;
    transform: translateY(-50%);
    padding: 2px;
    width: 17px;
    height: 17px;

    &::after {
      content: none;
    }
  }
}

.popper {
  padding-top: 5px !important;
  z-index: 10 !important;
}

.calendar {
  box-shadow: 0 0 0 2px $black;

  div[class*='react-datepicker__triangle'] {
    display: none;
  }

  div[class*='range-start'],
  div[class*='selecting-range-end'],
  div[class*='range-end'] {
    color: $white !important;
    background-color: $primary-color !important;
  }

  div[class*='in-selecting-range'],
  div[class*='in-range'],
  div[class*='keyboard-selected'] {
    color: $text-color;
    background-color: $pink;

    &:hover {
      color: $white !important;
      background-color: $primary-color !important;
    }
  }
}
