@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.introContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;

  .lockContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .toggleLabel {
      margin: 0;
      color: $grey-600;

      &.isLocked {
        color: $primary-color;
      }
    }

    .lockIcon {
      position: absolute;
      width: 24px;
      height: 24px;
      max-width: 80%;
      max-height: 90%;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      margin: 0;
    }
  }
}

.lockIcon {
  display: inline-block;
  width: 20px;
  height: $base-line-height;
  margin-left: 5px;
  vertical-align: bottom;
}

.errorMessage {
  color: $error-red;
  font-size: $small-font-size;
  margin: 0;
}

.unlockModal div[class*='footerInner'] {
  margin: 0;
}
