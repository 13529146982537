@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.helpfulWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  :nth-child(1) {
    order: 3;
  }
  :nth-child(2) {
    order: 1;
    margin: 0;
  }
  :nth-child(3) {
    order: 2;
  }
  @include medium {
    flex-direction: row;
    :nth-child(1) {
      order: 1;
    }
    :nth-child(2) {
      order: 2;
      margin-left: 8px;
    }
    :nth-child(3) {
      order: 3;
    }
  }

  button {
    display: block;
    width: 35px;
    height: 0;
    padding-top: 35px;
    overflow: hidden;
    background-position: 50%;
    background-size: 15px;
    background-color: $white;
    border: 1px solid $grey-200;
    background-repeat: no-repeat;
    margin-left: 8px;
    &:hover {
      cursor: pointer;
      background-color: $grey-100;
    }
  }
}
.helpfulTitle {
  font-size: $small-font-size;
  color: $grey-900;
  font-weight: 500;
}

.yes {
  background-image: url(../assets/thumb-up.svg);
}
.no {
  background-image: url(../assets/thumb-down.svg);
}
