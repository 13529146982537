@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.summaryContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid $grey-200;

  @include medium {
    flex-direction: row;
  }

  .summary p {
    margin-bottom: 8px;
  }
}

.editTitle {
  width: 100%;
  display: inline-block;
  min-width: 300px;
  z-index: 0;

  @include medium {
    width: auto;
  }

  label {
    font-size: 1.8rem;
    line-height: 42px;
    font-weight: 700;
  }
}

.successMetric p {
  display: inline;
}

.filterContainer {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 8px;

  .filterTitle {
    margin: 0;
    flex-shrink: 0;
  }

  .filterPillsContainer {
    flex-shrink: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-width: 100%;
    overflow: hidden;

    .filterPill {
      max-width: 100%;

      button {
        margin: 0;
        background: $grey-200;
        color: $grey-600;
        height: 32px;
        line-height: 32px;
        padding: 0 24px 0 16px;
        transition: none;
        max-width: 100%;

        &::after {
          background-image: url(../assets/icon-close-black.svg);
          display: none;
        }

        &:hover {
          color: $grey-600;
          background: $grey-200;
          border-color: $grey-400;

          &::after {
            display: block;
          }
        }

        div {
          max-width: 100%;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .filterDimensionName {
        font-weight: 600;
      }
    }
  }
}

.logoWrapper {
  height: 100px;

  .workspaceLogo {
    width: auto;
    min-width: auto;
  }

  img {
    min-height: auto !important;
    max-height: 100px !important;
    max-width: auto !important;
  }

  div [class*='dropZone'] {
    min-height: 85px;
  }
}

.error {
  color: $error-red;
}
