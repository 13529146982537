@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.subsection {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .fieldRow {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    .field {
      min-width: 300px;
    }
  }
}

.paddleManagementLinks {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
