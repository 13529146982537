@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.errorMsg {
  color: $error-red;
  font-size: $x-small-font-size;
  line-height: $base-line-height;
}

.addButton {
  width: 100%;
  font-weight: 600;
  padding: 8px 12px;

  > div {
    justify-content: flex-start;
  }
}
