// --------------- variables --------------------

$site-padding: 20px 30px;
$site-padding-mobile: 0 12px 12px;

$primary-color: #e61969;
$secondary-color: #0fc3f0;
$tertiary-color: #007d9b;
$quaternary-color: #ffd70f;

$report-color: #ffd70f;
$audit-color: #0fc3f0;
$track-color: #007d9b;
$explain-color: #e61969;
$welcome-color: #96a4b8;
$settings-color: #9f9f9f;

$white: #fff;
$black: #1a202c;
$pink: #ffeef5;
$light-yellow: #fdf3ce;

$modal-background-color: rgba(0, 0, 0, 0.5);

$error-red: #dc3545;
$green: #38a169;
$light-green: #c6f6d5;

$text-color: #333;

// Blue-greys
$blue-grey-500: #96a4b8;
$blue-grey-600: #78879c;

// Greys
$navigation-grey: #f9f9f9;
$grey-100: #f7fafc;
$grey-200: #edf2f7;
$grey-300: #e2e8f0;
$grey-400: #cbd5e0;
$grey-500: #a0aec0;
$grey-600: #718096;
$grey-700: #4a5568;
$grey-800: #2d3748;
$grey-900: #1a202c;

$xx-small-font-size: 1.1rem;
$x-small-font-size: 1.3rem;
$small-font-size: 1.4rem;
$base-font-size: 1.5rem;
$medium-font-size: 1.8rem;
$large-font-size: 2rem;
$base-line-height: 2.2rem;

$h1-font-size: 2.4rem;
$h1-line-height: 4.2rem;

$h1-font-size-mobile: 2.2rem;
$h1-line-height-mobile: 3rem;

$h2-font-size-mobile: 1.8rem;
$h2-line-height-mobile: 2.8rem;

$h2-font-size: 2rem;
$h2-line-height: 3.2rem;

$h3-font-size: 1.8rem;
$h3-line-height: 2.2rem;

$compact-button-height: 26px;
$button-height: 40px;
$button-font-size: 1.4rem;
$button-line-height: 4rem;

$border-radius-small: 4px;
$border-radius-medium: 6px;
$border-radius-large: 16px;
$border-radius: 50px;
$animation-duration: 0.6s;

$box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
$box-shadow-large: 0px 1px 4px rgba(118, 130, 150, 0.33);
$box-shadow-x-large: 1px 1px 10px rgba(118, 130, 150, 0.33);
$box-shadow-large-pink: 1px 1px 10px rgba(230, 25, 105, 0.75);
