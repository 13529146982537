@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.placeholderRow {
  height: 75px;

  td {
    .loadingText {
      margin-top: 0;
    }
  }
}

.noScroll {
  overflow: hidden !important;

  td {
    padding: 0 !important;
  }
}

.table {
  border: 0;
  width: 100%;
  white-space: nowrap;
  border-spacing: 0;
  padding: 0;
  overflow: auto;

  thead {
    th {
      position: relative;
      text-align: left;
      padding: 0 24px 0 16px;
      border-right: 1px solid $grey-300;
      color: $grey-600;
      font-weight: 500;
      background-color: $white;
      white-space: nowrap;
      height: 60px;
      border-bottom: 1px solid $grey-300;

      &:first-child {
        border-radius: $border-radius-medium 0 0 0;
      }

      &:last-child {
        border-radius: 0 $border-radius-medium 0 0;
        border-right: 0;
      }

      button {
        position: relative;
      }
    }
  }

  tbody {
    tr {
      background-color: $white;

      &:nth-child(odd) {
        background-color: $grey-100;
      }

      &:last-child {
        td:first-child {
          border-bottom-left-radius: $border-radius-medium;
        }

        td:last-child {
          border-bottom-right-radius: $border-radius-medium;
        }
      }
    }
  }

  th,
  td {
    min-height: 60px;
    font-size: $x-small-font-size;
    line-height: 1.6em;
    text-align: left;
    padding: 6px 10px;
    border-right: 1px solid $grey-300;

    @include medium {
      min-width: 120px;
      padding: 8px 16px;
      white-space: nowrap;

      p {
        white-space: normal;
      }
    }

    &:last-child {
      border-right-width: 0;
    }
  }
}

.loadingText {
  margin: 8px 0;
}

.fullPageTable {
  .tableContainer {
    border: 1px solid $grey-300;
    border-radius: $border-radius-medium;
    overflow: auto;
    margin-bottom: 20px;
  }

  .paginationRow {
    position: relative;

    .pagination {
      padding-bottom: 16px;
    }

    .stickyScroll {
      display: none;
    }
  }

  &.showFixedFooter {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .paginationRow {
      @include medium {
        width: calc(100% + 40px);
        max-width: calc(100% + 40px);
        position: sticky;
        bottom: 0;
        margin: 0 auto -20px -20px;
        padding: 32px 20px 0;
        background-color: $grey-100;
        border-top: 1px solid $grey-300;
        z-index: 1;

        .stickyScroll {
          display: block;
          position: absolute;
          max-width: calc(100% - 40px);
          top: 8px;
          overflow: auto hidden;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;

          // Alex prefers default scrollbar
          // &::-webkit-scrollbar {
          //   width: 12px;
          //   height: 12px;
          //   position: absolute;
          //   bottom: 100px;
          // }

          // &::-webkit-scrollbar-track {
          //   background: $grey-200;
          // }

          // &::-webkit-scrollbar-thumb {
          //   background: $grey-300;
          //   border-radius: 4px;
          // }

          > .topScroll {
            padding-top: 1px;
            height: 0;
          }
        }
      }
    }

    .tableContainer {
      thead {
        position: sticky;
        background-color: $white;
        z-index: 1;
      }

      // Custom scrollbar is shown instead
      @include medium {
        div[class*='doubleScroll']:last-of-type::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .tableContainerInner {
    position: relative;

    .loadingContainer {
      position: fixed;
      background-color: $white;
      opacity: 0.8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .loading {
        height: 60px;
        width: 75px;
      }

      p {
        font-weight: 600;
      }
    }
  }

  .rowsLabelWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 16px 0;

    @include medium {
      padding: 0 0 16px 0;
      margin-top: 0;
    }
  }
}
