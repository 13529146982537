@import '../core/variables.scss';
@import '../core/breakpoints.scss';

/* TwoColumns */
.wrapper {
  display: flex;
  flex-direction: column;

  @include medium-wide {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

/* Column */
.columnWrapper {
  box-sizing: border-box;
  transition: 0.3s padding;

  &.mainColumn {
    background-color: $grey-100;
    align-self: clear;

    &.transparent {
      background-color: transparent;
    }

    &.equalColumn {
      padding: 12px;
    }

    @include medium-wide {
      width: 65%;

      &.equalColumn {
        width: 50%;
      }
    }
  }

  &.sideColumn {
    background-color: $grey-100;

    &.transparent {
      background-color: transparent;
    }

    @include medium-wide {
      width: 35%;
      min-width: 300px;
      margin-left: 32px;

      &.equalColumn {
        width: 50%;
        margin-left: 0;
      }
    }

    > div:after,
    .innerBox:first-child::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 37px;
      background: url(../assets/pin.png);
      background-size: 23px;
      background-repeat: no-repeat;
      background-position: calc(100% - 16px) 16px;
    }
  }

  &.fixedColumn {
    @include fixed-content-min-height {
      position: sticky;
      top: 10px;
    }

    .box {
      position: relative;
      margin-bottom: 16px;
    }
  }
}

/* OuterBox */
.box {
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: $border-radius-small;
  padding: 0;
  margin: 0 0 16px 0;
}

/* InnerBox */
.innerBox {
  position: relative;
  padding: 16px;

  @include medium {
    padding: 20px;
  }

  > button:last-child {
    margin-bottom: 0;
  }
}

.noPadding {
  padding: 0;

  @include medium {
    padding: 0;
  }
}

.divider {
  border-bottom: 1px solid $grey-300;
}
