@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.filter {
  display: none;
  margin: 0;

  @media (min-width: 768px) {
    display: block;
  }
}

.container {
  table tr td[data-row='heading'] {
    max-width: 200px !important;
    overflow: hidden;

    @media (min-width: 500px) {
      max-width: 400px !important;
    }
  }
}

.backButton {
  color: $primary-color;
  font-weight: 600;
  padding-left: 24px;
  font-size: $small-font-size;
  background: url(../assets/back-arrow.svg) no-repeat 0 50%;
  height: 32px;
  line-height: 32px;
}

.topPanel {
  margin-bottom: 4px;
  // display: flex;
  // flex-direction: column;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  .backButton {
    margin-top: 6px;
  }

  button {
    // order: 3;
    max-width: 250px;
  }

  h2 {
    // order: 2;
  }

  @include medium {
    // align-items: flex-start;

    & > * {
      // flex-basis: 50%;
      // flex-grow: 2;
    }

    button {
      margin-bottom: 0;
      // order: 2;
    }
    h2 {
      width: 100%;
      // order: 3;
    }
  }
}

.mainContent {
  display: block;
}

.footer {
  p {
    padding-left: 0;
    margin-left: 0;
  }
}

.columns {
  align-items: stretch !important;
}

.bottomPanel {
  width: 100%;
  border-top: 1px solid $grey-400;

  footer {
    padding: 16px 10px;
  }
}
.topBox {
  padding-bottom: 4px !important;
}
.topTitle {
  margin: -10px 0 16px;
}
.panelTitle {
  color: $grey-900;
  // color: $primary-color;
  padding-left: 28px;
  font-weight: 600;
  font-size: $base-font-size;
  background: url(../assets/star-cluster.svg) no-repeat 0 50%;
  margin-bottom: 16px;
}
.dimensionsTitle {
  color: $grey-900;
  font-weight: 600;
  font-size: $base-font-size;
  background-size: 18px;
  &:after {
    content: ' ';
    display: inline-block;
    height: 24px;
    width: 26px;
    margin-left: 8px;
    background: transparent url(../assets/star-full.svg) no-repeat 0 50%;
    vertical-align: middle;
    transform: translateY(-3px);
  }
}
.tagsWrapper {
  margin-bottom: 16px;
}
.graphWrapper {
  position: relative;
}
.interestingButton {
  position: absolute;
  top: 0;
  right: 0;
}

.pageTitle {
  // display: flex;
  // color: $grey-600;
  // margin-bottom: 8px;
  text-align: left;
  margin: 4px 0 8px;
  // align-items: baseline;
  // font-weight: 600;
  font-size: $base-font-size;
  line-height: 1.6em;
}
.rowTitleDate {
  margin-right: 6px;
}
.rowTitleDirection {
  display: inline;
  color: $grey-500;
  margin-right: 6px;
}
.rowTitleName {
  margin-right: 6px;
}
.titleWithButton {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.accountName {
  text-align: left;
  display: inline-block;
  margin: 0;
  font-weight: 600;
  font-size: $base-font-size;
  width: 100%;
  margin: 8px 0;
  line-height: 1.6em;
}
.emptyColumn {
  background: $grey-100 !important;
}
