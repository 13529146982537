@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.confirmation {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  background-color: $white;
  z-index: 3;
  overflow: hidden;

  .deleteConfirmIcon {
    background-color: transparent !important;
  }

  .loading {
    margin-left: 12px;
    padding: 4px !important;
  }

  p {
    margin: 0;
  }

  .pillButton {
    padding: 0;
    min-width: 65px;
    height: $compact-button-height;
    line-height: $compact-button-height;
  }
}
