@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
}

.blank {
  background: none;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.gradient {
  padding: 1px 20px 20px;
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(46deg, #eeeeee, #d4d4d4, #eeeeee);
  background-size: 600% 600%;
  -webkit-animation: loaderAnimation 3s ease infinite;
  -moz-animation: loaderAnimation 3s ease infinite;
  animation: loaderAnimation 3s ease infinite;
}

.fixedWidth {
  min-width: 160px;
}

.animatedDots {
  display: inline-block;
  position: relative;
  width: auto;
  white-space: nowrap;
  color: $grey-500;
  font-size: $small-font-size;
  line-height: 2.4rem;

  span > b {
    animation: grow 1s linear infinite;
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;

    b {
      font-weight: normal;
      width: 6px;
      display: inline-block;

      &:after {
        content: '.';
        padding: 0 1px;
        width: 3px;
        line-height: 18px;
      }
    }
  }
}

@keyframes grow {
  0% {
    width: 0;
  }

  100% {
    width: 25px;
  }
}

@-webkit-keyframes loaderAnimation {
  0% {
    background-position: 0% 84%;
  }
  50% {
    background-position: 100% 17%;
  }
  100% {
    background-position: 0% 84%;
  }
}
@-moz-keyframes loaderAnimation {
  0% {
    background-position: 0% 84%;
  }
  50% {
    background-position: 100% 17%;
  }
  100% {
    background-position: 0% 84%;
  }
}
@keyframes loaderAnimation {
  0% {
    background-position: 0% 84%;
  }
  50% {
    background-position: 100% 17%;
  }
  100% {
    background-position: 0% 84%;
  }
}

.gifContainer {
  display: flex;
  justify-content: space-between;
  width: 50px;
  height: 35px;
  margin: 16px auto;

  > div {
    display: block;
    width: 16%;
    height: 100%;
    border-radius: $border-radius-medium;
    animation: pulse 1.6s linear infinite;

    &:first-child {
      background-color: $primary-color;
    }

    &:nth-child(2) {
      background-color: $secondary-color;
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      background-color: $tertiary-color;
      animation-delay: 0.4s;
    }

    &:last-child {
      background-color: $quaternary-color;
      animation-delay: 0.6s;
    }
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

.logoContainer {
  display: block;
  position: relative;
  height: 100%;

  &.fullScreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    background: #fff;
  }

  .loader {
    display: block;
    position: absolute;
    height: 100px;
    width: 100px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: $white;
    transform: scale(1);
    animation: logoPulse 1.2s infinite;
  }
}

@keyframes logoPulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}
