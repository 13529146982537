@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.intro {
  margin-bottom: 20px;
}

.reportControlCopy {
  font-size: $small-font-size;
  line-height: 2.4rem;
  font-weight: 400;
  display: inline-flex;
  text-transform: capitalize;
  line-height: $button-height;

  @include medium {
    text-transform: none;
    font-size: $base-font-size;
  }
}

.controlsSelector {
  div[class*='multiValue'] {
    width: max-content;
    min-width: 100px;
  }

  div[class*='_menu_'] {
    min-width: 350px;
  }

  @include medium {
    margin: 0 8px;
  }
}

.paper {
  padding: 16px;
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: 0 $border-radius-small $border-radius-small
    $border-radius-small;
  border-top-width: 0;

  .paperHeader {
    padding: 20px 20px 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #e2e8f0;

    @include medium {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .paperHeaderContent {
    width: 100%;

    p {
      margin-bottom: 12px;

      @include medium {
        margin-bottom: 8px;
      }

      strong {
        font-weight: 600;
      }
    }
  }

  .logoWrapper {
    width: 180px;
    min-width: 180px;
    height: 90px;
    display: flex;
    justify-content: flex-end;
    margin: auto auto 16px;

    img {
      width: auto;
      max-width: 180px;
      height: auto;
      max-height: 90px;
      display: block;
    }
  }
}

.graphWrapper {
  position: relative;

  h1 {
    font-size: $medium-font-size;
    margin: 0;

    @include medium-wide {
      display: flex;
    }

    &.graphTitle {
      .titleSelectWrapper {
        > span {
          text-transform: lowercase;
          display: inline-block;
          padding-left: 6px;
        }
      }

      .successMetricSelector {
        margin-left: 6px;
      }
    }
  }

  .successMetricSelector {
    width: 100%;
    margin: 8px 0 12px 0;

    @include medium-wide {
      width: auto;
      margin: 0 8px 0 12px;
    }
  }
}

.metricNote {
  margin: 8px 0 0 !important;
  width: fit-content;

  strong {
    display: inline;
  }
}

.metricDataBannerWrapper {
  padding: 0 0 16px 0;
}

.metricDataBanner {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;

  @include medium-wide {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.afterTabsActionButtons {
  z-index: 10;

  @include medium {
    margin-left: auto;
    margin-top: -8px;
  }

  .actionButtonsWrapper {
    margin-left: auto;
    margin-bottom: 8px;

    @include site-width {
      margin: 0;
    }
  }

  .bulkButtons {
    width: 100%;
    margin: 0 0 16px;

    @include medium {
      margin: 0 0 0 8px;
      width: 160px;
      min-width: 160px;

      > div {
        min-width: 160px;
      }
    }
  }
}
