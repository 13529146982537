@import './variables.scss';
@import './breakpoints.scss';

.react-datepicker-popper {
  min-width: 320px;
  border-color: $grey-300;
  z-index: 2 !important;

  .react-datepicker {
    width: 100%;
    border-radius: $border-radius-small;
    font-family: 'Open Sans', sans-serif;
    font-size: $base-font-size;
    line-height: 2.4rem;
    font-weight: 400;

    .react-datepicker__month .react-datepicker__month-text {
      width: 33%;
      margin: 0;
      line-height: 3.4rem;
    }

    .react-datepicker__current-month {
      border-radius: $border-radius-small;
      font-size: $base-font-size;
      line-height: 2.4rem;
      font-weight: 600;
    }

    .react-datepicker__day--outside-month {
      color: $grey-500;
    }

    .react-datepicker__day-name {
      width: calc(100% / 7);
      margin: 0;
      line-height: 3.4rem;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__day {
      width: calc(100% / 7);
      margin: 0;
      line-height: 3.4rem;
    }

    .react-datepicker-year-header {
      border-radius: $border-radius-small;
      font-size: $base-font-size;
      line-height: 2.4rem;
      font-weight: 600;
    }

    .react-datepicker__navigation {
      top: 12px;
    }

    .react-datepicker__year-wrapper {
      width: 100%;
      max-width: 100%;
    }

    .react-datepicker__month .react-datepicker__quarter-text {
      width: 25%;
      margin: 0;
      line-height: 3.4rem;
    }

    .react-datepicker__year .react-datepicker__year-text {
      width: 33%;
      margin: 0;
      line-height: 3.4rem;
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: $tertiary-color;

    &:hover {
      background-color: $tertiary-color;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: $tertiary-color;

    &:hover {
      background-color: $tertiary-color;
    }
  }

  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: $tertiary-color;

    &:hover {
      background-color: $tertiary-color;
    }
  }
}
