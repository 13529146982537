@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.multiListTable {
  border: 1px solid $grey-300 !important;
  border-radius: $border-radius-small;
}

.deleteColumn {
  width: 56px;
  min-width: 56px !important;

  .deleteConfirmContainer {
    padding: 0 16px;
  }
}
