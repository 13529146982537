@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.issuesCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-small;
  padding: 0 6px;
  margin-left: 0px;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  // border: 2px solid $primary-color;
  font-size: $x-small-font-size;
  font-weight: 600;
  color: $primary-color;
  background: #ffe4ed;
  transform: translateX(9px);
}

.issuesCounterOK {
  composes: issuesCounter;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.issuesCounterLoading {
  opacity: 1;
}
.inProgress {
  composes: issuesCounterLoading;
  background-image: none;
}

.grey {
  background-color: $grey-300;
  border-color: $grey-300;
  color: $grey-700;

  &.inProgress {
    background-image: none;
  }
}
.error {
  background-color: rgba($error-red, 0.1);
  border-color: $error-red;
  color: $error-red;

  &.inProgress {
    background-image: none;
  }
}

.statusLabel {
  font-weight: normal;
  font-style: italic;
}

.error {
  .statusLabel {
    font-style: normal;
  }
}

.bulletCounter {
  height: 20px;
  position: absolute;
  right: 3px;
  top: 3px;
  background-color: $error-red;
  color: $white;
  font-size: 14px;
  font-weight: 600;
  padding: 0 6px;
  border-radius: 20px;
  line-height: 20px;
  min-width: 10px;
}

.bulletCounterBeta {
  position: relative;
  top: 0;
  right: -6px;
  background-color: $tertiary-color;
  color: $white;
  display: inline-block;
  // border: 1px solid $white;
}

.biLine {
  display: block;
  color: $text-color;
  font-weight: 400;
  border-radius: $border-radius-small;
  margin: 0;
  padding: 8px 16px;
  position: relative;
  background-color: $light-yellow;

  &.centerAlign {
    text-align: center;
  }

  &:before {
    position: absolute;
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    z-index: 1;
  }

  &.arrowRight {
    &:before {
      bottom: 50%;
      right: 0;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $light-yellow;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      transform: translate(20px, 50%);
    }
  }

  &.arrowLeft {
    &:before {
      bottom: 50%;
      left: 0;
      border-bottom: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 10px solid transparent;
      border-right: 10px solid $light-yellow;
      transform: translate(-20px, 50%);
    }
  }

  &.arrowTop {
    &:before {
      top: 0;
      right: 50%;
      border-bottom: 10px solid $light-yellow;
      border-left: 10px solid transparent;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      transform: translate(46%, -20px);
    }
  }

  &.arrowBottom {
    &:before {
      bottom: 0;
      left: 50%;
      border-bottom: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 10px solid $light-yellow;
      border-right: 10px solid transparent;
      transform: translate(50%, 20px);
    }
  }
}
