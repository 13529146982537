@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  flex: 100%;
  width: 100%;
  margin: 0 auto 16px;
  display: flex;
  align-items: center;

  .labelText {
    display: inline-block;
    font-size: $small-font-size;
    line-height: 34px;
    max-width: 100%;
    overflow: hidden;
    color: $grey-800;
    font-weight: normal;
    white-space: nowrap;

    @include medium {
      font-size: 1.4rem;
    }

    em {
      font-style: normal;
    }
  }
}

.hidden {
  display: none;
}

.checkboxContainer {
  b {
    background-color: $white;
  }
  &.disabled > span {
    opacity: 0.6;
  }
  .inputWrapper {
    width: auto;
    display: flex;
  }
}

.passiveContainer {
  composes: container;
  margin: 0;
  position: relative;
}

.input {
  border-radius: $border-radius-small;
  font-size: $button-font-size;
  line-height: $button-line-height;
  height: $button-height;
  display: block;
  background-color: $white;
  border: none;
  width: 100%;
  padding: 0 12px;
  font-weight: normal;
  border: 1px solid $grey-300;

  &[type='c'] {
    width: auto;
    display: inline-block;
    height: auto;
  }

  &[type='radio'] {
    width: auto;
    display: inline-block;
    display: none;
    margin-top: 10px;
    margin-right: 10px;
    height: auto;

    & ~ .radioControl {
      display: inline-block;
      width: 34px;
      height: 34px;
      background: url(../assets/radio-blank.svg) no-repeat 50% 50%;
      margin: 0 4px 0 0;
    }

    &:checked ~ .radioControl {
      background: url(../assets/radio-grey.svg) no-repeat 50% 50%;
    }
  }

  &[disabled] {
    opacity: 0.9;
    cursor: not-allowed;

    &::placeholder {
      color: $grey-500;
    }
  }
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 100%;
}

.clearButton {
  position: absolute;
  right: 10px;
  top: 2px;
  height: 36px;

  img {
    height: 12px !important;
  }
}

.prefix {
  line-height: 40px;
  margin-bottom: 20px;
  margin-right: 8px;
  font-size: $small-font-size;
  font-style: normal;
}

.optional {
  color: $grey-600;
  font-weight: 400;
  display: inline;
  font-size: 1.2rem;
  line-height: 1em;
}

.textArea {
  width: 100%;
  border: 1px solid $grey-300;
  resize: none;
  border-radius: $border-radius-small;
  background-color: $white;
  padding: 8px 12px;

  &:not(.smallTextArea) {
    min-height: 100px;
    margin-bottom: 20px;
  }

  &.smallTextArea {
    padding-right: 35px;
  }
  &.wordBreak {
    word-break: break-all;
  }

  &[disabled]::placeholder {
    color: $grey-500;
  }
}

.rowInput {
  border: 0;
  line-height: 4.5rem;
  border-bottom: 1px solid $grey-200;
  padding: 0;
  font-weight: 500;
  font-size: $small-font-size;
  margin: 0;
}

.input {
  &[type='checkbox'] {
    position: absolute;
    left: -9999px;
    display: inline-block;

    &:checked + b {
      background-image: url('../assets/tick-green.svg');
    }
  }
}

// checkbox styling
.container {
  b {
    display: inline-block;
    overflow: hidden;
    width: $compact-button-height;
    min-width: $compact-button-height;
    max-width: $compact-button-height;
    height: 0;
    padding: $compact-button-height 0 0 0;
    margin-right: 8px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 50% 8px;
    border: 1px solid $grey-300;
    border-radius: 6px;

    &.checkboxError {
      border-color: $error-red;
    }
  }
}

.list {
  font-size: 1.8rem;
  line-height: 4rem;
  font-weight: 500;
  padding-left: 25px;
  list-style-image: url('../assets/price-tick.svg');
  vertical-align: middle;

  li {
    font-size: 1.8rem;
    line-height: 4rem;
    vertical-align: middle;
  }
}

.error {
  input,
  textarea {
    border-color: $error-red;
  }
}

.searchContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  @include medium {
    margin-right: 16px;
  }

  .searchInputWrapper {
    display: flex;
    position: relative;
    justify-content: flex-start;
    flex-direction: row;
    border-radius: $border-radius-small;
    border: 1px solid $grey-300;
    height: $button-height;
    width: 100%;

    @include medium {
      width: auto;
    }

    .selectBoxWrapper {
      position: relative;
      height: $button-height;
      max-width: 100px;
      text-overflow: ellipsis;
      width: 100%;

      select {
        text-overflow: ellipsis;
        background: $grey-100;
        border-width: 0;
        box-sizing: border-box;
        border-radius: $border-radius-small 0 0 $border-radius-small;
        color: $black;
        font-weight: 500;
        margin: 0;
        height: $button-height - 2px;
        font-size: $button-font-size;
        line-height: $button-line-height;
        padding: 0 24px 0 12px;
        appearance: none;
        border-right: 1px solid $grey-300;
      }
      &:before {
        position: absolute;
        content: ' ';
        display: block;
        top: 16px;
        right: 12px;
        width: 10px;
        height: 10px;
        background: transparent url(../assets/drop-down-arrow.svg) no-repeat 50%
          50%;
        transform: rotate(90deg);
        pointer-events: none;
      }
    }

    .searchInput {
      width: auto;
      margin: 0;

      input {
        width: 100%;
        border-width: 0;
        margin: 1px;
        height: $button-height - 4px;
        line-height: $button-height - 4px;
      }

      &.searchInputOnly {
        cursor: pointer;
        padding-left: 35px;
        background: url('../assets/search.svg') no-repeat 10px 50%;
      }
    }
  }

  .searchSummary {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;

    @include medium {
      width: auto;
    }

    p,
    span {
      display: block;
      align-self: center;
      margin: 0;
      font-size: $button-font-size;
      color: $grey-600;
      white-space: nowrap;
    }
  }
}

.label {
  font-size: $small-font-size;
  color: $grey-800;
  font-weight: 400;
  line-height: 2.1rem;
  position: relative;

  a {
    display: inline;
    font-size: $small-font-size;
    font-weight: 400;
  }

  .labelSpan {
    display: inline-block;
    position: relative;
    height: 100%;
    overflow: clip;
    text-overflow: ellipsis;
    transition: transform 0.6s ease-in-out;

    &:hover {
      overflow: initial;
      text-overflow: initial;
    }
  }
}

.heading {
  color: $black;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
  font-size: 1.6rem;
}

.modalHeading {
  composes: heading;
  color: $grey-700;
  font-weight: 600;
  margin-bottom: 8px;
}

.smallLabel {
  color: $grey-600;
  font-size: $small-font-size;
  margin-bottom: 8px;
  margin-left: 4px;
}

.clickEditInput {
  position: relative;
  width: 100%;

  &.clickEditInputTextArea {
    &.clickEditInputActive {
      margin-bottom: 16px;
      button:last-child {
        margin-right: 0;
      }
    }
  }

  &.disabled label:hover {
    background: none;
  }

  @include medium {
    label {
      display: block;
      cursor: pointer;

      &:hover::after {
        content: url('../assets/edit.svg');
        display: block;
        position: absolute;
        width: 30px;
        right: 0;
        top: 0;
        background: white;
      }
    }
  }

  label {
    opacity: 1;
    display: block;
    z-index: 1;
    width: 100%;
    line-height: 42px;
    height: 42px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 40px;
    font-weight: normal;
  }

  input {
    width: 100%;
    padding-right: 30px;
  }

  .markDown {
    &:hover {
      cursor: pointer;
      &:after {
        position: absolute;
        right: 4px;
        top: 4px;
        display: block;
        background-color: $grey-300;
        padding: 8px 16px;
        border: 1px solid $grey-300;
        content: 'Edit';
        border-radius: $border-radius-small;
      }
    }
  }
}

.clickEditInputLabel {
  &.clickEditInputEmpty {
    border: 1px solid $grey-300;
    border-radius: $border-radius-small;
    height: 40px;
    padding-left: 4px;
    line-height: 38px;
    color: $grey-500;
  }
}

.showBorder {
  label {
    border: 1px solid $grey-300;
    border-radius: $border-radius-small;
    height: 40px;
    padding-left: 14px;
  }
}
.clickEditViewOnlyInput {
  composes: clickEditInput;
  border-width: 0 !important;
  padding: 0 16px 1px 0 !important;
  &.viewOnlyShowIcon {
    padding: 0 !important;
  }

  &:focus {
    outline: none;
  }

  &.viewOnlyShowIcon {
    label {
      &:hover {
        background: url('../assets/edit-grey.svg') no-repeat 100% 50%;
        background-size: 30px;
      }
    }
  }

  @include medium {
    label {
      &:focus {
        outline: none;
      }
      &:hover {
        background-image: none;
      }
    }
  }
  .markDown {
    border-width: 0;
    padding: 0 16px 1px 0;
    &:hover {
      cursor: auto;

      &:after {
        display: none;
      }
    }
    &:focus {
      outline: none;
    }
  }
}

.inputButton {
  position: absolute;
  line-height: 34px;
  height: 34px;
  top: 4px;
  right: 12px;
  z-index: 2;
  padding: 0;

  font-weight: 600;
}

.cancelButton {
  position: absolute;
  line-height: 34px;
  height: 34px;
  top: 3px;
  right: -76px;
  margin: 0;
  z-index: 2;
}

.markDown {
  margin-bottom: 16px;
  position: relative;
  border-radius: $border-radius-small;
  border: 1px solid $grey-300;
  padding: 8px 16px 0;
  * {
    color: $grey-700;
  }

  h1 {
    font-size: $medium-font-size;
  }
  h2 {
    font-size: $base-font-size;
  }
  h3 {
    font-size: $small-font-size;
  }
  h4 {
    font-size: $x-small-font-size;
  }
  h1,
  h2,
  h3,
  h4 {
    margin: 0 0 16px;
    line-height: 1em;
  }
  p {
    margin: 0 0 8px;
    font-size: $small-font-size;
  }
  li {
    font-size: $small-font-size;
    margin: 0 0 4px;
  }
}
.buttonRow {
  margin-top: 8px;
}
.clickEditInputContainer {
  margin: 0;

  &.multilineInput > textarea {
    min-height: 150px;
  }
}

.clickEditInputActive {
  label {
    display: none;
  }
  padding-top: 1px;
  padding-bottom: 2px;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.required {
  color: $error-red;
  font-weight: 600;
  display: inline-block;
  padding: 0 0 0 3px;
}
.hide {
  label {
    color: $grey-500;
    text-decoration: line-through;
  }
}

.errorMessage {
  span[class*='tooltip_dot'] {
    display: none;
  }
  div[class*='tooltip_box'] {
    transform: translate(-85%, 100%);
  }
  p {
    color: $error-red !important;
  }
}
