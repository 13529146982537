@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.wrapper {
  background: transparent;
  position: relative;
}

.addRow {
  margin: 0;
  width: auto;
  flex: 1 2;
  min-width: 150px;
  padding: 0 0 0 6px;
  font-size: $button-font-size;
  line-height: $button-line-height;
  display: block;
  background-color: $white;
  border: 0;
  width: 100%;
  border-radius: $border-radius-small;
}

.tagWrapper {
  background: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  flex-wrap: wrap;
  position: relative;
  border-radius: $border-radius-small;
  width: 100%;
  padding: 0;
  border: 1px solid $grey-400;

  &.noBorder {
    border: 0;
    padding: 0;
  }
}

.tag {
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;

  > span {
    min-width: 150px;
    font-size: 1.2rem;
  }

  .button {
    border-radius: 16px;
    display: flex;
    align-items: center;
    width: auto;
    min-height: 26px;
    height: auto;
    padding: 4px 28px 4px 16px;
    margin: 5px 6px 4px 6px;
    color: $primary-color;
    background: #f4d3e0;
    background-size: 16px 16px;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
    text-align: left;
    line-height: 1.6em;

    &:hover {
      text-decoration: none;
      background: rgba($primary-color, 0.1);
      border: 1px solid rgba($primary-color, 0.6);
    }

    &::after {
      content: ' ';
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      line-height: 1em;
      vertical-align: top;
      background: transparent url(../assets/icon-close-red.svg) no-repeat 50%
        50%;
      background-size: 10px;
      min-width: 18px;
      opacity: 0.6;
    }

    .buttonInner {
      font-weight: 500;
    }
  }

  // Different tag styles
  &.blue {
    .button {
      background-color: #e4f0f7;
      border-color: #3f85aa;
      color: #3f85aa;
      font-size: $x-small-font-size;
      padding: 7px 16px 8px 8px;
      height: 32px;
      line-height: 32px;

      &:after {
        background: none;
        display: none;
      }
    }
  }

  &.grey {
    .button {
      background-color: $grey-300;
      border-color: $grey-400;
      color: $grey-700;
      font-size: $x-small-font-size;
      padding: 7px 16px 8px 16px;
      height: 32px;
      line-height: 32px;
      outline: none;

      &:after {
        background: none;
        display: none;
      }
    }
  }

  &.compact {
    .button {
      height: 24px;
      line-height: 24px;
    }
  }
}

.formType {
  max-width: 430px;
  display: block;
  margin-bottom: 24px;

  label {
    display: block;
    font-size: 1.6rem;
    color: $grey-600;
    margin-bottom: 6px;
  }

  .tagWrapper {
    border: 0;
    padding: 0;
  }

  .tag {
    width: 100%;

    .button {
      text-align: left;
      width: 100%;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 8px;
      color: $grey-900;
      background: $grey-200;
      border: 1px solid rgba($grey-900, 0.6);
      height: 32px;

      span {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  input {
    height: 40px;
    padding: 0 12px;
    border-radius: $border-radius-small;
    border: 1px solid $grey-400;
  }
}

.tagExistsMessage {
  margin: 4px 0;
  line-height: $button-line-height;
}

.box {
  height: auto;
  width: auto;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  // bottom: 0;
  transform: translateY(6px);
  pointer-events: none;
  background-color: $white;
  border: 1px solid $white;
  box-shadow: 0 0 0 1px $grey-400;
  border-radius: $border-radius-small;
  max-height: 200px;
  overflow: auto;
  z-index: 5;

  button {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }
}
.expanded {
  composes: box;
  opacity: 1;
  pointer-events: auto;
}

.tagExists {
  line-height: 34px;
  border: 1px solid $quaternary-color;
  border-radius: 34px;
  padding: 0 8px 0 0;
  background: rgba($quaternary-color, 0.3);
  color: $grey-800;
  font-weight: 600;
  margin: 4px 0 4px 4px;
}

.tagMessage {
  border-radius: 18px;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4px;
  > button {
    margin: 0;
    border: 1px solid $primary-color !important;
  }

  > span {
    margin: 0 4px 0 0;
    min-width: 50px;
  }
}
.invalid,
.validating {
  > button {
    margin: 3px;
    color: $grey-600 !important;
    background-color: $grey-400 !important;
    border: 1px solid $grey-600 !important;
    &:after {
      background-image: url(../assets/icon-close-black.svg) !important;
    }
    &:hover {
      &:after {
        background-image: url(../assets/icon-close-black.svg) !important;
      }
    }

    &:hover {
      background-size: 10px !important;
    }
  }
}

.valid {
  > button {
    margin: 3px;
    color: $green !important;
    background-color: rgba($green, 0.2) !important;
    border: 1px solid $green;

    &:after {
      background: transparent url(../assets/icon-close-green.svg) no-repeat 50%
        50% !important;
      background-size: 10px !important;
    }

    &:hover {
      color: $green !important;
      background-color: rgba($green, 0.1) !important;
      border-color: rgba($green, 0.6) !important;
    }
  }
}

.error {
  & > div {
    border-color: $error-red;
  }
}

.possibleValue {
  display: block;
  width: 100%;
  border: none;
  font-weight: 600;

  > div {
    justify-content: flex-start;
  }
}
