@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.trackDropdownRequestsContainer {
  margin-bottom: 16px;
}

.headerPanelInner {
  width: 100%;

  @include medium {
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    align-items: center;
  }

  h3 {
    margin: 0;
    position: relative;
    padding-right: 30px;
    width: fit-content;

    span[class*='bulletCounter'] {
      top: 0;
      background-color: $primary-color;
    }
  }

  .requestCycle {
    display: flex;
    align-items: center;
    gap: 4px;

    p {
      margin: 0;
      color: $blue-grey-600;
      font-weight: 600;
    }

    .cycleArrow {
      width: 20px;
      padding: 0;
      background-color: transparent !important;

      svg {
        height: 16px;
      }
    }
  }
}

.requestBox {
  margin-bottom: 24px;

  h3 {
    font-size: $small-font-size;
    display: flex;
    font-weight: 600;
    margin: 0 0 8px 0;

    span:not([class*='tooltip']) {
      font-weight: normal;
      text-align: left;
    }
  }

  p {
    text-align: left;
  }

  .bold {
    font-weight: 700;
  }
}

div[class*='carousel-slider'],
div[class*='slider-wrapper'] {
  overflow-x: clip !important;
  overflow-y: visible !important;
  z-index: 2;
}

ul[class*='slider'] {
  height: 100%;
}

/* ******************************** */
/* ParamRequestTable */
/* ******************************** */

.paramRequests {
  text-align: left;

  &:last-child {
    .actionSlot,
    .optionNameSlot > span,
    .optionValueSlot > span {
      margin-bottom: 0;
    }
  }

  .title {
    font-size: $small-font-size;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 8px;
  }
}

.optionLabel > span {
  margin-bottom: 8px;
}

.optionNameSlot {
  @include medium {
    margin-bottom: 0;
    padding-right: 16px;
    width: 25%;
  }
}

.optionNameSlot {
  span[class*='input_error'] {
    margin-bottom: 0;
  }
}

.optionValueSlot {
  @include medium {
    width: 25%;
    padding-right: 16px;
  }

  span[class*='input_error'] {
    margin-bottom: 0;
  }
}

.optionAccountsSlot {
  @include medium {
    width: 25%;
  }

  .multiSelect {
    max-width: 100%;

    div[class*='multiSelectExpanded'] {
      z-index: 100;
    }
  }
}

.actionSlot {
  margin-bottom: 16px;

  @include medium {
    flex: 0 1;
    min-width: 200px;
  }

  h3 {
    padding-left: 16px;
  }
}

.buttonRow {
  justify-content: flex-end;
  gap: 16px;

  @include medium {
    justify-content: flex-start;
    gap: 0;
  }

  button {
    margin: 8px 0 0 0;

    @include medium {
      margin: 0 0 0 16px;
    }
  }
}

.footNote {
  color: $grey-600;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 8px;
  display: block;
  width: 100%;

  li {
    font-size: 1.3rem;
  }

  button {
    font-size: 1.3rem;

    img {
      height: 10px !important;
      margin-left: 5px !important;
    }
  }
}

.footNoteError {
  composes: footNote;
  color: $error-red;
}

.divider {
  border-top: 1px solid $grey-400;
  width: 100%;
  margin: 16px 0;
}

.manageButton {
  font-weight: 600;
}

.modalError {
  margin: 0;
  font-size: $small-font-size;
  color: $error-red;
}
