@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.tableContainer {
  border: 1px solid $grey-300;
  border-radius: $border-radius-medium;
  overflow: auto;
  margin-bottom: 20px;

  .table {
    th,
    th button {
      font-size: $small-font-size;
      font-weight: 500;
    }
  }
}

.loadingText {
  height: 21px;
  margin: 8px 0;
}

.landingPage {
  min-width: 400px !important;

  span {
    white-space: normal;
  }
}

.sortButton {
  color: $grey-600;

  .orderArrow {
    position: relative;
    top: initial;
    transform: initial;

    &[class*='ascOrder'] {
      transform: rotate(180deg);
    }
  }
}

.metricColumn,
.paramColumn {
  max-width: 300px;

  span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  &:hover {
    span {
      overflow: auto;
      text-overflow: initial;
    }
  }
}

.noData {
  color: $grey-500;
}

.specialTag {
  color: $grey-600;
}
