@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.headerPanel {
  position: relative;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;

  @include medium {
    flex-wrap: nowrap;
  }
}

.panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;

  &:nth-child(2) {
    flex-direction: row;
    justify-content: space-between;

    @include medium {
      flex-direction: column;
      padding-top: 0;
    }
  }

  @include medium {
    align-items: center;
    width: auto;
  }

  > div {
    justify-content: flex-start;
    @include medium {
      justify-content: flex-start;
    }
  }
}
