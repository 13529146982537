@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.noPadding {
  padding-top: 0;
}

.linkPreview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  button {
    margin: 0 0 0 16px;
    box-shadow: none;
  }
}

.lastCreatedLabel {
  height: 40px;
}

.copyAllButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-left: auto;

  .divider {
    border-left: 1px solid $grey-300;
    height: 30px;
  }
}

.previewTableContainer {
  width: 100%;

  .shortLinkColumn {
    white-space: initial;
    word-break: break-all;

    p {
      width: 200px;
    }
  }

  .fullLinkColumn {
    min-width: 425px;
    word-break: break-all;
    white-space: normal !important;
  }

  tr:nth-child(odd) .cellActions {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      $grey-100 10%,
      $grey-100 100%
    );
  }

  td {
    position: relative;

    &:hover {
      .cellActions {
        display: flex;
      }
    }

    .cellActions {
      display: none;
      position: absolute;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      width: 50%;
      height: 100%;
      top: 0;
      right: 16px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        $white 10%,
        $white 100%
      );
    }
  }
}

.lastCreatedLinksTableRow {
  overflow-x: scroll;
}
