@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.accordionContainer {
  margin: 8px 0;
  padding: 0;
  box-sizing: border-box;
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: $border-radius-small;
}

.accordionItemContainer {
  border-top: 1px solid $grey-300;
  transition: 0.5s all;

  &:first-child {
    border-top-width: 0;
  }

  &.expanded,
  &:hover {
    > div > .accordionItemHeader {
      background-color: $pink;

      .accordionCheckbox {
        background-color: $pink;
      }
    }
  }

  .accordionItemHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    position: relative;
    padding: 16px;

    &.clickable {
      cursor: pointer;
    }

    .accordionCheckbox {
      position: relative;
      width: 26px;
      height: 26px;
      padding: 4px;
      background-color: $grey-200;
      border-radius: 50%;

      img {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .emoji {
      display: block;
      width: 42px;
      text-align: center;

      > span {
        line-height: initial;
        font-size: 30px;
      }
    }

    .accordionItemTitle {
      h2 {
        font-weight: 500;
        line-height: initial;
        margin: 0;
      }

      .subtitle {
        font-size: $x-small-font-size;
        color: $grey-600;
        line-height: initial;
      }
    }

    .rightContent {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
    }

    .arrow {
      transition: 0.5s all;
      position: absolute;
      padding: 0;
      right: 16px;
      top: 50%;
      height: 16px;
      width: 16px;
      transform: translateY(-50%) rotate(-90deg);
      fill: $primary-color;

      &.arrowClosed {
        fill: $primary-color;
        transform: translateY(-50%) rotate(90deg);
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.accordionItemInner {
  height: auto;
  animation: expand 0.25s linear;

  .expandedContentContainer p:last-child {
    margin-bottom: 0;
  }

  .progressionButton {
    margin-top: 16px;
  }
}

@keyframes expand {
  0% {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  50% {
    padding-top: 16px;
    opacity: 0;
    padding-bottom: 16px;
  }

  75% {
    opacity: 0;
  }

  100% {
    max-height: 250px;
    opacity: 1;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
