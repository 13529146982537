@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    width: 100%;
  }
}

.addNewField {
  background-color: $grey-300;
  align-items: stretch !important;

  &:nth-child(odd) {
    background-color: $grey-300;
  }

  label[class^='input_container'] {
    margin-top: 0;
    margin-bottom: 0;
  }

  button {
    margin: auto;
  }
}

.checkboxPanel {
  border-top: 1px solid $grey-400;
  padding-top: 16px;
  // TODO: this should only apply when there is Adobe settings panel...
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 10px;
  }

  @include medium {
    padding: 20px 0 20px 0;

    &:last-child {
      padding-bottom: 20px;
    }
  }
}

.checkboxPanelCollapsed {
  composes: checkboxPanel;

  @include medium {
    padding: 20px 20px 4px;
  }
}

.fieldSlot {
  padding: 8px 0 0;
  margin: 0;
}

.fieldCell {
  align-items: flex-start !important;
}

.icon {
  position: relative;

  & > span:not([class*='tooltip']) {
    width: auto;
  }

  &:after {
    content: ' ';
    display: inline-block;
    width: 85px;
    height: 28px;
    position: absolute;
    right: 0;

    @include medium {
      right: auto;
      left: 34px;
    }
  }
}

.iconDropDown {
  composes: icon;

  & > span > span {
    padding-left: 100px;
  }

  &:after {
    background: url(../assets/icon-drop-down.svg) no-repeat 0 0;
  }
}

.iconInput {
  composes: icon;

  & > span > span {
    padding-left: 100px;
  }

  &:after {
    background: url(../assets/icon-input.svg) no-repeat 0 0;
  }
}

.iconUKDate {
  composes: icon;

  & > span > span {
    padding-left: 100px;
  }

  &:after {
    background: url(../assets/icon-date-uk.svg) no-repeat 0 0;
  }
}

.hasError {
  margin-top: 0;
  margin-bottom: 0;

  & ~ .footNoteError {
    margin-bottom: 8px;
  }
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > label {
    margin-bottom: 0;
  }

  .fixedInput {
    width: auto;
    flex: initial;
    margin: 0;
  }

  .iconUKDate,
  .iconInput {
    width: auto;
    margin: 0;
    flex: none;
  }

  .selectBox {
    margin-left: 8px;
  }
}

.fixedValueField {
  margin: 0 8px 16px;
  max-width: 210px;
}
