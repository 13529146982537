@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.connectorBoxesContainer {
  display: grid;
  grid-template-columns: 100%;
  column-gap: 32px;
  row-gap: 32px;

  @include medium {
    grid-template-columns: 1fr 1fr;
  }

  @include large {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.headerPanel {
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 12px;

  @include medium {
    flex-direction: row;
    align-items: center;
  }

  .progressIndicator {
    @include medium {
      margin-left: auto;
    }

    p,
    .progressBar {
      margin-bottom: 0;
    }
  }
}
