@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.errorContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .noDataImg {
    display: block;
    margin: 8px auto;
    max-width: 175px;
    max-height: 175px;
  }

  p {
    color: $grey-500;
    line-height: 2.4rem;
    font-weight: 500;
    font-size: $base-font-size;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}
