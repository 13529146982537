@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.outerBox {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.innerBox {
  position: relative;
}

.formIntro {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.clearAllButton {
  display: block;
  margin-left: auto;
  min-width: 90px;
  align-self: baseline;

  &:hover {
    text-decoration: none !important;
  }

  /** Hack to make the button fit on the form when landing page field is the top row */
  &.floatingClearAllButton {
    position: absolute;
    right: 20px;
    top: 8px;
  }
}

.trackCreateForm {
  // Removes content shift on hover
  div[class*='formHeading'] {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
  }

  div[class*='formRow'],
  div[class*='formHeading'] {
    span[class*='tooltipIcon'] {
      display: none;
    }

    &:hover {
      span[class*='tooltipIcon'] {
        display: block;
      }
    }
  }
}

.noPaddingTop {
  padding-top: 0;
}

.destinationLabel {
  color: $primary-color;
}

.appRadio {
  display: inline-flex;
  gap: 4px;

  .betaTooltip {
    border-bottom-width: 0;
  }
}

.linkToRadioGroup {
  justify-content: space-between;

  // .radioButton {
  //   min-width: 75px;
  // }
}

.warning {
  color: $grey-600;

  button {
    font-size: 1.3rem;
  }
}

.validCombinationsNote {
  font-size: $small-font-size;
  color: $green;
  margin: 9px 0 0;
}

.bulkCsvButton {
  font-size: inherit;
}

.highlight {
  background-color: $report-color;
  border-radius: $border-radius-small;
}

.emailPreviewHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-bottom: 16px;

  .copyButton {
    margin-left: auto;
  }
}

.previewCode {
  width: 100%;
  border: 1px solid $grey-400;
  border-radius: $border-radius-small;
  padding: 8px;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $grey-300;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey-500;
    border-radius: 4px;
  }

  code {
    font-family: Consolas, 'courier new';
    font-size: $x-small-font-size;
  }
}

.betaPill {
  top: unset;
  right: unset;
}

.sendWeeklyReportField {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  .weeklyReportCheckbox {
    width: fit-content;
    margin: 0;
    flex: 0 0;
  }

  .betaPill {
    width: fit-content;
  }
}
