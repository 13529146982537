@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.modalTitle {
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    height: 30px;
  }
}

.formRow {
  padding-top: 16px;

  > div[class*='labelSlot'] {
    width: auto;
  }

  > div[class*='fieldSlot'] {
    flex-grow: 1;
  }

  &:first-of-type {
    border-top: 1px solid $grey-300;
    padding-top: 32px;
  }

  .checkboxItem {
    align-items: center;

    .ruleDropdown {
      flex-grow: 1;
      margin-left: auto;
      padding-left: 16px;
      max-width: 350px;
    }
  }
}

.supportLink {
  font-size: $base-font-size !important;
}
