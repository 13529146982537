@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.parametersHeaderRow {
  margin-top: 24px;
  margin-bottom: 8px;
  align-items: flex-start;

  @include medium-wide {
    align-items: center;
  }

  .parameterHeading {
    display: none;
    justify-content: flex-start;
    align-items: center;
    margin-right: 32px;

    @include medium-wide {
      display: flex;
    }

    &.column1 {
      margin-right: 48px;
      width: 200px;

      @include large {
        width: 350px;
      }
    }

    &.secondaryColumnHeader {
      min-width: 175px;

      @include large {
        min-width: 225px;
      }
    }

    &:last-child {
      margin-left: auto;
      margin-right: 4px;
    }
  }
}

.lockedButton {
  color: $grey-600;
}

.lockedTooltip {
  font-style: italic;
  color: $grey-600;
}

.addNew {
  @include medium-wide {
    margin-left: 8px;
  }

  @include large {
    margin-left: 16px;
  }

  .addNewDropdown {
    min-width: 250px;
  }
}

.hasUplifterID {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.parametersAccordionHeader {
  div[class*='accordionItemTitle'] {
    max-width: 200px;

    @include medium-wide {
      margin-bottom: 0;
      width: 200px;
    }

    @include large {
      min-width: 330px;
    }
  }

  label[class*='clickEditInputLabel']::after {
    background-color: $pink;
  }
}

.editableParameterName {
  display: flex;
  align-items: center;
  gap: 8px;

  .parameterName {
    width: auto;
    max-width: 100%;

    > label {
      color: $text-color;
      width: fit-content;
      font-weight: 500;
      font-size: $h2-font-size;
    }
  }
}

.paramHeaderPrefix {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  width: fit-content;
  position: relative;

  @include medium-wide {
    margin-left: unset;
    margin-bottom: unset;
    min-width: 175px;
  }

  @include large {
    min-width: 225px;
  }
}

.paramHeaderInputType {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.paramHeaderFieldLabel {
  font-weight: 600;
  color: $grey-600;
  margin-right: 8px;

  @include medium-wide {
    display: none;
  }
}

.metaParameter {
  margin: 0;
  font-style: italic;
  color: $grey-600;
}

.parameterInput {
  margin-bottom: 0;
  width: auto;
  margin: 0;
  min-width: 145px;

  label,
  input,
  select {
    color: $grey-600;
  }

  label[class*='clickEditInputEmpty'] {
    font-style: italic;
  }

  div[class*='multiSelectContainer'],
  div[class*='select-box-typeahead_container'] {
    margin: 0;
    width: fit-content;
    min-width: 150px;

    @include site-width {
      min-width: 125px;
    }
  }

  &.fullWidth {
    @include site-width {
      flex: 1 0;
    }
  }

  &.inlineDropdown {
    min-width: 200px !important;
  }

  &.fixedValue {
    max-width: 175px;
  }
}

.parametersSettings {
  padding-right: 72px;

  .separator {
    flex: 0 0;
    height: 25px;
    border-left: 1px solid $grey-400;
    display: none;

    @include site-width {
      display: block;
    }
  }

  .helpTextContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1 0;
    position: relative;
    margin-bottom: 16px;
  }

  .extraDetailsRow {
    gap: 24px;
    margin-bottom: 16px;

    &:not(:last-of-type) {
      padding-bottom: 16px;
      border-bottom: 1px solid $grey-400;

      @include medium-wide {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    @include medium-wide {
      align-items: center !important;
    }

    span[class*='inputWrapper'] {
      align-items: center;
    }

    .checkboxField {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      label {
        margin: 0;
        flex: 0 0;

        span[class*='labelText'] {
          font-weight: 600;
        }
      }
    }
  }

  .characterLimitField {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    div[class*='tooltipTextContainer'] {
      flex-grow: 1;
      font-size: $small-font-size;
      font-weight: 600;
    }
  }

  .parameterRuleLabel {
    min-width: 120px;
    font-weight: 600;

    &.inlineLabel {
      min-width: 0;
    }
  }

  .copyFromLimiters {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }

  .clickEditField {
    flex-shrink: 1;
    border: 1px solid $grey-300;
    border-radius: $border-radius-small;
    margin-left: 8px;
    padding-left: 8px;
    width: auto;

    > label {
      height: 100%;
      line-height: 34px;
      color: $grey-600;
      border: 0;
    }

    input {
      border: 0;
      max-width: 100px;
    }
  }

  .emailDefaultField {
    width: 175px;
    margin-bottom: 0;
  }

  .deleteParameterButton {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.lockIcon {
  width: 24px;
  height: 24px;
  max-width: 80%;
  max-height: 90%;
  margin: 0;
}

.dragAndDropParent {
  position: relative;
  margin-bottom: 32px;

  .dragReorderCopy {
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%) rotate(-180deg);
    height: 100%;
    display: none;
    cursor: default;

    @include site-width {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    > span {
      font-size: $small-font-size;
      color: $blue-grey-600;
      writing-mode: vertical-rl;
      text-align: center;
    }

    .copyBurger {
      transform: rotate(90deg);

      > svg {
        max-width: 15px;
      }
    }
  }
}

.addParamError {
  color: $error-red;
}

.noParamsButtonContainer {
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: $border-radius-small;
  padding: 16px;
  position: relative;
}

// Replicate accordion styles
.uplifterIDParamContainer {
  background-color: $white;
  border: 1px solid $grey-300;
  border-bottom-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;
  border-top-width: 0;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  position: relative;

  &:hover {
    background-color: $pink;
  }

  .uplifterIDTitle {
    font-weight: 500;
    line-height: initial;
    margin: 0;

    max-width: 200px;

    @include medium-wide {
      margin-bottom: 0;
      width: 200px;
    }

    @include large {
      min-width: 330px;
    }
  }
}
