@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.outerBox {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.intro {
  font-weight: 600;
}

.innerBoxContent {
  img {
    max-width: 100%;
  }
}

.optional {
  font-style: italic;
}

.cloneButton {
  margin-top: 8px;
}
