@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.intro {
  margin-bottom: 20px;
}

.paper {
  padding: 16px;
  background-color: $white;
  border: 1px solid $grey-300;
  border-radius: 0 $border-radius-small $border-radius-small
    $border-radius-small;
}

.headerButton {
  &:hover {
    cursor: pointer;
  }
}

.metricDataBannerWrapper {
  padding: 16px 0;
}

.metricDataBanner {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;

  @include medium-wide {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.graphWrapper {
  position: relative;
  padding: 16px 0;
  width: fit-content;

  h1 {
    font-size: $medium-font-size;
    margin: 0;

    @include medium-wide {
      display: flex;
    }

    &.graphTitle {
      .titleSelectWrapper {
        > span {
          text-transform: lowercase;
          display: inline-block;
          padding-left: 6px;
        }
      }

      .successMetricSelector {
        margin-left: 6px;
      }
    }
  }

  .successMetricSelector {
    width: 100%;
    margin: 8px 0 12px 0;

    @include medium {
      > input {
        transform: translateY(-4px);
      }
    }

    @include medium-wide {
      width: auto;
      margin: 0 8px 0 12px;
    }
  }
}

.tableWrapper {
  width: 100%;
  padding-top: 8px;
}

.tableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.tableContainer {
  border: 1px solid $grey-300;
  border-radius: $border-radius-medium;
  overflow: auto;
  margin-bottom: 20px;
}

.controlsSelector {
  div[class*='multiValue'] {
    width: max-content;
    min-width: 100px;
  }

  div[class*='_menu_'] {
    min-width: 350px;
  }

  @include medium {
    margin: 0 8px;
  }
}

.reportControlCopy {
  font-size: $small-font-size;
  line-height: 2.4rem;
  font-weight: 400;
  display: inline-flex;
  text-transform: capitalize;
  line-height: $button-height;

  @include medium {
    text-transform: none;
    font-size: $base-font-size;
  }
}

.tableParametersSelector {
  width: 220px;
  margin: 16px 16px 0 auto;

  @include medium {
    margin-top: 0;
    margin-right: 0;
  }

  div[class*='_menu_'] {
    right: 0;
  }
}
