@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.limitsProgress {
  .progressIndicator {
    cursor: pointer;
    min-width: 175px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    .limitTooltip {
      border-bottom: none;
      width: 100%;
    }

    p {
      margin-bottom: 0;
    }

    .progressBar {
      margin-bottom: 0;
    }
  }
}

.annualToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: relative;
  margin-bottom: 16px;
  max-width: $medium-screen-min-width;

  p {
    margin-bottom: 0;

    &:first-child {
      text-align: right;
    }
  }
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  width: $medium-screen-min-width;
  max-width: 100%;
  // min-height: 50vh;
  border-width: 0;
}

.tierContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 275px;
  min-height: inherit;
  border: 1px solid $grey-400;
  border-radius: 0;

  &.highlight {
    border: solid $primary-color;
    border-width: 3px !important;
    border-radius: 0;
    background-color: $pink;
  }

  &:first-child {
    border-top-left-radius: $border-radius-small;
    border-bottom-left-radius: $border-radius-small;
  }

  &:last-child {
    border-top-right-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;
  }

  &:not(:last-child) {
    border-right-width: 0;
  }
}

.currentPlan {
  text-align: center;

  span {
    background-color: $primary-color;
    right: 0;
  }
}

.tierImage {
  display: flex;
  align-items: center;
  max-height: 110px;

  img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 110px;
    height: 100%;
  }
}

.tierTitle {
  margin: 0;
  font-size: 2.6rem;

  @include medium {
    font-size: 2.8rem;
  }
}

.tagline {
  p {
    margin: 0;
  }
}

.price {
  p {
    margin: 0;
  }

  strong {
    font-size: $large-font-size;
  }
}

.tierDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .currentBill {
    p {
      margin: 0;
    }

    .textButton {
      line-height: 2.4rem;
    }
  }
}

.tierActions {
  margin-top: auto;
}

.featureList {
  padding-inline-start: 20px;

  li {
    margin-bottom: 4px;
    padding-left: 10px;

    &::marker {
      content: url(../assets/tick-green.svg);
      position: absolute;
      margin-right: 4px;
    }
  }
}

.upgradeButton {
  width: 100%;
  margin-bottom: 8px;

  &:hover svg {
    stroke: $primary-color;
  }
}

.savePill {
  display: inline-block;
  position: initial;
  top: unset;
  right: unset;
  margin-left: 4px;
}
