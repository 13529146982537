@import '../core/variables.scss';

.loginButton {
  width: 100%;
  font-weight: 600;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.oktaLoginMulti {
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-bottom: 12px;

  > div {
    width: 100%;
  }

  div[class*='dropdownMainButton'] {
    padding-left: 77px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      margin-left: -22px;
      width: 49px;
      height: 30px;
      background-size: 50px !important;
      background: url(../assets/logos/icon-okta.png);
      background-position: center center;
      background-repeat: no-repeat;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
