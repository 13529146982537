@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.buttonRow {
  display: flex;
  min-height: 40px;
  justify-content: center;
  max-width: 100%;
  flex-wrap: wrap;
  width: 100%;

  @include medium {
    justify-content: flex-end;
  }

  & > span,
  & > div,
  & > a {
    margin-bottom: 0;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  & > button {
    margin-right: 16px;
    width: auto;
    min-width: 75px;
    padding: 0 16px;
    margin-bottom: 0;
    text-align: center;
    line-height: 100%;
    height: 40px;
  }

  @include medium {
  }

  &.centerAlign {
    align-items: center;
  }
}
