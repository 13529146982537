@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.areaItemSlider {
  background-color: rgba($grey-200, 0.8);
  // border: 1px solid rgba($grey-600, 0.6);
  border-radius: $border-radius-medium;
  color: $grey-800;
  font-size: $small-font-size;
  font-weight: normal;
  padding: 0 12px 0 30px;
  outline: none;
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  max-width: 340px;
  // transform: translateX(-50%);
  // position: absolute;
  // top: 0;
  // left: 50%;
  z-index: 2;
  & > div > span {
    margin-left: 8px;
  }
}

.labelWrapper {
  text-align: left;
  // width: 50%;
  display: flex;

  label {
    white-space: nowrap;
    text-align: center;
    padding-right: 8px;
  }
}

.sliderWrapper {
  display: flex;
  // width: 50%;
  padding-top: 4px;

  input {
    width: 100%;
    margin: 6px 8px 0 8px;
    background: #d3d3d3;
    -webkit-appearance: none;
    appearance: none;
    height: 2px;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: $primary-color;
    }

    &::-moz-range-thumb {
      background-color: $primary-color;
    }
  }
  b {
    vertical-align: middle;
    text-align: center;
    padding: 0;
    // border: 1px solid $grey-400;
    // background-color: $white;
    // border-radius: 2px;
  }
}
