@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.addNewOptionRow {
  td {
    background-color: $grey-300;
  }
}

.newOptionInput {
  margin: 0;
}

.footNote {
  color: $grey-600;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 0;
  display: block;
  width: 100%;
}
