@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: $site-padding-mobile;

  @include medium {
    padding: $site-padding;
  }

  & + .container {
    padding-top: 0;
  }
}

.containerFullScreen {
  composes: container;
  padding: 0;

  @include medium {
    background: linear-gradient(
      90deg,
      $grey-100 0%,
      $grey-100 50%,
      $white 50%,
      $white 100%
    );
  }
}

.containerFlex {
  composes: container;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  * {
    display: block;
    flex: 1;
  }
}
