@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.rowsPerPageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @include medium {
    margin-top: 0;
    margin-left: auto;
  }

  .rowsPerPageSummary {
    font-size: 1.3rem;
    color: $grey-600;
    margin-right: 12px;
  }
}

.paginationRow {
  justify-content: space-between;
}

.paginationWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.paginationWrapperCenter {
  composes: paginationWrapper;
  justify-content: center;
}

.paginationMiddle {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: conter;

  button {
    background: $white;
    border: 1px solid $grey-300;
    border-left-width: 0;
    color: $black;
    padding: 0 8px;
    min-width: 30px;
    &:hover {
      color: $black;
    }
  }
  & div:first-child {
    button {
      border-left-width: 1px;
      border-right-width: 0;
    }
  }
}
.paginationMiddleMobile {
  border: 1px solid $grey-300;
  border-left-width: 0;

  span {
    font-size: 1.4rem;
    line-height: $button-height;
    margin-bottom: 0;
    padding: 0 4px;
  }

  @include medium {
    display: none;
  }
}

.paginationMiddlePages {
  display: none;
  flex-wrap: no-wrap;

  @include medium {
    display: flex;
  }
}

.paginationElement button {
  border-radius: 0;
  min-width: $button-height;
  height: 100%;

  &:focus {
    outline: none;
  }
  &:hover {
    border: 1px solid $grey-300;
    border-left-width: 0 !important;
    background-color: $grey-400 !important;
  }
}

.activePagination button {
  background-color: $grey-200 !important;
  color: $text-color;
}

.previousButton {
  background: #fff url(../assets/drop-down-arrow-left.svg) no-repeat 50% 50% !important;
  background-size: 6px !important;
  border-left-width: 1px !important;
  border-radius: $border-radius-small 0 0 $border-radius-small;
  margin-left: 8px;
  min-width: 40px !important;

  @include medium {
    border-right-width: 0;
  }

  &:hover {
    background-color: $grey-400 !important;
  }
}

.nextButton {
  background: #fff url(../assets/drop-down-arrow.svg) no-repeat 50% 50% !important;
  background-size: 6px !important;
  border-radius: 0 $border-radius-small $border-radius-small 0;
  border-left-width: 1px;
  margin-right: 8px;
  min-width: 40px !important;

  @include medium {
    border-left-width: 0;
  }

  &:hover {
    background-color: $grey-400 !important;
  }
}
