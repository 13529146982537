@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.tableContainer {
  margin-bottom: 8px;
}

.columnContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.subText {
  color: $grey-500;
}

.homepageIcon,
.analyticsLogo {
  display: block;
  width: 20px;
  max-width: 20px;
  height: 20px;
}

.loadingText {
  margin: 8px 0;
}

.deleteButton {
  margin-left: auto;
}

.addWorkspaceButton {
  margin-bottom: 8px;
}

.checkboxItem {
  margin: 0;

  span[class*='tooltipIcon'] {
    display: none;
  }

  &:hover {
    span[class*='tooltipIcon'] {
      display: block;
    }
  }
}
