@import '../core/variables.scss';

.wrapper {
  position: relative;
  // transform: translateY(38px);
  display: flex;
  justify-content: flex-end;
}

.tabsWrapper {
  border: 1px solid rgba($grey-600, 0.6);
  padding: 0;
  margin: 0 0 8px;
  display: inline-flex;
  width: auto;
  border-radius: $border-radius-small;
  position: relative;
  overflow: hidden;
  height: 38px;
  background: $white;

  > label > span {
    padding: 0 1px !important;
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;
    width: auto;

    &:checked + em + span {
      background-color: $grey-600;
      color: $white;
    }
  }

  input[type='radio'] + em {
    display: none !important;
  }

  label {
    font-size: 1.4rem;
    text-align: center;
    line-height: 38px;
    border-right: 1px solid rgba($grey-600, 0.2);
    margin: 0;

    span {
      padding: 0 12px;
      vertical-align: top;
    }

    &:last-child {
      border-right: 0;
      span {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    &:first-child {
      span {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:hover {
      background-color: rgba($grey-600, 0.2);
      cursor: pointer;
    }
  }
}

.dateWrapper {
  transform: translateY(42px);
  position: absolute;
  right: 0;
  z-index: 3;
  border: 1px solid rgba($grey-600, 0.6);
  border-radius: $border-radius-small;

  .calendar {
    max-width: 300px;
  }
}
