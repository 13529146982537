@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.anomalyIcon {
  vertical-align: middle;
}

.divider {
  display: none;
  width: 1px;
  height: 22px;
  background-color: $grey-400;
  margin-top: 9px;
  margin-right: 16px;

  @include medium {
    display: inline-block;
  }
}

.tabButton {
  margin: 0 12px 4px 0;
  width: auto;
  border-width: 2px;
  font-size: $base-font-size;
  font-weight: 600;
  transition: none;
  white-space: normal;
  line-height: 2.4rem;
  color: $grey-700;
  border-radius: 0;

  @include medium {
    padding-left: 8px;
    margin: 0 12px 8px 0;
    justify-content: space-between;
    align-items: center;
    display: flex;
    text-align: center;
    white-space: nowrap;
    line-height: 40px;
  }

  &:hover {
    text-decoration: none;
    color: $primary-color;
    border-bottom: 3px solid $primary-color;
  }

  &.tabButtonActive {
    border-bottom: 3px solid $primary-color;
  }
}
