@import '../core/variables.scss';

.quizContainer {
  max-width: 450px;
  min-width: 250px;
  background: $white;
  border-radius: 4px;

  .activeQuestionNo {
    font-size: 15px;
    font-weight: 600;
  }

  .answerButton {
    display: block;
    width: 250px;
    text-align: left;
    margin-bottom: 12px;

    &:nth-of-type(4) {
      margin-bottom: 16px;
    }

    > div {
      justify-content: flex-start;
    }

    &.selectedAnswer {
      border: 2px solid $primary-color;
    }
  }

  .totalQuestion {
    font-size: $large-font-size;
    color: $grey-500;
  }
}

.result p span {
  color: $primary-color;
  font-size: 20px;
}

.flexBtns {
  display: flex;
  gap: 1em;
}
