@import '../core/variables.scss';

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 600;

  * {
    pointer-events: auto;
  }

  .navHeader {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    max-width: 100vw;
    background-color: $navigation-grey;
    box-shadow: 1px 1px 5px $grey-400;

    .logoContainer {
      display: block;
      height: 100%;

      .logo {
        box-sizing: border-box;
        display: inline-block;
        max-height: 100%;
        height: 100%;
        padding: 10px;
        padding-left: 15px;
        width: auto;
      }

      .onboardingCounter {
        top: 10px;
        right: unset;
      }
    }
  }

  .navMenu {
    position: absolute;
    right: 100%;
    top: 60px;
    bottom: 0;
    width: 100vw;
    transition: 0.5s all;
    background-color: $grey-100;
    padding: 40px 20px 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.active {
      right: 0;
    }

    .navItem {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      padding: 20px 0;

      &[data-name='track'] {
        color: $track-color;
      }

      &[data-name='connect'] {
        color: $audit-color;
      }

      &[data-name='plan'] {
        color: $primary-color;
      }

      &[data-name='report'] {
        color: $report-color;
      }

      &[data-name='explain'] {
        color: $explain-color;
      }

      &[data-name='settings'] {
        margin-top: auto;
        color: $settings-color;
      }

      h3 {
        margin-left: 0;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 2rem;
      }

      .counter {
        position: absolute;
        top: 12px;
        right: auto;
        left: 120px;
      }

      svg:not(.arrow) {
        height: 40px;
        width: 45px;
      }
    }
  }
}

.arrow,
.subNavArrow {
  margin-left: auto;
  width: 10px;
  fill: #7a7a7a;
}

.subNav,
.secondarySubNav {
  position: absolute;
  right: auto;
  left: 100%;
  top: 60px;
  bottom: 0;
  width: 100vw;
  transition: 0.5s all;
  background-color: $grey-100;
  padding: 80px 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.active {
    right: auto;
    left: 0;
  }

  .arrow {
    position: absolute;
    top: 30px;
    left: 25px;
    transform: rotate(180deg);
  }

  .navItem {
    position: relative;
    padding: 24px 0;

    h3 {
      width: fit-content;
      margin-left: 0;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 2rem;
    }

    .subCounter {
      top: 20px;
      right: auto;
      left: 160px;
    }
  }
}

.subNavDrilldown {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
