@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.introContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 16px;
}

.switchContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  .toggleLabel {
    font-size: $button-font-size;
    margin: 0;
    color: $grey-600;
  }

  .toggleIcon {
    position: absolute;
    width: 20px;
    height: 20px;
    max-width: 80%;
    max-height: 90%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    margin: 0;
  }
}

.accordionInner {
  h4 {
    font-size: $base-font-size;
  }

  img {
    display: block;
    padding: 6px;
  }

  .utmsTable {
    width: 100%;
    margin-bottom: 32px;

    td {
      padding: 16px;
      white-space: initial;
    }
  }
}

.example {
  margin: 40px auto;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 700;

  > div {
    display: inline-block;
  }
}

.urlExample {
  border-width: 0;

  &:hover {
    border-bottom: 1px solid $text-color;
  }

  &.auditColor {
    color: $audit-color;
    border-bottom-color: $audit-color;
  }

  &.trackColor {
    color: $track-color;
    border-bottom-color: $track-color;
  }

  &.explainColor {
    color: $explain-color;
    border-bottom-color: $explain-color;
  }
}

.unorderedList {
  padding-inline-start: 24px;
}
