@import '../core/variables.scss';
@import '../core/breakpoints';

.tooltipTextContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 6px;

  h2,
  p {
    margin: 0;
  }
}

.tooltipText {
  text-decoration: none;
  border-bottom: 1px dashed $grey-400;

  &:hover {
    border-bottom-color: $grey-500;
  }
}

.tooltipIcon {
  cursor: pointer;
  display: block;
  width: 20px;
  height: 20px;
  color: $white;
  text-align: center;
  font-size: 14px;
  line-height: initial;
  font-weight: 700;
  background-color: $grey-400;
  border-radius: 50%;
}

.tooltipArrow {
  display: none;
}

.tooltipMessage {
  background-color: $white !important;
  border: 2px solid $grey-400;
  opacity: 1 !important;
  z-index: 1001;
  max-width: calc(100vw - 16px);
  font-weight: normal;
  text-wrap: initial;
  word-break: break-word;

  &.centerText {
    text-align: center;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    font-size: inherit !important;
  }
}
