@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.validationCheck {
  display: block;
  font-size: $x-small-font-size;
  align-items: center;
  padding-top: 8px;
  position: relative;
  padding-left: 18px;

  p,
  a {
    margin: 0;
    display: inline;
    font-size: $x-small-font-size;

    a {
      text-decoration: underline;
    }
  }

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 12px;
    width: 14px;
    height: 14px;
    background: transparent url(../assets/icon-check-grey.svg) no-repeat;
    border-radius: 10px;
    margin-right: 8px;
    line-height: $x-small-font-size;
    background-size: 14px;
  }

  .validationCheckItem {
    margin-right: 6px;
    display: inline;
  }
}

.intensiveLoading {
  font-size: $x-small-font-size;
  line-height: 1.8rem;
  color: $green;
}

.urlValidating {
  color: $grey-600;

  &:before {
    background: transparent url(../assets/icon-check-grey.svg) no-repeat;
    background-size: 14px;
  }
}

.urlIsBad {
  color: $grey-600;

  &:before {
    background: transparent url(../assets/icon-check-grey.svg) no-repeat;
    background-size: 14px;
  }
}

.urlIsGood {
  color: $green;

  &::before {
    background: transparent url(../assets/icon-check-green.svg) no-repeat;
    background-size: 14px;
  }
}

.urlWarning {
  color: $grey-600;

  &:before {
    background-color: $grey-400;
  }
}

.urlForbidden {
  &::before {
    content: '?';
    color: $white;
    font-size: 11px;
    text-align: center;
    background: none;
    background-color: $grey-500;
    width: 15px;
    height: 15px;
  }
}

.badge {
  margin: 4px 0;
  display: block;
  width: 20px;
  height: 20px;
  color: $white;
  padding: 0;
  border: none;
  font-size: $small-font-size;
  text-align: center;
  border-width: 0;
  height: $compact-button-height;
  white-space: nowrap;
  position: relative;

  &.urlForbidden::before,
  &.createdToday::before,
  &:not(.noUrl):not(.badUrl):not(.goodUrl):not(.slowLandingPage):not(.noAnalyticsTag):not(.redirectedLandingPage)::before {
    content: '?';
    pointer-events: inherit;
    font-weight: bold;
    font-size: 14px;
    background-color: $grey-500;
    border-radius: 50%;
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }

  &.noUrl {
    background: 50% 50% url('../assets/remove.svg') no-repeat;
  }

  &.badUrl {
    background: 50% 50% url(../assets/icon-check-grey.svg) no-repeat;
  }

  &.goodUrl {
    background: 50% 50% url(../assets/icon-check-green.svg) no-repeat;
  }

  &.slowLandingPage {
    background: 50% 50% url(../assets/icon-check-grey.svg) no-repeat;
  }

  &.noAnalyticsTag {
    background: 50% 50% url(../assets/icon-check-grey.svg) no-repeat;
  }

  &.redirectedLandingPage {
    background: 50% 50% url(../assets/icon-check-grey.svg) no-repeat;
  }
}

.blank {
  display: block;
  width: 20px;
  height: 20px;
}

.urlStatusMessage {
  display: inline;

  p,
  a {
    display: inline;
    font-size: $button-font-size;
  }
}

.tooltip {
  border-bottom: none;
}

.urlValidationTooltip {
  word-break: normal;
}

.observepointLink {
  display: inline;
  font-size: $button-font-size;

  img {
    height: 8px !important;
    margin-top: 1px;
    margin-left: 4px !important;
  }
}
