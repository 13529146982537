@import '../core/variables';
@import '../core/breakpoints';

.modalTitle {
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    height: 30px;
  }
}

.modalUserProgress {
  margin: -8px 0 16px;
  max-width: 300px;
}

.licencesDropdown {
  max-width: 100px;
  margin: 0 0 16px;
}

.checkboxesContainer {
  margin-bottom: 16px;

  .checkboxContainer {
    margin: 4px;
    width: fit-content;

    &:last-of-type {
      margin-bottom: 16px;
    }
  }
}

.deleteError {
  font-size: $small-font-size;
  color: $error-red;
}

.modalInput {
  margin-bottom: 0;

  textarea {
    margin-bottom: 0 !important;
  }
}
