@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.noPadding {
  padding-top: 0;
}

.qrContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;

  .qrCodePreview {
    position: relative;
    margin: 0 !important;

    &.small {
      width: 150px;
      height: 150px;
    }
  }

  .customiseButton,
  .downloadButton {
    display: block;
    width: fit-content;
    margin-bottom: 8px;
  }
}

.qrPreviewCustomiseButton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
  border-radius: 0;
  border-width: 0;
  text-align: center;
}

.qrBiLine {
  margin-top: 16px;
  max-width: 225px;
}
