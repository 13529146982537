@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.headerPanel {
  align-items: flex-end;
}

.searchSummary {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;

  @include medium {
    width: auto;
    flex-direction: row;
    justify-content: flex-start;
  }

  .searchButton {
    margin-left: auto;
  }

  .searchPreloader {
    margin: 0;
    width: 30px;
    height: 20px;
  }

  .searchCount {
    margin: 0;
    font-size: $button-font-size;
    color: $grey-600;
  }
}

.switchContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  .toggleIcon {
    position: absolute;
    width: 24px;
    height: 24px;
    max-width: 80%;
    max-height: 90%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    margin: 0;
  }
}

.tableActions {
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  @include medium {
    margin-top: 0;
    justify-content: flex-end !important;
  }

  .metricsSelector {
    min-width: 220px;
  }

  .actionsButton {
    min-width: 220px;

    & > div {
      min-width: 220px;
    }

    .observepointIcon {
      width: 120px;
      vertical-align: middle;
    }
  }

  .actionMsg {
    align-self: flex-end;
    margin-bottom: 0;
    margin-right: 0;
    font-size: $x-small-font-size;

    &.error {
      color: $error-red;
    }

    &.success {
      color: $green;
    }
  }
}

.linksTable {
  &:hover .warningTooltip {
    visibility: visible;
  }

  // Limits width for checkboxCell when table is in loading state
  td:first-child,
  th:first-child {
    min-width: unset;
    max-width: 60px;
  }

  .loadingText {
    margin: 8px 0;
  }

  .tableLinkRow {
    min-height: 100px;

    th {
      cursor: pointer;
    }

    .orderArrow {
      position: relative;
      top: 7px;
      vertical-align: middle;
    }

    .loadingCell {
      margin: 8px 0;
    }

    .checkboxCell {
      min-width: unset;
      max-width: 60px;

      .checkboxItem {
        margin-bottom: 0;

        b {
          width: $compact-button-height;
          min-width: $compact-button-height;
          min-height: $compact-button-height;
          padding-top: $compact-button-height;
          margin: auto;
        }
      }
    }

    .createdCell {
      min-width: 215px;

      .createdDateLine {
        line-height: 1.8em;
        margin-bottom: 0;
        font-size: $small-font-size;

        .createdDate {
          color: $black;
        }

        .createdTime {
          font-weight: 400;
        }
      }

      .createdUser {
        display: flex;
        width: 100%;
        color: $grey-600;
        line-height: 1.8em;
        text-overflow: ellipsis;
        overflow-x: hidden;

        &:hover {
          white-space: break-spaces;
          overflow-x: initial;
        }
      }

      .editHistoryButton {
        font-size: inherit;
        display: flex;
        align-items: center;

        & > div {
          display: inline-flex;

          > img {
            height: 10px !important;
            margin-left: 6px !important;
          }
        }
      }

      .createdTime,
      .editHistoryButton + span {
        &::before {
          content: ' ';
          display: inline-block;
          width: 5px;
          height: 5px;
          background-color: $grey-500;
          border-radius: 5px;
          margin: 0 6px;
          line-height: 0.5em;
          vertical-align: middle;
        }
      }
    }

    .shortLinkCell {
      min-width: 260px;

      &.shortLinkEditActive {
        min-width: 460px;
      }

      .addShortLink {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .domainSelector {
          max-width: 130px;

          > input {
            height: 35px;
          }
        }

        .aliasField {
          max-width: 160px;
        }

        .actionButtons {
          width: auto;

          button {
            display: block;
            font-size: $small-font-size;
            margin: 0;
            padding: 0 8px;
            min-width: auto;
          }
        }
      }

      .emptyShortLink {
        color: $grey-600;

        .addShortLinkButton {
          font-size: $small-font-size;
        }
      }
    }

    .fullLinkCell {
      min-width: 300px;
      max-width: 650px;
      white-space: normal;
      word-break: break-all;

      @include site-width {
        min-width: 650px;
      }

      .fullLinkContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;

        .alertsBellTooltip {
          border-bottom: none;

          .alertsBell {
            display: block;
            position: relative;
            margin: 4px 0;
            padding: 0;
            width: 20px;
            height: 20px;
            background: url(../assets/svgs/notification-bell.svg) no-repeat
              center center;
            background-size: contain;

            &.inactive {
              background: url(../assets/svgs/notification-bell-strikethrough.svg)
                no-repeat center center;
              background-size: contain;
            }
          }
        }
      }
    }

    .statusCell {
      .button {
        background-color: $grey-600;
        color: $white;
        padding: 4px 8px;
        width: 100%;
        position: relative;
        cursor: initial;

        &.unknownButton,
        &.unknownButton:hover {
          background-color: $grey-200;
        }

        &.newButton,
        &.newButton:hover {
          background-color: $secondary-color;
        }

        &.unusedButton,
        &.unusedButton:hover {
          background-color: $grey-400;
        }

        &.liveButton {
          background-color: $green;
          cursor: pointer;

          &:hover {
            background-color: $light-green;
            color: $green;

            svg {
              fill: $green;
            }
          }
        }

        &.endedButton {
          background-color: $grey-600;
          cursor: pointer;

          &:hover {
            background-color: $grey-400;
          }
        }

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;

          > svg {
            fill: $white;
            transition: 0.5s fill;
            height: 18px;
            width: 18px;
          }
        }
      }
    }

    .metricCell {
      .shortLinkMetricHeader {
        display: flex;
        gap: 8px;
      }
    }
  }

  // TODO: WTH is this?
  .tableLinkRow {
    td {
      .cellWrapper {
        position: relative;
        min-height: 20px;

        .cellActions {
          display: flex;
          justify-content: flex-end;
          position: absolute;
          z-index: 1;
          height: 100%;
          width: 100%;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          align-items: center;
          gap: 8px;
          margin-left: auto;
          margin-right: 0;
          opacity: 0;

          .editTooltip {
            border-bottom: none;

            .editButton {
              margin-top: 4px;
              margin-right: -16px;
            }
          }

          .createParameterReportButton {
            padding: 0;
            border: none;
            vertical-align: middle;

            svg {
              fill: $primary-color;
              height: 20px;
              width: 20px;
              vertical-align: middle;
            }
          }
        }

        &:hover {
          .cellActions::before {
            content: ' ';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            max-width: 110px;
            height: 100%;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              #fff 20%,
              #fff 100%
            );
          }

          .cellActions {
            opacity: 1;
          }
        }
      }
    }

    &:nth-child(odd) .cellWrapper:hover .cellActions::before {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        $grey-100 20%,
        $grey-100 100%
      ) !important;
    }
  }
}

.warningTooltip {
  visibility: hidden;
  width: 20px;
  height: 20px;
  color: $white;
  text-align: center;
  font-size: 14px;
  line-height: initial;
  font-weight: 700;
  background-color: $quaternary-color;
  border-radius: 50%;
  cursor: pointer;
}

.shortLinkError {
  font-size: $x-small-font-size;
}

.softDisable {
  color: $grey-400;
}

.tableColumnHeaderContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.progressBar {
  margin-bottom: 0;
}
