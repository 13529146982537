@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.panelButtonRow {
  display: flex;
}

.searchLoading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .searchPreloader {
    margin: 0;
    width: 30px;
    height: 20px;
  }
}

.searchInput {
  span[class*='selectBoxWrapper'] {
    width: 100px;
  }
}

.searchInputButton {
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    margin-bottom: 0;
  }
}
