@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.widget {
  width: 390px;
  box-shadow: $box-shadow-large-pink;
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 15px 20px;
  border: 1px solid rgba(230, 25, 105, 0.75);
  border-radius: $border-radius-small;
  background-color: $white;
  z-index: 100;
  display: none;

  @include small {
    display: block;
  }

  &.mobile {
    bottom: 10px;
    right: 10px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;

    &:last-child {
      align-items: center;
    }

    > div {
      flex-grow: 1;

      p {
        font-weight: 600;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        text-align: right;

        .pink {
          color: $primary-color;
          font-weight: 100;
          flex-grow: 1;
          line-height: 1.8rem;
        }
      }
    }

    .progressButton {
      padding: 0;

      img {
        height: 31px !important;
      }
    }

    .hide {
      padding: 0;
      font-size: $x-small-font-size;
      height: 18px;
    }

    .viewAll {
      color: $grey-600;
      font-size: $x-small-font-size;
    }
  }
}

.viewStep {
  padding: 0;
  font-size: 15px;
}

.stepComplete {
  .viewStep {
    color: $grey-600 !important;
  }

  span {
    text-decoration: line-through;
    font-style: italic;
    color: $grey-600 !important;
  }
}

.widgetTop {
  max-width: calc(100% - 44px);

  .progressIndicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 90px;

    > span {
      margin: 0 3px;
    }

    .cycleArrow img {
      height: 16px;
    }

    + span {
      max-width: calc(100% - 90px);
      overflow: hidden;
      white-space: break-spaces;

      button {
        max-width: 100%;

        span {
          text-align: right;
        }
      }
    }
  }
}
