@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.byline {
  color: $grey-600;
  display: block;

  > div {
    top: -3px;
  }
}

.subsection {
  display: inline-block;

  @include medium {
    margin-left: 0.5em;
  }
}

.matchTypeDropdown {
  div[class*='_menu_'] {
    min-width: 250px;
  }
}

.wrapper {
  background: #ffffff;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  max-width: 690px;
  margin-left: 0;
  margin-right: auto;
  overflow: hidden;
  margin-bottom: 32px;
}

.header {
  min-height: 52px;
  background: #fff;
  border-bottom: 1px solid #e2e8f0;
  font-weight: 500;
  font-size: 1.5rem;
  color: #718096;
  padding: 0 20px;
}

.footer {
  composes: header;
  background: #fff;
  border-top: 1px solid #e2e8f0;
  border-bottom: 0;
}

.body {
  max-height: 322px;
  overflow: auto;
  width: 100%;
}

.bodyItem {
  padding: 16px 20px;

  &:nth-child(even) {
    background: #f7fafc;
  }

  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #2d3748;
    display: block;
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 18px;
    color: #2d3748;
  }

  p {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    line-height: 1.4rem;
    color: #a0aec0;
    margin: 0;
  }

  select,
  select option {
    background: #ffffff;
    border: 1px solid #667eea;
    box-sizing: border-box;
    border-radius: 4px;
    color: #667eea;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;
    height: 3.5rem;
  }
}

.bodyItemSlot {
  flex-direction: column;
  padding-right: 20px;
}
