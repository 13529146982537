@import '../core/variables.scss';
@import '../core/breakpoints.scss';
@import './sso.module.scss';

.loginFormButton {
  margin-bottom: 16px;
}

.backButton {
  width: auto !important;
  font-size: $button-font-size;

  img {
    height: 10px !important;
  }
}

.svg {
  width: 220px;
  height: 140px;
  display: block;
  margin: 0 auto;
}

.extra {
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  margin-top: 10px;

  a,
  div {
    margin: 0;
    display: flex;
    font-size: $small-font-size;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  label {
    padding-left: 20px;
    margin: 0;
  }

  a {
    // font-style: italic;
    color: $tertiary-color;
  }
}

.footer {
  margin-top: 20px;
  text-align: center;
}

.passwordWrapper {
  display: flex;
  border: 0.8px solid $grey-400;
  height: 100%;
  border-radius: $border-radius-small;
  margin-bottom: 16px;
  &.focused {
    outline: 3px solid black;
    border: none;
  }

  &.blurred {
    outline: none;
    border: 0.8px solid $grey-400;
  }
}

.passwordInput {
  margin-bottom: 0;

  input {
    border: none;
    outline: none;
  }
}

.showPassword {
  height: 40px;
  font-size: $button-font-size;
  padding: 0 10px;

  &:hover {
    text-decoration: none;
  }
}

.emailSubjectMessage {
  margin-bottom: 0;

  p:not(:last-child) {
    margin-bottom: 16px;
  }
}
