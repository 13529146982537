@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.radioLabel {
  display: block;
  cursor: pointer;

  > span {
    vertical-align: middle;
  }

  .disabled {
    cursor: not-allowed;
    color: $grey-500;
  }
}

.radioInput {
  position: relative;
  margin: 0 8px 0 0;
  appearance: none;
  border: 1px solid $grey-300;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: border 0.3s, background-color 0.3s;

  &:checked {
    border-color: $grey-300;
    background-color: $grey-300;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%;
      background-color: $grey-700;
      border-radius: 50%;
    }
  }
}

.radioPanel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;

  &.horizontal {
    flex-direction: row;
  }
}

.inputLabel {
  display: block;
  margin-bottom: 8px;
  font-size: $small-font-size;
  line-height: $base-line-height;
  color: $grey-600;
  font-weight: 400;
}

.changeInputWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;

  .changeInputPrefix {
    padding-left: 12px;
    font-size: $small-font-size;
    line-height: 100%;
  }

  .changeInputContainer {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex-wrap: nowrap;
    height: 40px;
    border: 1px solid $grey-300;
    border-radius: $border-radius-small;

    &.error {
      border-color: $error-red;
    }

    input {
      flex-grow: 1;
      height: 100%;
      padding: 0 12px;
      border: none;
    }

    .changeInputLoader {
      margin-right: 12px;
      width: 40px;
      height: 25px;
    }

    .changeInputButton {
      padding-right: 12px;
      font-size: $button-font-size;
      font-weight: 600;

      &:disabled {
        color: $grey-600;
      }
    }

    .changeInputSuccess,
    .changeInputError {
      margin-top: 0;
      padding-right: 12px;
      font-size: $button-font-size;
      font-weight: 600;
    }
  }
}
