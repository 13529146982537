@import '../core/variables.scss';
@import '../core/breakpoints.scss';

/* ******************************************************* */
/* SelectBoxSimple
/* ******************************************************* */

.selector {
  background: $white;
  border: 1px solid $grey-300;
  box-sizing: border-box;
  border-radius: 4px;
  color: $black;
  font-weight: 500;
  margin: 0;
  height: $button-line-height;
  font-size: $button-font-size;
  line-height: $button-line-height;
  padding: 0 40px 0 12px;
  appearance: none;
}

.selectorWrapper {
  max-width: 430px;
  display: block;
  position: relative;

  .innerWrapper {
    position: relative;
    display: block;

    &:after {
      position: absolute;
      content: ' ';
      display: block;
      top: 16px;
      right: 16px;
      width: 10px;
      height: 10px;
      background: transparent url(../assets/drop-down-arrow.svg) no-repeat 50%
        50%;
      transform: rotate(90deg);
      pointer-events: none;
    }
  }
}

.label {
  display: block;
  font-size: 1.6rem;
  color: $grey-600;
  margin-bottom: 6px;
}

/* ******************************************************* */
/* Main SelectBox
/* ******************************************************* */

.container {
  font-size: $small-font-size;
  color: $text-color;
  letter-spacing: normal;

  .control {
    border: 1px solid $grey-300;
    border-radius: $border-radius-small;

    &:hover {
      border-color: $grey-300;
    }

    &.grey {
      border-width: 0;
      background-color: $grey-200;
    }

    &.isFocused {
      box-shadow: 0 0 0 2px $black;

      &.grey {
        background-color: $grey-400;
      }
    }

    &.error {
      border-color: $error-red;
      border-width: 1px;
    }

    &.menuIsOpen {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .dropdownIndicator {
        transform: rotate(180deg);
      }

      .singleValue {
        color: $grey-600;

        .icon {
          display: none;
        }
      }
    }
  }

  .valueContainer {
    padding: 2px 4px 2px 8px;
    cursor: text;
  }

  .singleValue {
    line-height: $base-line-height;

    &.grey {
      color: $primary-color;
      font-weight: 500;
    }
  }

  .multiValue {
    line-height: $base-line-height;
    border-radius: $border-radius-large;
    margin: 3px 2px;
    padding: 0 6px;
    font-size: $button-font-size;
    color: $primary-color;
    background: #f4d3e0;
    border: 1px solid #f4d3e0;
    transition: 0.3s all;

    &.noPill {
      background: none !important;
      border: none !important;
      padding: 0;

      .multiValueLabel {
        color: $text-color !important;
        padding: 0;
      }

      .multiValueRemove {
        display: none;
      }
    }

    &:hover {
      background: rgba($primary-color, 0.1);
      border-color: rgba($primary-color, 0.6);
    }

    &.green {
      background: rgba($green, 0.2);
      border-color: $green;
      color: $green;

      &:hover {
        background: rgba($green, 0.1);
      }

      .multiValueLabel {
        color: $green;
      }

      .multiValueRemove {
        color: $green;
      }
    }

    &.red {
      background: rgba($error-red, 0.2);
      border-color: $error-red;
      color: $error-red;

      &:hover {
        background: rgba($error-red, 0.1);
      }

      .multiValueLabel {
        color: $error-red;
      }

      .multiValueRemove {
        color: $error-red;
      }
    }

    &.grey {
      background: $grey-400;
      border-color: $grey-500;
      color: $grey-600;

      &:hover {
        background: rgba($grey-400, 0.8);
        border-color: rgba($grey-500, 0.8);
      }

      .multiValueLabel {
        color: $grey-600;
      }

      .multiValueRemove {
        color: $grey-600;
      }
    }

    .multiValueLabel {
      color: $primary-color;
      font-size: 100%;
    }

    .multiValueRemove {
      cursor: pointer;
      background-color: transparent;
      color: $primary-color;
    }
  }

  .dropdownIndicator {
    color: $primary-color !important;
    transition: transform 0.2s ease-in-out;
  }

  .indicatorSeparator {
    display: none;
  }

  .clearIcon {
    &:hover {
      cursor: pointer;
    }

    img {
      height: 12px;
    }
  }

  .placeholder {
    color: $grey-500;

    &.grey {
      color: $primary-color;
      font-weight: 500;
    }
  }
}

.hideGroupPadding {
  padding: 0 !important;
}

.groupHeading {
  position: relative;
  margin: 4px 0 8px;
  min-height: 8px;
  color: $grey-700 !important;
  font-size: $x-small-font-size !important;
  text-transform: initial !important;

  &.hideGroupLabel {
    display: none;
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-bottom: 1px solid $grey-300;
    width: 100%;
  }

  > span {
    display: block;
    position: relative;
    background-color: $white;
    width: fit-content;
    padding: 0 4px;
    z-index: 1;
  }
}

.loadingIndicator {
  height: 16px;
  width: 24px;
  margin: auto 12px auto 8px;

  > div {
    width: 4px;
  }
}

.menu {
  z-index: 1000 !important;
  font-size: $small-font-size;
  margin-top: 3px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: 0 0 0 2px $black !important;

  &.grey {
    margin-top: 4px;
  }

  .menuList {
    overflow-x: hidden;

    .virtualItem {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  .option {
    color: $text-color;
    font-weight: 600;

    &.isSelected {
      background-color: $grey-300;
    }

    &.isFocused {
      background-color: $grey-400;
    }

    &.isDisabled {
      color: $grey-500;
      cursor: not-allowed;
    }

    .optionInner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-wrap: nowrap;
      width: 100%;

      > div {
        display: block;
        overflow-x: clip;
        height: 100%;
        line-height: $base-line-height;

        > span {
          display: inline-block;
          position: relative;
          height: 100%;
          overflow: clip;
          text-overflow: ellipsis;
          transition: transform 0.6s ease-in-out;

          &:hover {
            overflow: initial;
            text-overflow: initial;
          }
        }
      }

      .tooltip {
        z-index: 100;
      }
    }
  }
}

.noOptionsMessage,
.loadingMessage {
  text-align: left !important;
}

.icon {
  vertical-align: middle;
  margin-right: 10px;
  max-width: 20px;
  width: 16px;
  height: 16px;
}

.additionalOptions {
  > * {
    padding: 8px 12px;
    margin: 0;
  }
}

.checklistValueContainer {
  flex-wrap: nowrap !important;
}

.checklistMultiValue {
  font-size: $button-font-size;
  background-color: transparent !important;
  transition: 0.3s all;

  .checklistValueTooltip {
    border-bottom: none;
    max-width: 250px;
  }

  .multiValueLabel {
    font-size: 100%;
  }

  &.grey .multiValueLabel {
    color: $primary-color;
  }
}

.checklistMenu {
  min-width: max-content;

  .checklistOptionRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    width: 100%;
    max-width: 100%;

    .checkbox {
      border: 1px solid $grey-300;
      border-radius: $border-radius-small;
      min-width: 28px;
      height: 28px;
      margin-right: 8px;
    }

    .onlyButton {
      position: absolute;
      right: 0;
      background-color: $grey-400;
      padding: 2px 8px;
      visibility: hidden;
      transition: none;
      max-height: 100%;
    }

    &:hover .onlyButton {
      visibility: visible;
    }
  }

  .option {
    &.isFocused {
      background-color: $grey-200;
    }

    &.isSelected {
      &:not(.isFocused) {
        background-color: transparent;
      }

      .checkbox {
        background-image: url(../assets/tick-green.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 75%;
      }
    }

    &.isDisabled {
      color: $grey-500;
      cursor: not-allowed;
    }
  }
}

.anyNoneContainer {
  border-bottom: 1px solid $grey-300;

  .checklistOptionRow {
    padding: 8px 12px;
    font-weight: 600;
    font-size: inherit;
    color: inherit;
    width: 100%;
    transition: none;

    &:hover {
      text-decoration: none !important;
      cursor: initial;
      background-color: $grey-200 !important;
    }

    .checkbox.isSelected {
      background-image: url(../assets/tick-green.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 75%;
    }
  }
}
