@import '../core/variables.scss';

.errorContainer {
  width: 100%;
  flex: 100%;
  max-height: 250px;
  overflow: hidden;
  margin: 0 auto 16px;

  &.shake {
    animation: errorShake 0.4s 1 linear;
  }

  .inner {
    padding: 10px;
    width: 100%;
    background-color: $pink;
    border-radius: $border-radius-small;
    font-size: 1.4rem;
    text-align: center;
    color: $primary-color;

    a {
      text-decoration: underline;
    }

    p {
      margin: 0 0 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@keyframes errorShake {
  0% {
    transform: translate(30px);
  }
  20% {
    transform: translate(-30px);
  }
  40% {
    transform: translate(15px);
  }
  60% {
    transform: translate(-15px);
  }
  80% {
    transform: translate(8px);
  }
  100% {
    origin-transform: translate(0px);
  }
}
