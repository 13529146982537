@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.graphContainer {
  margin-top: 6px;
  padding: 8px 20px;
  border: 1px solid $grey-400;
  border-radius: $border-radius-small;
}

.graphWrapper {
  position: relative;
  margin-bottom: 24px;
  margin-top: 12px;

  h1 {
    font-size: $medium-font-size;
    margin: 0;
    @include medium-wide {
      display: flex;
    }
  }
  .successMetricSelector {
    width: 100%;
    margin: 8px 0 12px 0;
    @include medium {
      > input {
        transform: translateY(-4px);
      }
    }
    @include medium-wide {
      width: auto;
      margin: 0 8px 0 12px;
    }
  }
}

.graphPaper {
  position: relative;

  .titleRow {
    display: flex;
    justify-content: space-between;

    .tabsWrapper {
      border: 1px solid rgba($grey-400, 0.6);
      padding: 0;
      margin: 0 0 8px;
      display: inline-flex;
      width: auto;
      border-radius: $border-radius-small;
      overflow: hidden;
      height: 38px;
      background: $white;

      @include medium {
        margin: 0;
      }

      .tabs {
        margin: 0;
        width: 40px;
        position: relative;

        &:hover {
          cursor: pointer;
        }

        &:first-child {
          border-right: 1px solid $grey-400;
        }

        img {
          position: absolute;
          top: 12px;
          left: 12px;
        }

        &:last-child {
          img {
            top: 9px;
            left: 10px;
            width: 21px;
          }
        }
      }

      input[type='radio'] {
        position: absolute;
        left: -9999px;
        width: auto;

        & + em + span {
          background-color: $grey-100;
          display: block;
          width: 40px;
          height: 40px;
        }

        &:checked + em + span {
          background-color: $white;
          z-index: 2;
          opacity: 0.6;
        }
      }

      input[type='radio'] + em {
        display: none !important;
      }
    }
  }

  .colorLegendWrapper {
    margin-top: 16px;
    margin-bottom: -16px;
    svg {
      display: none;
    }
  }

  .colorLegendDot {
    display: flex;
    flex-direction: row;
    padding: 4px 8px 4px 0;

    .dotTitle {
      color: $black;
      font-size: $small-font-size;
      padding-left: 8px;
    }
    .dot {
      width: 16px;
      height: 16px;
      border-radius: 16px;
    }
    &:hover {
      cursor: pointer;
      .dotTitle {
        text-decoration: underline;
      }
      .dot {
        box-shadow: 0px 0px 0px 2px;
      }
    }
  }
}

.hint {
  z-index: 10;
  &.hintLineChart {
    .hintBox {
      transform: translateY(0);
    }
    .hintDate {
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.hintBox {
  white-space: nowrap;
  border-radius: $border-radius-medium;
  padding: 12px 12px 0;
  background-color: $white;
  border: 1px solid $grey-200;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  transform: translateY(50%);
  min-width: 160px;
}

.hintDate {
  font-size: $small-font-size;
  font-weight: 400;
  color: $grey-600;
  margin-left: 24px;
  margin-top: -8px;
}

.hintTitle {
  color: $grey-900;
  font-size: $small-font-size;
  font-weight: 600;
  margin: 0 0 8px;
  display: flex;
  flex-direction: row;
}

.hintColour {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-top: 2px;
  margin-right: 8px;
}

.xAxislabel {
  color: #6b6b76;
  font-size: 11px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -8px;
  text-align: center;
}
