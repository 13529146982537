@import '../core/variables.scss';

.requestConnectorModal {
  .connectorLogo {
    display: block;
    margin: 0 auto 16px;
    max-height: 40px;
  }

  .connectorName {
    font-weight: 600;
  }

  .formRow {
    padding: 0;
  }

  div[class*='modalFooter'] button[class*='primary'] {
    min-width: 135px;
  }

  .error {
    color: $error-red;
    font-size: $x-small-font-size;
    line-height: $base-font-size;
  }
}
