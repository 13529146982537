@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  width: 100%;
  margin-bottom: 12px;
  border: 1px solid $grey-200;
  background-color: $white;
  padding: 6px 12px 12px 16px;
  box-shadow: inset 0 0 6px $grey-300;
  border-radius: $border-radius-small;
}
.graphWrapper {
  // padding: 24px 0;
  position: relative;
}
.anomaly {
  transition: 0.3s all;
  transform: translateY(-12px) translateX(-12px);
  cursor: pointer;

  &:hover {
    transform: translateY(calc(-12px * 1.2)) translateX(calc(-12px * 1.2))
      scale(1.2);
  }
}

.graphLoader {
  margin-top: 0;
  margin-bottom: 16px;
}

.noAnomalyMessage {
  margin: 24px 0 16px;
}

.noData {
  .graphWrapper {
    display: none;
  }
}
.box {
  border-radius: $border-radius-medium;
  padding: 12px 12px 0;
  background-color: $white;
  border: 1px solid $grey-200;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  width: 290px;
  box-sizing: border-box;
}

.dateBox {
  white-space: nowrap;
  border-radius: $border-radius-medium;
  padding: 12px 12px 0;
  background-color: $white;
  border: 1px solid $grey-200;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  // min-width: 240px;
  box-sizing: border-box;
  transform: translateX(50%);
}

.hint {
  z-index: 10;
}

.title {
  color: $grey-900;
  font-size: $small-font-size;
  font-weight: 600;
  margin: 0 0 8px;
}
.link {
  display: block;
  font-weight: 600;
  font-size: $small-font-size;

  &:after {
    content: ' > ';
  }
}
.para {
  font-size: $small-font-size;
  color: $grey-700;
}
.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: $grey-400;
  border-radius: 5px;
  margin: 0 8px;
  line-height: 1em;
  vertical-align: middle;
}
.titleLink {
  color: $primary-color;
  font-size: $small-font-size;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
}
.neutralDot {
  fill: #a6cee3;
  transform: translate(-3px, -3px);
}
.topLayer {
  z-index: 2;
}
.tagsTitle {
  font-size: $small-font-size;
  color: $grey-700;
  font-weight: 700;
  margin: 8px 0 8px;
}
.tagsWrapper {
  margin-bottom: 16px;
  max-width: 100%;
}
.tag {
  max-width: 100%;
  button {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.boxDiff {
  margin-bottom: 8px;
}

.markDown {
  padding-bottom: 12px;
  h1,
  h2,
  h3,
  p,
  h4 {
    color: $grey-700;
    margin: 0 0 4px;
    line-height: 1.6em;
    font-size: $small-font-size;
  }
}
.marginTop {
  margin-top: 16px !important;
}

.yAxisTitleWrapper {
  transform-origin: 0 0;
  transform: rotate(-90deg);
  position: absolute;
  top: 50%;
  left: -8px;
}

.yAxisTitle {
  text-align: center;
  transform: translateX(-50%);
  // writing-mode: vertical-rl;
  white-space: nowrap;
  font-size: $small-font-size;
  font-weight: 400;
  color: $grey-600;
  line-height: 1em;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map {
  display: flex;
  flex-direction: column;
  z-index: 2;
  align-items: flex-start;

  @include medium {
    // top: 0;
    // right: -4px;
    // position: absolute;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;
  }
}

.areaButton {
  background-color: rgba($grey-200, 0.8);
  border-radius: $border-radius-medium;
  font-size: $small-font-size;
  font-weight: normal;
  padding: 0 12px 0 30px;
  outline: none;
  display: inline-block;
  position: relative;
  margin: 8px 0 0;
  height: 30px;
  line-height: 30px;
  text-align: left;

  @include medium {
    margin: 0 0 0 8px;
  }

  &:before {
    position: absolute;
    display: block;
    content: ' ';
    top: 8px;
    left: 8px;
    height: 14px;
    width: 14px;
    border: 1px solid $grey-400;
    background-color: $white;
    border-radius: 2px;
  }

  &:hover {
    background-color: #edf2f7;
    opacity: 0.8;
    color: $grey-800;
  }

  &.areaButtonActive {
    &:before {
      background: $white url('../assets/tick-green.svg') no-repeat 50% 50%;
      background-size: 80%;
    }
  }
}
.titleDate {
  color: #347a95;
}
