.observepointLogo {
  max-width: 50%;
  min-width: 200px;
  margin: 0 auto 16px;
  display: block;
}

.link {
  word-break: break-all;
}

.scanTooltip {
  border-bottom-width: 0;
}
