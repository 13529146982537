@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.accordionContainer {
  margin-top: 0 !important;
  border-top-width: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.profileImg img {
  display: block;
  width: 42px;
  height: 42px;
  max-width: 42px;
}
