@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.byline {
  color: $grey-600;
  display: block;

  > div {
    top: -3px;
  }
}

.subsection {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .fieldRow {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    .field {
      min-width: 300px;
    }
  }
}

.updatePasswordMessage {
  font-size: $x-small-font-size;
  color: red;

  &.success {
    color: green;
  }
}
