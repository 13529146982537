@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.layout {
  @include medium {
    height: calc(100vh - 40px);
    justify-content: center;
    display: block;
    min-height: 100%;
    align-items: center;
  }
}
