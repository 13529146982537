@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 0;
}

.button {
  cursor: pointer;
  box-sizing: border-box;
  background-color: #edf2f7;
  color: #4a5568;
  margin: 10px;
  border: 0;
  border-radius: 1000px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  img {
    margin-right: 10px;
    max-height: 18px;
    width: auto;
  }

  @media (min-width: 768px) {
    margin: 0 5px;
  }
}

.active {
  composes: button;
  background-color: $grey-800;
  color: $white;
  fill: $white;
}

.tabsWrapper {
  border: 1px solid rgba($grey-600, 0.6);
  padding: 0;
  margin: 0 0 8px;
  display: inline-flex;
  width: auto;
  border-radius: $border-radius-small;
  position: relative;
  overflow: hidden;
  height: 38px;
  background: $white;

  @include medium {
    margin: 0;
  }

  > label > span {
    padding: 0 1px !important;
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;
    width: auto;

    &:checked + em + span {
      background-color: $grey-600;
      color: $white;
    }
  }

  input[type='radio'] + em {
    display: none !important;
  }

  label {
    font-size: 1.4rem;
    text-align: center;
    line-height: 38px;
    border-right: 1px solid rgba($grey-600, 0.2);
    margin: 0;

    span {
      padding: 0 12px;
      vertical-align: top;
    }

    &:last-child {
      border-right: 0;
      span {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    &:first-child {
      span {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:hover {
      background-color: rgba($grey-600, 0.2);
      cursor: pointer;
    }
  }
}
