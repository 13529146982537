@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.form {
  .label {
    margin-bottom: 8px;
    display: block;
    color: $grey-700;
    font-weight: 600;
    font-size: $base-font-size;
  }
  textarea {
    margin-bottom: 0;
  }

  > div {
    padding-bottom: 16px;

    @include medium {
      padding-bottom: 0;
    }
  }
}
.input {
  border-radius: $border-radius-small;
  border: 1px solid $grey-400;
  padding: 0 16px 0;
  margin-bottom: 16px;
  height: 42px;
  overflow: hidden;

  label {
    height: 40px;
    line-height: 40px;
    font-weight: normal;
    border-width: 0 !important;
  }

  input {
    border: none;
    padding-left: 0;
    outline: none;
  }
}

.twoColumns {
  display: flex;
  flex-direction: column;

  @include medium {
    flex-direction: row;
    align-items: center;
    > div {
      width: 50%;
    }

    > label {
      margin-right: 8px;
    }

    > div:nth-child(2) {
      margin-left: 16px;
    }
  }
}
