@import '../core/variables.scss';
@import '../core/breakpoints.scss';
@import './sso.module.scss';

.svg {
  width: 100%;
  display: block;
  margin: 0 auto;
  flex: 320px;
  padding: 20px;
  max-width: 220px;
}

.terms span {
  text-wrap: initial !important;
  line-height: 1.6rem !important;
}
