@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.highlight {
  background-color: $light-green;
}

.dateCell {
  width: 215px !important;
  min-width: 215px !important;
  max-width: 215px !important;

  .createdWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    font-size: $small-font-size;
    margin-bottom: 4px;

    .created {
      display: flex;
      line-height: 1.8em;
    }

    .date,
    .versionNumber {
      color: $black;
    }

    .date::before {
      content: ' ';
      display: inline-block;
      width: 5px;
      height: 5px;
      background-color: $grey-500;
      border-radius: 5px;
      margin: 0 6px;
      line-height: 0.5em;
      vertical-align: middle;
    }

    .timeSince {
      font-weight: 400;

      &::before {
        content: ' ';
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: $grey-500;
        border-radius: 5px;
        margin: 0 6px;
        line-height: 0.5em;
        vertical-align: middle;
      }
    }

    .user {
      line-height: 1.8em;
      width: 100%;
      display: flex;
      color: $grey-600;
      text-overflow: ellipsis;
      overflow-x: hidden;
      display: block;

      &:hover {
        white-space: break-spaces;
        overflow-x: initial;
      }
    }
  }
}

.codeCell {
  width: 450px !important;
  min-width: 450px !important;
  max-width: 450px !important;

  .codeText {
    height: auto;
    white-space: normal;
    word-break: break-all;
    line-height: 1.8em;
    font-weight: normal;
  }
}

.hasChanged {
  background-color: $light-green;
}
