@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.subsection {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .fieldRow {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    .field {
      min-width: 300px;
    }
  }
}

.radioRow {
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }
}

.alignRow {
  display: flex;
  width: 100%;
  flex-direction: row;

  label {
    max-width: 160px;
    margin: 0;
  }
}

.fieldLabel {
  display: block;
  font-size: 1.6rem;
  color: $grey-600;
  margin-bottom: 6px;
}
