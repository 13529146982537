@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.dropzoneEmpty {
  display: flex;
  margin: 0 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  width: fit-content;

  p {
    margin: 0;
    font-size: $base-font-size;
    font-weight: 600;
  }
}
.dropZoneWrapper {
  padding-top: 4px;
  margin-bottom: 8px;
}

.dropZone {
  background-color: $grey-100;
  border-radius: $border-radius-medium;
  border: 1px solid $grey-400;
  min-height: 180px;
  align-items: center;
  display: flex;
  justify-content: center;

  &.dropZoneActive,
  &.dropZoneAccept {
    background-color: $grey-200;
    border: 1px solid $grey-600;
  }

  &.dropZoneReject {
    background-color: rgba($error-red, 0.6);
    border: 1px solid $error-red;
  }
}

.success {
  font-size: $x-small-font-size;

  &::before {
    line-height: $x-small-font-size;
  }

  a {
    color: $green;
    text-decoration: underline;
  }
}

.error {
  font-size: $x-small-font-size;
  color: $error-red;
  margin-bottom: 0;

  a {
    color: $error-red;
    text-decoration: underline;
  }
}
