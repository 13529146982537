@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.wrapper {
  margin-top: 0;
}

.metricsList {
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  border-bottom: 1px solid $grey-300;
}

.itemDragged {
  z-index: 620;
}

.row {
  display: flex;
  padding: 0;
  border-bottom: 1px solid $grey-200;
  position: relative;

  @include medium {
    border-bottom: 0;

    // This is to enable drag and order functionality
    &:hover {
      cursor: move;
    }
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }

  label {
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    margin: 0;
    opacity: 1;
    transition: transform $animation-duration;
  }
}

.typeahead {
  margin-bottom: 16px;
}

.actionPanel {
  display: none;
}

.confirmationButtons {
  margin-top: 16px;
}

.actionPanelActive {
  .actionPanel {
    display: block;
  }
  .body {
    display: none;
  }
}

.heading {
  font-size: 1.6rem;
  margin: 0 0 16px;
}
.table {
  border: 0;
}

.tableHeader {
  flex-direction: row;
  vertical-align: middle;
  display: none;
  justify-content: space-between;

  .tableCell {
    font-size: $base-font-size;
    color: $grey-700;
    font-weight: 600;
  }

  @include medium {
    display: flex;
  }
}

.tableCell {
  padding: 8px 16px 4px;

  @include medium {
    padding: 12px 16px;
    align-items: left;
    border-right: 1px solid $grey-300;
    width: 20%;
    height: 52px;
    display: flex;
    align-items: center;

    &:nth-child(1) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 25%;
    }
    &:nth-child(4) {
      width: 10%;
    }
    &:nth-child(5) {
      width: 20%;
    }

    &:last-child {
      border-right: 0;
    }
  }
}

.tableCellContent {
  display: inline;
  white-space: normal;
}

.tableBody {
  display: block;
}
.tableCell {
  text-align: left;
  line-height: 1.8rem;
  font-size: 1.4rem;
  color: $grey-700;

  @include medium {
    margin: 0;
  }

  .tableCellContentPadded {
    margin-right: 4px;
  }
}

.counterLabel {
  display: inline-block;
  margin: 0 8px 0 0;
  padding: 0 8px;
  white-space: nowrap;
  width: auto;
}

.tagWrapper {
  font-size: $small-font-size;
  font-weight: 600;
}

.tag {
  display: inline-block;
  margin: 0 0 0 8px;
  background: rgba($green, 0.2);
  color: $green;
  padding: 4px 8px;
  border-radius: $border-radius-medium;
  line-height: 1em;
}

.tagIsBad {
  background: #ffeef4;
  color: #d4366a;
}

.tableCellButtons {
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: auto;

    @include medium {
      width: 100%;
    }
  }
}

.goodBadPanel {
  display: flex;
  overflow: hidden;
  justify-content: flex-start;
  margin-bottom: 16px;

  label {
    margin: 0;
  }
}

.radioBox {
  border: 1px solid $grey-200;
  border-radius: $border-radius-medium;
  max-width: 200px;
  padding-bottom: 4px;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;
    border-color: $grey-400;
  }

  &:first-child {
    margin-right: 32px;
  }

  &:after {
    content: ' ';
    display: block;
    background: transparent url(../assets/icon-warning-green.svg) no-repeat 50%
      50%;
    background-size: 100%;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -6px;
  }

  &:last-child {
    &:after {
      background-image: url(../assets/icon-warning.svg);
    }
  }

  > span {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;

    &:before {
      content: ' ';
      display: block;
      background-color: $grey-100;
      border-bottom: 1px solid $grey-200;
      height: 86px;
      width: 100%;
      margin-bottom: 4px;
    }

    &:after {
      content: ' ';
      display: block;
      background: transparent url(../assets/graph-up.svg) no-repeat 50% 50%;
      background-size: 100%;
      height: 38px;
      width: 100%;
      position: absolute;
      top: 24px;
      left: 0;
    }
  }
  &.radioBoxBad {
    > span {
      &:after {
        background-image: url(../assets/graph-down.svg);
      }
    }
  }

  input {
    &[type='radio'] {
      &:checked ~ em {
        background-image: url(../assets/radio-red.svg) !important;
      }
    }
  }
  &:first-child {
    input {
      &[type='radio'] {
        &:checked ~ em {
          background-image: url(../assets/radio-blue.svg) !important;
        }
      }
    }
  }
}

.radioPanel {
  border: 1px solid $grey-200;
  border-radius: $border-radius-medium;
  overflow: hidden;
  margin-bottom: 16px;

  label {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0 12px;

    &:nth-child(even) {
      background-color: $grey-100;
    }
  }

  p {
    padding-left: 34px;
    color: $grey-600;
    font-size: $small-font-size;
    margin: 0;
  }
}

.mobileOnly {
  display: inline-block;
  font-size: $base-font-size;
  color: $grey-700;
  font-weight: 600;
  padding-right: 8px;

  @include medium {
    display: none;
  }
}

.manageModal {
  > div[class*='modalBody'] {
    padding: 0;
  }
}

.reportModal {
  > div[class*='modalBody'] {
    padding: 0;
  }

  .tableCell {
    padding: 8px 16px 4px;

    @include medium {
      width: 20%;

      &:nth-child(1) {
        width: 55%;
      }
      &:nth-child(2) {
        width: 27%;
      }
      &:nth-child(3) {
        width: 18%;
      }

      &:last-child {
        border-right: 0;
      }
    }
  }

  .editButton {
    margin-right: 0;

    @include medium {
      margin-right: 0;
      margin-left: -10px;
    }
  }
}

.addmetricModal div[class*='modalBody'] {
  overflow: initial;
}

.itemLabel {
  display: block;
  text-align: center;
  white-space: nowrap;
  height: 30px;
  vertical-align: middle;
  color: $grey-700;
  font-size: $x-small-font-size;
  font-weight: 600;
  line-height: 30px;
  text-transform: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: none;
  text-align: left;
  position: relative;
  box-sizing: border-box;

  span {
    display: inline-block;
    background-color: $white;
    position: relative;
    z-index: 7;
    margin: 0 10px 0 20px;
    padding: 0 8px;
    box-sizing: border-box;
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    border-bottom: 2px solid $grey-200;
    left: 0;
    top: 15px;
    width: 100%;
    height: 1px;
    z-index: 6;
  }
}

.labelIcon {
  margin-top: -2px;
  width: 18px;
  vertical-align: middle;
  margin-right: 8px;
}
