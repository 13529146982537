@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.dropdownButtonContainer {
  display: inline-block;
  position: relative;
}

.mainButtonContainer {
  display: inline-flex;
  box-sizing: border-box;
  line-height: $base-line-height;
  cursor: pointer;
  font-size: $button-font-size;
  transition: 0.5s all;
  border: 1px solid;
  border-radius: $border-radius-small;
  overflow: hidden;
  background-color: $grey-300;
  border-color: $grey-400;
  color: $white;

  &.disabled {
    cursor: not-allowed;
    color: $grey-600;

    .dropdownArrowButton {
      border-left-color: $grey-600;

      .arrowIcon {
        fill: $grey-600 !important;
      }
    }
  }

  &.primary:not(.disabled) {
    &.color {
      &-pink {
        border-color: $primary-color;

        // Only change hovered area if main button does not expand dropdown
        > div {
          background-color: $primary-color;

          &.isSeparate:hover:not(.demoOnly) {
            color: $primary-color;
            background-color: $pink;

            &.dropdownArrowButton {
              border-left-color: $primary-color;

              .arrowIcon {
                fill: $primary-color;
              }
            }
          }
        }

        // Change color of whole button if main button expands dropdown
        &:hover {
          > div:not(.isSeparate):not(.demoOnly) {
            color: $primary-color;
            background-color: $pink;

            &.dropdownArrowButton {
              border-left-color: $primary-color;

              .arrowIcon {
                fill: $primary-color;
              }
            }
          }
        }
      }

      &-blue {
        border-color: $tertiary-color;

        // Only change hovered area if main button does not expand dropdown
        > div {
          background-color: $tertiary-color;

          &.isSeparate:hover:not(.demoOnly) {
            color: $tertiary-color;
            background-color: $white;

            &.dropdownArrowButton {
              border-left-color: $tertiary-color;

              .arrowIcon {
                fill: $tertiary-color;
              }
            }
          }
        }

        // Change color of whole button if main button expands dropdown
        &:hover {
          > div:not(.isSeparate):not(.demoOnly) {
            color: $tertiary-color;
            background-color: $white;

            &.dropdownArrowButton {
              border-left-color: $tertiary-color;

              .arrowIcon {
                fill: $tertiary-color;
              }
            }
          }
        }
      }

      &-white {
        border-color: $grey-200;

        // Only change hovered area if main button does not expand dropdown
        > div {
          background-color: $white;
          color: $text-color;

          &.dropdownArrowButton {
            border-left-color: $grey-300;

            .arrowIcon {
              fill: $primary-color;
            }
          }

          &.isSeparate:hover:not(.demoOnly) {
            background-color: $grey-200;
          }
        }

        // Change color of whole button if main button expands dropdown
        &:hover {
          > div:not(.isSeparate):not(.demoOnly) {
            background-color: $grey-200;
          }
        }
      }
    }
  }

  &.secondary {
    &.color {
      &-pink {
        color: $primary-color;

        .arrowIcon {
          fill: $primary-color;
        }
      }

      &-blue {
        color: $tertiary-color;

        .arrowIcon {
          fill: $tertiary-color;
        }
      }

      &-white {
        border-color: $grey-200;

        // Only change hovered area if main button does not expand dropdown
        > div {
          background-color: $white;
          color: $text-color;

          &.dropdownArrowButton {
            border-left-color: $grey-300;

            .arrowIcon {
              fill: $primary-color;
            }
          }

          &.isSeparate:hover:not(.demoOnly) {
            background-color: $grey-200;
          }
        }

        // Change color of whole button if main button expands dropdown
        &:hover {
          > div:not(.isSeparate):not(.demoOnly) {
            background-color: $grey-200;
          }
        }
      }
    }

    &:not(.disabled) {
      &.color {
        &-pink {
          border-color: $primary-color;

          // Only change hovered area if main button does not expand dropdown
          > div {
            background-color: $pink;

            &.isSeparate:hover:not(.demoOnly) {
              background-color: $white;
            }
          }

          // Change color of whole button if main button expands dropdown
          &:hover {
            > div:not(.isSeparate):not(.demoOnly) {
              background-color: $white;
            }
          }
        }

        &-blue {
          border-color: $tertiary-color;

          // Only change hovered area if main button does not expand dropdown
          > div {
            background-color: $white;
          }
        }
      }
    }
  }
}

.dropdownMainButton,
.dropdownArrowButton {
  display: inline-block;
  padding: 8px 12px;
  transition: 0.5s all;
}

.dropdownMainButton {
  flex-grow: 1;
}

.dropdownArrowButton {
  border-left: 1px solid;
  padding: 8px 16px;

  .arrowIcon {
    width: 8px;
    transform: rotate(90deg) translateX(2px);
    fill: $white;
    transition: transform 0.1s ease-in-out;

    &.isExpanded {
      transform: rotate(-90deg) translateX(-2px);
    }
  }
}

.dropdownItemsContainer {
  min-width: 100%;
  border: 1px solid $grey-400;
  border-bottom-left-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;
  position: absolute;
  z-index: 10;
  background-color: $white;
}

.dropdownButtonItem {
  width: 100%;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  padding: 10px 12px;
  line-height: $base-line-height;
  background-color: transparent;
  cursor: pointer;
  color: $text-color;
  transition: 0.2s all;
  border: none;
  font-weight: 600;

  &:hover:not(:disabled):not(.loading) {
    background-color: $grey-300;
  }

  &:disabled,
  &.loading {
    cursor: not-allowed;
    color: $grey-400;

    span[class*='tooltipText'] {
      border-bottom: none;
    }
  }

  .loadingBars {
    height: 24px;
  }
}

.loadingBars {
  margin: 0 auto;
  height: 100%;
  min-height: 24px;
  width: 35px;
}

.itemLabel {
  display: block;
  text-align: center;
  white-space: nowrap;
  height: 30px;
  vertical-align: middle;
  color: $grey-500;
  font-size: $small-font-size;
  line-height: 30px;
  text-transform: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: none;
  text-align: left;
  position: relative;
  box-sizing: border-box;

  span {
    display: inline-block;
    background-color: $white;
    position: relative;
    z-index: 7;
    margin: 0 10px 0 4px;
    padding: 0 8px;
    box-sizing: border-box;
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    border-bottom: 2px solid $grey-200;
    left: 0;
    top: 15px;
    width: 100%;
    height: 1px;
    z-index: 6;
  }
}

.inlineFakeButton > div[class*='mainButtonContainer'] {
  font-size: $x-small-font-size;

  > div[class*='dropdownMainButton'] {
    padding: 2px 4px;
  }

  > div[class*='dropdownArrowButton'] {
    padding: 2px 6px;

    > svg {
      width: 6px;
    }
  }
}
