.container {
  height: 20px;
  width: 20px;
  overflow: hidden;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    position: absolute;
    fill: #a1a1a1;
    color: #a1a1a1;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 20px;
    width: 20px;
    transition: 0.5s all;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    &.close {
      height: 30px;
      width: 30px;
    }
  }
}

.active {
  opacity: 1;
  pointer-events: auto;
}
