.arrow {
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../assets/arrow.svg) 50% 50% no-repeat;
  background-size: 100%;
  opacity: 0.4;
  margin-left: 8px;
  top: 50%;
  transform: translateY(-50%);
  &.arrowTypeBi {
    background: url(../assets/arrow-up-down.svg) 50% 50% no-repeat;
  }
}

.ascOrder {
  transform: rotate(180deg) translateY(50%);
}
