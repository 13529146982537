@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.tableActions {
  justify-content: space-between;
  margin-bottom: 8px;
  align-items: center;

  .flexBtns {
    display: flex;
    gap: 1em;
  }

  .tableSearch {
    display: flex;
  }
}

.deleteColumn {
  width: 120px;

  .actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
  }
}

.restrictDropdownsColumnHeader {
  display: flex;
  align-items: center;

  .selectWrapper {
    margin: 0 8px;
  }
}

.actionsTooltip {
  background-color: $white !important;
  box-shadow: 1px 1px 5px $grey-400;

  p {
    display: inline-block;
    margin: 0 10px 5px !important;
    line-height: 2.4rem;
  }

  img {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
  }
}

.multiSelect {
  min-width: 140px;
  margin: 8px 0;
}

.downloadTemplateButton {
  padding: 2px 4px;
}
