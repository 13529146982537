@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.button {
  position: relative;
  box-sizing: border-box;
  border-radius: $border-radius-small;
  padding: 8px 12px;
  line-height: $base-line-height;
  background-color: transparent;
  cursor: pointer;
  color: $text-color;
  transition: 0.5s all;

  &.loading,
  &:disabled {
    background-color: $grey-300;
    border-color: $grey-300;
    color: $grey-600;
  }

  &:disabled {
    cursor: not-allowed;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    // Make sure tooltips don't have dotted underline
    span[class*='tooltipText'] {
      border-bottom: none;
    }

    .loadingBars {
      margin: 0;
      height: 20px;
      width: 35px;
    }

    .buttonIcon {
      display: inline-block;
      vertical-align: middle;
      height: 24px;

      &.iconBefore {
        margin-right: 8px;
      }

      &.iconAfter {
        margin-left: 8px;
      }
    }
  }
}

.primary {
  color: $white;
  border: 1px solid;

  &:not(.loading):not([disabled]).color {
    &-pink {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &-blue {
      background-color: $tertiary-color;
      border-color: $tertiary-color;
    }

    &-yellow {
      color: $text-color;
      background-color: $quaternary-color;
      border-color: $quaternary-color;
    }

    &-green {
      background-color: $green;
      border-color: $green;
    }

    &-red {
      background-color: $error-red;
      border-color: $error-red;
    }

    &-grey {
      background-color: $grey-500;
      border-color: $grey-500;
    }
  }

  &:hover:not([disabled]):not(.demoOnly).color {
    &-pink {
      color: $primary-color;
      background-color: $pink;
    }

    &-blue {
      background-color: $white;
      color: $tertiary-color;
    }

    &-yellow {
      background-color: $white;
      color: $quaternary-color;
    }

    &-green {
      color: $green;
      background-color: $light-green;
    }

    &-red {
      color: $error-red;
      background-color: $pink;
    }

    &-grey {
      background-color: $white;
      color: $grey-500;
    }
  }
}

.secondary {
  background-color: $white;
  border: 1px solid;

  &:not(.loading):not([disabled]).color {
    &-pink {
      color: $primary-color;
      border-color: $primary-color;
      background-color: $pink;
    }

    &-blue {
      color: $tertiary-color;
      border-color: $tertiary-color;
    }

    &-yellow {
      color: $quaternary-color;
      border-color: $quaternary-color;
    }

    &-green {
      color: $green;
      border-color: $green;
    }

    &-red {
      color: $error-red;
      border-color: $error-red;
    }

    &-grey {
      color: $grey-500;
      border-color: $grey-500;
    }
  }

  &:hover:not([disabled]):not(.demoOnly) {
    background-color: $white;

    &.color {
      &-blue {
        color: $white;
        background-color: $tertiary-color;
      }

      &-green {
        background-color: $light-green;
      }

      &-red {
        background-color: $pink;
      }

      &-grey {
        background-color: $grey-200;
      }
    }
  }
}

.plainBox {
  background-color: $white;
  border: 1px solid #edf2f7;

  &:hover:not(.demoOnly) {
    background-color: $grey-200;
  }
}

.text {
  border: none;
  padding: 0;
  font-size: $base-font-size;
  background-color: transparent !important;

  &:disabled {
    text-decoration: line-through;
  }

  &.color {
    &-pink {
      color: $primary-color;
    }

    &-blue {
      color: $tertiary-color;
    }

    &-yellow {
      color: $quaternary-color;
    }

    &-green {
      color: $green;
    }

    &-red {
      color: $error-red;
    }

    &-grey {
      color: $grey-600;
    }
  }

  &:hover:not(:disabled):not(.demoOnly) {
    text-decoration: underline;
  }
}

.iconOnly {
  border: none;
  padding: 4px;
}

.navigateButton {
  font-weight: 600;

  &.back {
    img {
      transform: rotate(180deg);
    }
  }
}

.copyButton {
  font-weight: 600;
}

.deleteButton {
  background-color: #fed7d7 !important;
  border: none !important;
  padding: 6px;

  &:hover:not(.demoOnly) {
    background-color: #fec5c5 !important;
  }

  img {
    width: 20px;
  }
}

.clearButton {
  font-weight: 600;
  padding: 0;

  img {
    height: 14px !important;
  }
}

.inlineFakeButton {
  padding: 2px 4px;
  font-size: $x-small-font-size;
}
