@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.outerBox {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.innerBox {
  &.loading {
    display: flex;
    align-items: center;
    height: 400px;

    > div {
      height: 60px;
      width: 75px;
    }
  }
}

.intro {
  font-weight: 600;
}

.bulkUploadContent {
  // Removes content shift on hover
  div[class*='formHeading'] {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
  }

  div[class*='formRow'],
  div[class*='formHeading'] {
    span[class*='tooltipIcon'] {
      display: none;
    }

    &:hover {
      span[class*='tooltipIcon'] {
        display: block;
      }
    }
  }
}

.bulkList li {
  margin-bottom: 4px;
}

.downloadButton {
  padding: 4px 6px;
}

.recommended {
  color: $grey-600;
  font-weight: 400;
  display: inline;
  font-size: 1.2rem;
  line-height: 1em;
}

.radioButton {
  width: calc(50% - 4px);
  min-width: 200px;
}

.dragAndDrop {
  max-width: 100%;

  div[class*='dropZoneWrapper'] {
    padding-top: 0;
    margin-bottom: 0;
  }
}
