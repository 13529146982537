@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.siteLogo {
  width: 180px;
  height: 100px;
  display: block;
  margin: 16px auto 16px;
}

.tagLine {
  width: 100%;
  flex: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 1.5rem;
  margin: 0 auto 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $grey-200;
}

.addButton {
  font-weight: 600;
  padding: 8px 12px;
  font-size: $button-font-size;
}

.bold {
  font-weight: 600;
}

.topFormRow {
  padding-top: 0;
}

.formField {
  max-width: unset;
}

.paymentInfo {
  div[class*='tooltipTextContainer'] {
    display: inline-block;
  }

  .why {
    font-weight: 600;
    color: $primary-color;
    border-bottom-width: 0;
  }
}

.paddleCheckoutContainer {
  &.loading > iframe {
    display: none;
  }

  iframe {
    background-color: $grey-100 !important;
    // border: 1px solid $grey-300 !important;
    border-radius: $border-radius-small;
  }
}

.workspaceSelector {
  margin: 0 -16px 0 0 !important;
  min-width: 225px;
  text-align: left;

  div[class*='_menu_'] {
    min-width: 275px;
  }
}
