@import '../core/variables.scss';

.doubleScroll {
  overflow: auto hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &:first-of-type {
    z-index: 2;
    position: relative;
  }

  &.topScroll {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  // &::-webkit-scrollbar {
  //   width: 12px;
  //   height: 12px;
  //   position: absolute;
  //   bottom: 100px;

  //   &-track {
  //     background: $grey-200;
  //   }

  //   &-thumb {
  //     background: $grey-400;
  //     border-radius: 4px;
  //   }
  // }

  > .topScroll {
    padding-top: 1px;
    height: 0;
  }
}

.scrollDisabled {
  overflow-x: hidden;
}
