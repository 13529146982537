@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.marginTop {
  margin-top: 16px;
}

.connectCard {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  width: 100%;
  min-height: 120px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba($black, 0.1);
  opacity: 1;
  transition: 0.5s all;

  & > div {
    flex-direction: row;
  }

  img {
    max-width: 65px;
    margin: 16px auto;
    @include medium {
      max-width: 75px;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  .logo {
    width: 120px;
    max-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $grey-400;
    background-color: $grey-200;
    @include medium {
      width: 140px;
      max-width: 140px;
    }
  }
}

.title {
  font-size: $base-font-size;
  color: $grey-900;
  display: inline-block;
  margin: 16px 0 0 0;

  @include medium {
    font-size: $large-font-size;
  }
}

.textBoxOuter {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.textBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;

  @include medium {
    padding-left: 16px;
  }
}

.tag {
  white-space: nowrap;
  font-size: $small-font-size;

  @include medium {
    font-size: $base-font-size;
  }
  & > div {
    height: 34px;
    max-height: 34px;
  }
}

.modalTitle {
  display: flex;
  align-items: center;
  gap: 8px;

  img {
    height: 30px;
  }
}
