@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.content {
  position: relative;
  z-index: 1;
}

.tabsOuterWrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  p {
    display: inline-block;
    margin-bottom: 8px;
  }

  @include medium {
    align-items: center;
    flex-direction: row;
    p {
      margin-right: 8px;
    }
  }
}

.tabsWrapper {
  border: 1px solid rgba($grey-300, 0.6);
  padding: 0;
  margin: 0 0 8px;
  display: inline-flex;
  width: auto;
  border-radius: $border-radius-small;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  height: 38px;
  background: $white;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $grey-100;
  }

  &::-webkit-scrollbar-thumb {
    background: $grey-300;
    border-radius: 4px;
  }

  > label > span {
    padding: 0 1px !important;
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;
    width: auto;

    &:checked + em + span {
      background-color: $grey-600;
      color: $white;
    }
  }

  input[type='radio'] + em {
    display: none !important;
  }

  label {
    font-size: 1.4rem;
    text-align: center;
    border-right: 1px solid rgba($grey-600, 0.2);
    margin: 0;
    white-space: nowrap;

    span {
      padding: 0 12px;
      vertical-align: top;
      color: $grey-800;
    }

    &:last-child {
      border-right: 0;
      span {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
    &:first-child {
      span {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:hover {
      background-color: rgba($grey-600, 0.2);
      cursor: pointer;
    }
  }
}

.styleTabs {
  .tabsOuterWrapper {
    position: relative;
    z-index: 2;
    padding-bottom: 0;
    margin-bottom: -1px;
  }
  &.showDropDown {
    .tabsWrapper {
      height: 50px;
      label {
        line-height: 50px;
        span {
          line-height: 50px;
        }
      }
      input[type='radio'] {
        &:checked + em + span {
          line-height: 50px;
        }
      }
    }
  }
  .tabsWrapper {
    background-color: $grey-100;
    margin-bottom: 0;
    border-radius: $border-radius-small $border-radius-small 0 0;
    border: 1px solid $grey-300;
    border-bottom-width: 0;
    height: 42px;
    > label > span {
      padding: 0 !important;
    }

    label {
      line-height: 42px;
      span {
        text-align: left;
        width: 100%;
        line-height: 42px;
      }
      > span > span {
        border-bottom: 1px solid $grey-300;
      }
      &:last-child {
        span {
          border-bottom-right-radius: 0;
        }
      }
      &:first-child {
        span {
          border-bottom-left-radius: 0;
        }
      }
    }
    input[type='radio'] {
      &:checked + em + span {
        background-color: $white;
        // background-color: red;
        color: $grey-800;
        color: $primary-color;
        line-height: 40px;
      }
      &:checked + em + span:after {
        content: ' ';
        background-color: $primary-color;
        display: block;
        width: 100%;
        height: 2px;
        position: relative;
        bottom: 5px;
        z-index: 3;
      }
    }
  }
}

.isTopOfBox {
  &.styleTabs {
    .content {
      z-index: 1;
    }

    .tabsOuterWrapper {
      z-index: 2;
      margin-bottom: 0;
      // border-bottom: 1px solid $grey-300;
      box-shadow: inset 0 -1px $grey-300;
    }
  }
}

.showDropDownWrapper {
  margin-bottom: 8px;

  @include medium {
    margin-left: 8px;
    margin-bottom: 0;
  }

  .tabSelectorDropdown {
    min-width: 225px;
    max-width: 300px;

    div[class*='menu_'] {
      min-width: 300px;
    }

    img[class*='icon'] {
      display: none;
    }
  }
}
