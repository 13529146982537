@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.accordionContainer {
  margin-bottom: 16px;

  &:first-child {
    margin-top: 0;
  }

  div[class*='emoji'] > span {
    font-size: $h1-font-size !important;
  }
}

.accordionHeader {
  gap: 8px !important;
}

.collapsedProgressBar {
  display: flex;
  align-items: center;
  gap: 8px;

  > div {
    width: 100px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.skipAll {
  display: block;
  height: 40px;
  margin: 8px 16px 0 auto;
}

.quickAccessIcon {
  width: 42px;

  img {
    display: block;
    margin: auto;
    height: 24px;
  }
}

.quickAccessButton {
  display: block;
  font-size: $base-font-size;
  line-height: 2.2rem;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.voucherContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  .voucherText {
    margin-right: 16px;
    width: calc(50% - 16px);
    min-width: 75px;
    flex-grow: 1;

    a {
      max-width: 130px;
      margin-bottom: 8px;
    }

    > span {
      color: $grey-500;
    }
  }

  .amazon {
    display: block;
    width: 125px;
    margin: 0 24px 0 0;
  }
}

.container {
  margin: 0 0 2em 0;
}

.rightColumn {
  position: relative;

  @include medium {
    margin-bottom: 0;
  }
}

/* Modal */
.actionButton {
  display: block;
  width: fit-content;
  font-size: $button-font-size;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
