@import '../core/variables.scss';

.container {
  height: $button-line-height;
  background-color: $grey-200;
  width: 100%;
  border-radius: 20px;
  padding: 5px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 16px;
}

.thin {
  padding: 0;
  height: 1.5rem;
  &.superThin {
    height: 10px;
  }
  .progressContainer {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: 100%;
  }
}

.progressContainer {
  max-width: calc(100% - 10px);
  border-radius: 20px;
  height: auto;
  position: absolute;
  top: 5px;
  left: 5px;
  bottom: 5px;
  right: 5px;
  overflow: hidden;
  background-color: $grey-300;
}

.progress {
  transition: 0.2s all;
  height: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background-color: $audit-color;
  border-radius: 20px;
}

.red {
  background-color: $error-red;
}

.yellow {
  background-color: $quaternary-color;
}

.green {
  background-color: $green;
}

.grey {
  background-color: $grey-600;
}

.number {
  position: absolute;
  color: $grey-700;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  line-height: 30px;
  font-size: $base-font-size;
  font-weight: 600;
}
