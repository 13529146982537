@import '../core/variables';
@import '../core/breakpoints';

.row {
  width: 100%;
  flex: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  @include medium {
    flex-direction: row;
  }
}

.slot {
  display: block;
  flex: 1;
  max-width: 100%;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  @include medium {
    max-width: none;
    width: auto;
  }
}

.rowCenterAtMobile {
  composes: row;
  align-items: center;
  justify-content: center;

  @include medium {
    align-items: stretch;
    justify-content: flex-start;
  }
}

.slotCenterAtMobile {
  composes: slot;

  align-items: center;
  justify-content: center;

  @include medium {
    align-items: stretch;
    justify-content: flex-start;
  }
}

.odd {
  background: $grey-100;
}

.formRow {
  width: 100%;
  border-bottom: 1px solid $grey-200;
  padding-top: 16px;
  align-items: center;
  padding-bottom: 16px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  @include medium {
    // padding: 0 16px 16px 0;
    padding: 16px 0;
  }

  &.last {
    margin-bottom: 0;
    border-bottom: 0;
  }
  &.single {
    padding-top: 16px;
    border-top: 1px solid $grey-200;
    margin-bottom: 0;

    .labelSlot {
      @include medium {
        padding-left: 16px;
      }
    }
  }
}

.labelSlot {
  width: 100%;
  flex: 0 1 auto;
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  label {
    // margin-bottom: 0;
  }

  @include medium {
    width: 35%;
    margin-bottom: 0;
    padding-right: 16px;
  }

  &.column {
    @include medium {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  &.noPadding {
    margin-bottom: 0;
    @include medium {
      margin-bottom: 0;
    }
  }

  &.topAlign {
    align-items: flex-start;

    label {
      line-height: 40px;
    }
    [class*='tooltip_container'] {
      line-height: 40px;
    }
  }
}

.fieldSlot {
  width: 100%;
  flex: 0 1 auto;
  @include medium {
    width: 65%;
  }
  [class*='select-box-'],
  [class*='input_container'] {
    // margin-bottom: 0 !important;
    margin-bottom: 0;
  }

  &.column {
    @include medium {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  &.noPadding {
    margin-bottom: 0;
    @include medium {
      margin-bottom: 0;
    }
  }
}

.wideFields {
  @include medium {
    .labelSlot {
      width: 25%;
    }
    .fieldSlot {
      width: 75%;
    }
  }
}
