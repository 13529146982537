@import '../core/variables.scss';
@import '../core/breakpoints.scss';
@import './sso.module.scss';

.forgotPasswordContainer {
  height: 538px;
}

.forgotPasswordForm {
  margin: 16px 0;
  padding-top: 16px;
}

.svg {
  width: 100%;
  display: block;
  margin: 0 auto;
  flex: 320px;
  padding: 20px;
  max-width: 220px;
}

.loginHelp {
  display: block;
  margin: auto;
}
