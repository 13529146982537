@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.upgradeButton {
  font-weight: bold;
}

.addUsersHeading {
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
  align-items: center;

  h3 {
    margin-bottom: 0;
  }
}

.addUsersContainer {
  margin-bottom: 16px;

  .addUsersForm {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 16px;

    .emailField {
      width: 300px;
      max-width: 100%;
    }

    .workspacesField {
      width: 250px;
      max-width: 100%;
    }

    .permissionField {
      min-width: 150px;
    }

    .addButton {
      height: fit-content;
      align-self: flex-end;
    }
  }
}

.currentUsersHeader {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 16px;

  h3 {
    margin: 0;
  }
}

.tableActions {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;

  .emailSearchInput {
    margin: 0;
    min-width: 250px;
  }

  .rhsDropdown {
    margin-left: auto;
  }
}

.usersTable {
  tr {
    &:nth-child(odd) {
      .deleteConfirmContainer {
        background-color: $grey-100;
      }
    }
  }

  td {
    vertical-align: top;

    > p {
      line-height: 40px;
    }
  }

  .emailColumn {
    p {
      width: max-content;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        word-break: break-all;
      }
    }
  }

  .lastLoginColumn {
    width: 300px;
  }

  .permissionsColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    &:hover {
      gap: 8px;

      .addToWorkspaceButton {
        display: block;
      }
    }

    &:not(:hover) {
      .permissionSelector {
        margin-left: -12px;

        div[class*='control'] {
          background-color: transparent;
          border-color: transparent;

          div[class*='dropdownIndicator'],
          div[class*='clearIcon'] {
            visibility: hidden;
          }
        }
      }
    }

    .workspacePermission {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      position: relative;

      > p {
        margin: 0;
        font-size: $button-font-size;
      }

      .buttonTooltip {
        border: 0;
      }
    }

    p[class*='successText'] {
      font-size: $button-font-size;
    }

    .addToWorkspaceButton {
      display: none;
      margin-top: 8px;
      font-size: $button-font-size;
    }
  }

  .deleteColumn {
    width: 56px;
    min-width: 56px;
  }
}

.noData {
  padding: 16px;

  img {
    max-height: 125px !important;
  }
}

.greyText {
  margin: 0;
  color: $grey-500;
  font-size: $small-font-size;

  ~ p,
  button {
    font-size: $small-font-size;
  }
}

.deleteConfirmContainer {
  padding: 0 16px;
}

.usersTablePagination {
  margin-top: 16px;
}

.addWorkspaces {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
