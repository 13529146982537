@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.site {
  padding-top: 60px;

  @media (min-width: $site-container-width) {
    padding-top: 0;
    padding-left: 100px;
  }

  @include medium {
    min-height: 100%;

    main {
      min-height: 100vh;
      min-height: calc(100vh - 60px);
    }
  }
}
