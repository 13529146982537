@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  display: inline-block;
  white-space: nowrap;
}

.numbers {
  font-size: 1.8rem;
  margin-right: 10px;
  color: $green;
  font-weight: 700;
  min-width: 110px;

  &[data-type='down'] {
    color: $error-red;
  }

  &[data-type='neutral'] {
    color: $grey-400;
  }
}

.invertPolarity {
  .numbers {
    color: $error-red;

    &[data-type='down'] {
      color: $green;
    }
  }
}

.percentage {
  font-size: 1.2rem;
  position: relative;
  top: -4px;
  font-weight: 500;
  margin-left: 8px;
}

.hideOnMobile {
  .infoIcon {
    display: none;
    @include medium {
      display: inline-block;
    }
  }
}

.infoIcon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-right: 8px;
  vertical-align: top;

  &.largeIcon {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    vertical-align: middle;
  }
}

.compact {
  .infoIcon {
    margin-left: 0;
  }
}
.minimal {
  .infoIcon {
    display: none;
  }
  .percentage {
    display: none;
  }
}
.arrowOnly {
  .numbers {
    display: none;
  }
  .infoIcon {
    margin-left: 0;
  }
}

.noIcon {
  .infoIcon {
    display: none;
  }
}
.inline {
  .numbers {
    // font-size: 1.4rem;
    font-size: inherit;
    margin-right: 8px;
  }

  .percentage {
    // font-size: 1.4rem;
    font-size: inherit;
    position: static;
    top: 0;
    font-weight: 600;
    margin-left: 8px;
  }
}
.showBrackets {
  .percentage {
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
  }
}
.showLabelWhenZero {
  &:after {
    display: block;
    content: 'N/A';
    position: absolute;
    color: $grey-700;
    font-size: $x-small-font-size;
    top: 18px;
    left: 13px;
  }
}
