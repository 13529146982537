@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.createAccountModal {
  padding: 0 8px;

  > div[class*='modalBody'] {
    overflow-x: hidden;
  }
}

.siteLogo {
  width: 180px;
  height: 100px;
  display: block;
  margin: 0 auto;
}

.tagLine {
  width: 100%;
  flex: 100%;
  text-align: center;
  font-weight: normal;
  font-size: 1.5rem;
  margin: 0 auto 16px;
}

.progress {
  text-align: center;
  font-size: $xx-small-font-size;
  font-weight: 600;
  color: $grey-500;

  @include small {
    font-size: $x-small-font-size;
  }

  @include medium {
    font-size: $small-font-size;
  }

  .complete {
    color: $primary-color;
  }
}

.bold {
  font-weight: 600;
  margin-bottom: 8px;
}

.carousel {
  margin: 16px 0;
  padding-top: 16px;
  border-top: 1px solid $grey-200;
}

.topFormRow {
  padding-top: 0;
}

.formField {
  max-width: unset;
}

.createAccountForm {
  .inputLabel {
    font-weight: 600;
    color: $text-color;
    text-align: center;
  }

  .checkbox {
    input[type='checkbox'] ~ span {
      white-space: normal !important;
      line-height: 28px !important;
    }
  }

  .textAreaInput {
    margin-bottom: 0;

    textarea {
      min-height: unset;
      margin-bottom: 0;
    }
  }

  .submitButton {
    width: 100%;
  }
}

.accountText {
  text-align: center;
}

.selectBoxPortal {
  position: relative;
  z-index: 10000;
}
