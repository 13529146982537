@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.subsection {
  .hiddenButton {
    button {
      margin-left: 12px;
      opacity: 0;
      vertical-align: bottom;
    }

    &:hover {
      button {
        opacity: 1;
      }
    }
  }

  .idSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    p {
      margin-bottom: 0;
    }

    button {
      display: none;
    }

    &:hover button {
      display: inline;
    }
  }
}
