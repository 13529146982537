@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.filterWrapper {
  margin: 16px 0;

  .dateWrapper {
    display: block;
    margin-right: 0;
    max-width: unset;
  }
}

.reportControlsWrapper {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: nowrap;

  @include medium {
    width: auto;
    height: $button-height;
    flex-direction: row;
    align-items: stretch;
  }
}

.reportControlContainer {
  width: 100%;

  @include medium {
    width: auto;
    display: flex;
    flex-direction: row;
  }
}

.reportControlCopy {
  font-size: $small-font-size;
  line-height: 2.4rem;
  font-weight: 400;
  display: inline-flex;
  text-transform: capitalize;
  line-height: $button-height;

  @include medium {
    text-transform: none;
    font-size: $base-font-size;
  }
}

.calendarDateWrapper {
  margin-right: 8px;
}

.controlsSelector {
  div[class*='singleValue'] {
    width: max-content;
    min-width: 100px;
    text-transform: lowercase;
  }

  div[class*='_menu_'] {
    min-width: 250px;
    text-transform: lowercase;

    div[class*='tooltip']:not(div[class*='tooltipTextContainer']) {
      text-transform: initial;
    }
  }

  @include medium {
    margin: 0 8px;
  }

  &.dateSelector {
    div[class*='singleValue'],
    div[class*='_menu_'] {
      text-transform: none;
    }
  }
}

.filtersButton {
  width: 100%;
  margin-top: 8px;
  padding-left: 36px;

  & > div {
    justify-content: flex-start;
  }

  @include medium {
    width: auto;
    margin-top: 0;
  }

  &::before {
    position: absolute;
    left: 8px;
    top: 7px;
    content: ' ';
    display: block;
    width: 24px;
    height: 24px;
    background: url(../assets/filter-icon.svg) no-repeat 50%;
    background-size: contain;
  }
}

.filterDropdownsContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 0;

  .filterSelect {
    min-width: 200px;
    max-width: 240px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;

    @include medium {
      width: 20%;
    }

    div[class*='MenuList'] {
      max-width: 250px;
    }
  }

  .resetButton {
    height: 100%;
    font-weight: 600;

    img {
      height: 14px !important;
      vertical-align: bottom;
    }
  }
}

.filtersLoading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  .filtersPreloader {
    margin: 0;
    width: 30px;
    height: 20px;
  }
}
