@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.buttonRow {
  justify-content: flex-end;
  margin-top: -16px;

  button {
    margin-top: 4px;
  }
}

.confirmationButtonsRow {
  justify-content: center;
}

.addNameBlockWrapper {
  .addNameBlock:nth-child(even) {
    background: $grey-100;
  }
}

.addNameBlock {
  border-bottom: 1px solid $grey-400;
  padding-bottom: 16px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;

  @include medium {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.deleteButton {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 4px;
  right: 4px;
  margin: 0;
  padding: 0;
  opacity: 0.3;
  filter: grayscale(100%);
}

.addNameBlock {
  &:hover {
    .deleteButton {
      opacity: 1;
      filter: grayscale(0);
    }
  }
}

.helpText {
  color: $grey-700;
  margin-bottom: 8px !important;
}

.addAnother {
  margin-right: 0 !important;
}

.requestModal {
  > div[class*='modalHead'] p {
    margin-top: 16px;
    margin-bottom: 0;
  }

  > div[class*='modalBody'] {
    padding: 0;
  }

  .requestReason {
    padding: 16px;
  }
}

.footNote {
  color: $error-red;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 8px;
  display: block;
  width: 100%;

  > li {
    font-size: 1.3rem;
    margin-bottom: 0;
  }
}
