@import '../core/variables.scss';
@import '../core/breakpoints.scss';

/* *************************************************** */
// ParametersMasterRules
/* *************************************************** */

.headerRow {
  display: flex;
  align-items: center;
  margin: 32px 0 12px;

  .rulesHeading {
    margin: 0;
  }
}

.ruleRow {
  gap: 16px;
  flex-direction: row;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  > label {
    margin-bottom: 0;
  }

  div[class*='clickEditInput']:not(.multiLineInput) {
    width: fit-content;
  }

  .multiLineInput {
    width: 400px;

    &:not([class*='clickEditInputActive']) textarea {
      height: 100% !important;
    }
  }

  .advancedRulesClickEdit:not([class*='clickEditInputActive']):not(.emptyInput) {
    @include medium {
      border: 1px solid $grey-300;
      border-radius: $border-radius-small;
      padding-left: 8px;

      > label {
        height: 40px;
        line-height: 38px;
        color: $grey-600;
      }
    }
  }
}

.checkboxContainer {
  .ruleDropdown,
  .advancedRulesClickEdit {
    margin-left: 16px;
  }
}

.ruleDropdown {
  width: auto;
  min-width: 200px;
  margin: 0;

  div[class*='_menu_'] {
    min-width: 225px;
  }

  div[class*='additionalOptions'] {
    .dropdownUpgradeButton {
      text-align: left;
      font-size: inherit;
      color: $text-color;
      font-weight: 600;

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      background-color: $grey-400;
    }
  }
}
