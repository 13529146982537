@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.listingRow {
  border-radius: 6px;
  cursor: pointer;
  padding: 12px 24px 12px 16px;
  outline: none;
  position: relative;
  width: 100%;
  font-weight: 700;
  font-size: 1.4rem;
  &.viewOnly {
    cursor: auto;
  }
  &.listingRowColumns {
    justify-content: space-between;
  }
  .column {
    @include medium {
      display: flex;
      align-items: baseline;
    }
  }

  @include medium {
    padding: 14px 24px 12px 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-start;
  }

  &.listingRowWithStar {
    padding: 12px 40px 12px 16px;

    @include medium {
      padding: 14px 40px 12px 12px;
    }

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      width: 26px;
      height: 26px;
      background: url(../assets/star-empty.svg) no-repeat 50%;
      background-size: 100%;
    }
  }
  p {
    display: inline-block;
    font-weight: 700;
    font-size: 1.4rem;
    margin: 0;

    @include medium {
      // display: block;
    }
  }
}

.status {
  &:before {
    content: ' ';
    display: inline-block;
    width: 17px;
    height: 22px;
    vertical-align: bottom;
    margin-right: 8px;
    background: url(../assets/warningCircle.png) no-repeat 50%;
    background-size: 90%;
  }
  &.statusUnexplained {
    &:before {
      width: 22px;
      height: 22px;
    }
  }
  &.statusNotInteresting {
    &:before {
      background-image: url(../assets/neutral-arrow.svg);
    }
  }
  &.statusHasInsights {
    &:before {
      background-image: url(../assets/icon-explained.png);
    }
  }
}

.explainIcon {
  margin-right: 10px;
  max-height: 22px;
  height: 100%;
  width: auto;
  display: none;
}

.container {
  margin-bottom: 20px;
  border-radius: $border-radius-medium;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  background: $white;

  &.active {
    box-shadow: 0px 0px 0px 2px #3f85aa;
    .listingRow {
      &.listingRowWithStar:after {
        background: url(../assets/star-full.svg) no-repeat 50%;
      }
    }
  }
}

.para {
  margin: 0 0 8px 0;
  display: block;
  color: $grey-700;
  font-weight: 600;
  font-size: $base-font-size;

  @include medium {
    margin: 0;
  }
}

.upperRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.rowTitle {
}
.rowTitleName {
  display: inline;
}
.rowTitleDirection {
  display: inline;
  color: $grey-500;
}
.rowTitleDate {
  margin-right: 8px;
  sup {
  }
}

.divider {
  color: $grey-500;
}

.highlighted {
  // background-color: #ffeef4;
  // box-shadow: 0px 0px 0px 2px #ffeef4;
  // background-color: $grey-200;
  box-shadow: 0px 0px 0px 2px #347a95;
}
