@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.container {
  width: 100%;
  padding: 16px;
  margin: 0 auto;
  background: $grey-100;
  border-top: 1px solid $grey-300;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;

  a {
    // color: #007bff;
    // font-size: $small-font-size;
    font-weight: 400;
  }

  strong {
    font-weight: 600;
  }

  p {
    margin: 0 0 8px;
    // font-size: $small-font-size;
  }

  @include medium {
    flex-direction: row;
    justify-content: space-between;
    // transform: translateY(-100%);

    p {
      margin: 0;
    }
    .rightColumn {
      display: flex;
      flex-direction: row;
    }
    .links {
      margin-right: 16px;
    }
  }
}
