@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.topFormRow {
  padding-top: 0;
}

.addButton {
  font-weight: 600;
  padding: 8px 12px;
  font-size: $button-font-size;
}

.emailHtmlField {
  max-width: 100%;

  .textArea {
    margin-bottom: 0 !important;

    textarea {
      margin-bottom: 0 !important;
    }
  }

  .emailHtmlError {
    text-align: right;
  }
}
