@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.link {
  font-size: inherit;
  line-height: inherit;
  font-weight: 400;
  cursor: pointer;
  color: $tertiary-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
    color: $grey-600;
  }
}

.arrowForward {
  color: $primary-color;
  font-weight: 600;

  &::after {
    content: '';
    background-image: url(../assets/drop-down-arrow.svg);
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    transition: all 0.1s ease-in-out;
  }
}

.arrowBack {
  color: $primary-color;
  font-weight: 600;

  &::before {
    content: '';
    background-image: url(../assets/drop-down-arrow.svg);
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 5px;
    width: 10px;
    height: 10px;
    transform: rotate(180deg);
    transition: all 0.1s ease-in-out;
  }
}

.activeLink {
  color: $secondary-color;
}
