@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.linkPreview {
  margin: 0 auto 16px;
  font-size: $medium-font-size;
  font-weight: 600;
  word-break: break-all;

  span {
    line-height: $base-line-height;
  }

  .website {
    color: $blue-grey-500;
  }

  .prefixSeparator {
    color: $blue-grey-600;
    margin: 0 2px;

    &:hover {
      cursor: pointer;
    }
  }

  .toBack {
    color: $grey-400 !important;
  }

  .previewHover {
    font-size: $large-font-size;
  }
}
