@import '../core/variables.scss';

.container {
  p {
    color: $grey-600;
  }
  .flex {
    display: flex;
    width: 600px;
    gap: 5px;
  }
}
