@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.passiveInput {
  white-space: normal;
  padding: 0;
  border-width: 0;
  max-width: 100%;
  display: block;
  word-break: break-all;
}

.row {
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }

  .alignRow {
    display: flex;
    width: 100%;
    flex-direction: row;

    label {
      max-width: 90px;
      margin: 0;
    }
  }
}

.checkboxRow {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  label {
    flex-basis: 0;
    margin: 0 0 16px;
  }
}

.inlineTitle {
  em {
    color: $grey-600;
    margin-bottom: 0;
    font-size: $small-font-size;
  }

  b {
    margin-top: 5px;
  }
}

.fileUploader {
  width: 100%;

  .dragAndDrop > div[class*='dropZoneWrapper'] {
    padding-top: 0;
    margin-bottom: 0;
  }

  p[class*='error'] {
    margin: 4px 0 0;
  }
}

.cancelButton {
  margin-bottom: 16px;
  height: 40px;
}

.swatchContainerWrapper {
  margin: 0 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.hasWarning {
    margin-bottom: 4px;
  }

  .swatchContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    padding: 0;
    max-width: 220px;

    .swatch {
      width: 24px;
      height: 24px;
      padding: 0;
      border: 1px solid $grey-300;
    }
  }

  .colorField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    max-width: 90px;

    input {
      width: 90px;
      margin-left: 4px;
    }

    > label {
      min-width: 75px;
    }

    > span {
      margin-bottom: 0;
    }
  }
}

.warningText {
  color: $grey-700;
  font-size: $x-small-font-size;
  margin: 0;
}

.qrPreviewText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  width: fit-content;
  margin: auto !important;
  font-weight: 600;
  text-align: center;
}
