@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.controlsSelector {
  div[class*='multiValue'] {
    width: max-content;
    min-width: 100px;
  }

  div[class*='_menu_'] {
    min-width: 250px;
  }

  @include medium {
    margin: 0 8px 0 0;
  }

  &.noData {
    max-width: 200px;
    margin: 16px auto;
  }
}

.intro {
  margin-bottom: 20px;
}

.reportPaper {
  width: 100%;
  padding: 16px;
  overflow-x: auto;
  background-color: $white;
  border: 1px solid $grey-300;
  border-top-width: 0;
  border-radius: 0 $border-radius-small $border-radius-small
    $border-radius-small;
}

.reportControlCopy {
  font-size: $small-font-size;
  line-height: 2.4rem;
  font-weight: 400;
  display: inline-flex;
  text-transform: capitalize;
  line-height: $button-height;

  @include medium {
    text-transform: none;
    font-size: $base-font-size;
  }
}
