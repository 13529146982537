@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.addButton {
  font-weight: 600;
  font-size: $button-font-size;
}

.inputLabel {
  max-width: 200px;
}

.inputField {
  max-width: none;

  > span,
  > label,
  textarea {
    margin-bottom: 0 !important;
  }
}

.deleteAppGroupButton {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.osFieldsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  .osFieldsColumn {
    width: 100%;
    padding-bottom: 16px;

    &:first-child {
      border-bottom: 1px solid $grey-200;
    }
  }

  @include medium-wide {
    flex-direction: row;
    gap: 16px;

    .osFieldsColumn {
      width: calc(50% - 8px);
      margin-top: 16px;
      padding: 0 16px;

      &:first-child {
        border-right: 1px solid $grey-200;
        border-bottom: none;
      }

      > div:first-child {
        padding-top: 0;
      }
    }
  }
}

.accordionsHeading {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 16px;

  .appGroupHeading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    width: 400px;
  }

  h3 {
    margin: 0;
  }
}

.accordionItemHeader {
  div[class*='accordionItemTitle'] {
    width: 400px;
  }

  .editableField label {
    font-size: $h3-font-size;
    font-weight: 500;
    width: fit-content;
  }
}

.appGroupEditFormRow {
  &:first-child {
    padding-top: 0;
  }

  .appGroupEditLabel {
    height: 42px;

    label {
      font-weight: bold;
    }
  }

  .appGroupEditField {
    width: fit-content;
    max-width: 100%;
  }
}

.appScreensContainer {
  margin-top: 32px;

  .appScreensHeading {
    margin-bottom: 16px;
  }

  .appScreensButtons {
    justify-content: space-between;
    margin-bottom: 8px;
    margin-top: -10px;
    align-items: center;
    align-content: center;

    .tableSearch {
      display: flex;
    }
  }
}

.appScreensTable {
  td:not(.deleteColumn),
  th:not(.deleteColumn) {
    width: 200px;
  }

  .deleteColumn {
    width: 50px;
    min-width: 50px !important;
  }
}

.addContextOptionRow {
  background-color: $grey-300;

  .addOptionInput {
    margin: 0;
  }
}
