@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.addSupportUserForm {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 16px;

  .inputField {
    margin-bottom: 0;
  }
}

.enterpriseCompanyDetailsForm {
  span[class*='input_container'],
  textarea {
    margin-bottom: 0 !important;
  }

  .subtitle {
    font-size: $x-small-font-size;
    color: $grey-600;
    line-height: initial;
    margin-top: 8px;
  }

  .checkboxGroup {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap: 64px;
    flex-wrap: wrap;

    > label {
      width: auto;
      margin: 0;
      flex: 0 0;
    }
  }
}

.accordionContainer {
  margin-top: 0 !important;
  border-top-width: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.createEnterpriseClientModal {
  div[class*='modalFooter'] {
    padding: 8px 16px;

    div[class*='footerInner'] {
      margin: 0;
    }
  }
}
