@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.wrapper {
  display: inline-block;
}

.inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: middle;
  padding: 4px 8px;
  background-color: $light-yellow;
  min-height: 34px;
  color: $grey-700;
  font-size: $base-font-size;
  font-weight: 700;
  border-radius: 4px;

  img,
  span {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }
  span {
    padding-left: 10px;
  }
}

.inline {
  p {
    font-weight: normal;
    margin: 0;

    a {
      font-weight: 600;
    }
  }

  & > div {
    padding-right: 12px;
  }
}

.multiLine {
  .inner {
    position: relative;
    padding: 12px 16px 8px 8px;
    font-weight: normal;
    white-space: pre-wrap;
    img {
      position: absolute;
      top: 16px;
      left: 10px;
    }
    > span {
      padding-left: 32px;
    }
  }
}
