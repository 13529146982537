@import '../core/variables.scss';

.nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  z-index: 6;
  padding: 20px 0 0;
  background-color: $navigation-grey;
  box-shadow: 1px 1px 5px $grey-400;

  > a:first-child {
    margin-bottom: 20px;
  }

  .logo {
    max-width: 70px;
    width: 100%;
    margin: 0 auto;
  }

  .onboardingCounter,
  .navItemCounter {
    top: 8px;
    right: 6px;
  }

  .navItem {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 16px 0;
    transition: 0.5s all;
    background-color: inherit;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }

    &[data-name='track'] {
      color: $track-color;
    }

    &[data-name='report'] {
      color: $report-color;
    }

    &[data-name='connect'] {
      color: $audit-color;
    }

    &[data-name='plan'] {
      color: $primary-color;
    }

    &[data-name='explain'] {
      color: $explain-color;
    }

    &[data-name='upgrade'] {
      color: $white;
      width: 75%;
      padding: 8px 0;
      margin: 8px auto;
      background-color: $primary-color;
      border-radius: $border-radius-small;
    }

    &[data-name='settings'] {
      margin-top: auto;
      color: $settings-color;
    }

    h3 {
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 400;
      margin: 0;
    }

    svg,
    img {
      height: 40px;
      max-width: 45px;
    }

    .subNav {
      display: block;
      position: absolute;
      min-width: 100px;
      top: 0;
      left: -100%;
      pointer-events: none;
      opacity: 0;
      transition: 0.5s all;
      z-index: -1;
      background-color: $navigation-grey;
      box-shadow: inset 0 0 6px $grey-300;

      .subNavItem {
        display: block;
        position: relative;
        width: 100%;
        padding: 12px 46px 12px 26px;
        transition: 0.3s all;
        white-space: nowrap;
        text-align: left;
        font-size: $base-font-size;
        line-height: $base-line-height;
        font-weight: 400;

        &:hover {
          color: $white;
          text-decoration: none;

          &[data-name*='sub-track'] {
            background-color: $track-color;
          }

          &[data-name*='sub-report'] {
            background-color: $report-color;
          }

          &[data-name*='sub-explain'] {
            background-color: $explain-color;
          }

          &[data-name*='sub-connect'] {
            background-color: $audit-color;
          }

          &[data-name*='sub-settings'] {
            background-color: $settings-color;
          }

          > .subNav {
            left: 100%;
            opacity: 1;
            pointer-events: auto;
            max-height: 255px;
            overflow: auto;
          }
        }

        .counter {
          top: 13px;
          right: 8px;
        }
      }
    }

    &:hover {
      color: $white;

      &[data-name='track'] {
        background-color: $track-color;
      }

      &[data-name='report'] {
        background-color: $report-color;
      }

      &[data-name='explain'] {
        background-color: $explain-color;
      }

      &[data-name='connect'] {
        background-color: $audit-color;
      }

      &[data-name='plan'] {
        background-color: $primary-color;
      }

      &[data-name='settings'] {
        background-color: $settings-color;
      }

      svg,
      svg > path {
        fill: $white;
      }

      > .subNav {
        left: 100%;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
