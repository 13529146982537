@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.demoButton {
  padding: 2px 4px;
  font-size: $small-font-size;
}

.batchShortLinkRow {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 16px;
}

th.checkboxCell {
  background-color: $white;
}

.checkboxCell {
  position: sticky !important;
  width: 48px;
  min-width: 48px !important;
  left: 0;
  z-index: 1;
  background-color: inherit;
  padding: 8px 16px !important;

  label {
    margin: 0;

    b {
      margin-right: 0;
    }
  }
}

.shortLinkCell {
  min-width: 116px !important;
}

.urlColumn p {
  margin: 0;
  min-width: 300px;
  width: max-content;
  max-width: 500px;
  font-size: inherit;
  word-break: break-all;
  white-space: normal !important;
}

.optional {
  display: inline;
  font-size: $x-small-font-size;
  font-weight: 400;
  color: $grey-600;
}
