@import '../core/variables.scss';

.qrContainer {
  position: relative;
  box-sizing: border-box;
  border: 1px solid $grey-300;
  padding: 10px;
  margin: 0 auto 16px;
  width: 222px;
  height: 222px;

  canvas,
  svg {
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .hideCanvas {
    display: none;
  }

  .copyButton {
    display: none;
  }

  &:hover {
    .copyButton {
      display: block;
    }
  }
}

.copyButton {
  display: none;
  border-width: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
  box-shadow: none;
  background-color: $white !important;
  padding: 8px;
  border-radius: 0;
}
